import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionAmendmentOptions,
  selectSubscriptionAmendmentOrder,
  selectSubscriptionBillingDetails,
  selectSubscriptionOrganizationService,
  selectSubscriptionPrice,
  selectSubscriptionService,
} from "../../../store/subscription/subscriptionSlice";
import styled from "styled-components";
import {
  BREAKPOINTS,
  COLORS,
  ComponentLStyling,
  ComponentSStyling,
  ComponentTextStyle,
  ComponentXXSStyling,
  defaultOnMouseDownHandler,
  HyperLink, IconButton,
  SystemIcons,
  TooltipWrapper,
} from "@laerdal/life-react-components";
import {LicenseInformationHelper} from "../helpers/LicenseInformationHelper";
import dayjs from "dayjs";
import {formatCurrency} from "../../../util/currency-helper";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import {PaymentType} from "../../../model/dto/organization/paymentType";
import {CardType} from "../../../model/dto/organization/cardType";
import {selectGigyaAccount} from "../../../store/account/accountSlice";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;

  border-bottom: 1px solid ${COLORS.neutral_200};
`;

const ProrationContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProrationDetailsOpener = styled.div`
  margin-top: 8px;

  a {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 4px;
  }
`;

const ProrationDetails = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  border-radius: 4px;
  background: ${COLORS.neutral_50};
`;

const PaymentRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.LARGE} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Title = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const SubTitle = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  .note {
    color: ${COLORS.neutral_500};
  }
`;

const CardDescription = styled(Description)`
  gap: 0;
  align-items: center;
`;

const PlanDetailsNote = styled.div`
  ${ComponentXXSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
`;

const BillingDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BillingRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
`;

export const LicenseAmendmentSummary = () => {
  const {t, i18n} = useTranslation("License");
  const navigate = useNavigate();
  const location = useLocation();

  const [showProrationDetails, setShowProrationDetails] = useState(false);
  const account = useSelector(selectGigyaAccount);

  const orgService = useSelector(selectSubscriptionOrganizationService);
  const service = useSelector(selectSubscriptionService);
  const order = useSelector(selectSubscriptionAmendmentOrder);
  const options = useSelector(selectSubscriptionAmendmentOptions);
  const subscription = useSelector(selectSubscription);
  const billingDetails = useSelector(selectSubscriptionBillingDetails);
  const currentPrice = useSelector(selectSubscriptionPrice);

  const plan = service?.availablePlans?.find((p) => p.id === options?.planId);

  const title = `${service?.name} ${plan?.name}`;
  const planDescription = LicenseInformationHelper.FormatTierDescription(
    //@ts-ignore
    t,
    service!,
    options?.planId!,
    options?.planTierId!,
    options?.maxSubscriptionInstances ?? 1
  );

  return (
    <Wrapper>
      <Container>
        <div>
          <Title>{title}</Title>
          {planDescription && <Description>{planDescription}</Description>}
        </div>
        {order?.nextPayment && (
          <PaymentRow>
            <div>
              {subscription?.billingInterval === BillingInterval.Monthly && (
                <Description>
                  {t("What you’ll pay monthly starting")}
                </Description>
              )}
              {subscription?.billingInterval === BillingInterval.Yearly && (
                <Description>
                  {t("What you’ll pay annually starting")}
                </Description>
              )}
              {subscription?.billingInterval === BillingInterval.ThreeYear && (
                <Description>
                  {t("What you’ll pay every 3 year starting")}
                </Description>
              )}
              {subscription?.billingInterval === BillingInterval.FiveYear && (
                <Description>
                  {t("What you’ll pay every 5 year starting")}
                </Description>
              )}
              <Description>
                {dayjs(order!.nextPaymentDate).format("LL")}
              </Description>
            </div>
            <Description>
              <span>
                {formatCurrency(
                  order!.nextPayment!.intervalTotal ||
                  order!.nextPayment!.total,
                  order!.nextPayment.currency,
                  i18n.language
                )}
              </span>
              <span className="note">{t("incl. taxes")}</span>
            </Description>
          </PaymentRow>
        )}
        {order?.renewalPayment && (
          <PaymentRow>
            <div>
              {options?.billingInterval === BillingInterval.Monthly && (
                <Description>
                  {t("What you’ll pay monthly starting")}
                </Description>
              )}
              {options?.billingInterval === BillingInterval.Yearly && (
                <Description>
                  {t("What you’ll pay annually starting")}
                </Description>
              )}
              {options?.billingInterval === BillingInterval.ThreeYear && (
                <Description>
                  {t("What you’ll pay every 3 years starting")}
                </Description>
              )}
              {options?.billingInterval === BillingInterval.FiveYear && (
                <Description>
                  {t("What you’ll pay every 5 years starting")}
                </Description>
              )}
              <Description>
                {dayjs(order!.renewalDate).format("LL")}
              </Description>
            </div>
            <Description>
              <span>
                {formatCurrency(
                  order!.renewalPayment!.intervalTotal ||
                  order!.renewalPayment!.total,
                  order!.renewalPayment!.currency,
                  i18n.language
                )}
              </span>
              <span className="note">{t("incl. taxes")}</span>
            </Description>
          </PaymentRow>
        )}
        <PlanDetailsNote>
          {t(
            "Upgrades go into effect right away. Downgrades and term changes go into effect after the current billing cycle."
          )}
        </PlanDetailsNote>
      </Container>
      <Container>
        {order?.prorationPayment && (
          <ProrationContainer>
            <BillingRow>
              <SubTitle>{t("Amount due today")}</SubTitle>
              <SubTitle>
                {formatCurrency(
                  order!.prorationPayment.total,
                  order!.prorationPayment.currency,
                  i18n.language
                )}
              </SubTitle>
            </BillingRow>
            <ProrationDetailsOpener>
              <HyperLink
                variant={"default"}
                onMouseDown={defaultOnMouseDownHandler}
                href={"#"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowProrationDetails(!showProrationDetails);
                }}
              >
                <span>{t("View details")}</span>
                {!showProrationDetails && (
                  <SystemIcons.ChevronDown size="20px"/>
                )}
                {showProrationDetails && <SystemIcons.ChevronUp size="20px"/>}
              </HyperLink>
            </ProrationDetailsOpener>
            {showProrationDetails && (
              <ProrationDetails>
                <BillingRow>
                  <Description>
                    {t("Prorated adjustment ({{fromDate}} - {{toDate}})", {
                      fromDate: dayjs(order!.proratedFrom!).format("ll"),
                      toDate: dayjs(order!.proratedTo!).format("ll"),
                    })}
                  </Description>
                  <Description>
                    <TooltipWrapper
                      position={"top"}
                      align={"end"}
                      withArrow={true}
                      label={t(
                        "A one-time charge to cover the price difference between subscription options."
                      )}
                    >
                      <SystemIcons.Information size="20px"/>
                    </TooltipWrapper>
                    <span>
                      {formatCurrency(
                        order!.prorationPayment.total -
                        order!.prorationPayment.tax,
                        order!.prorationPayment.currency,
                        i18n.language
                      )}
                    </span>
                  </Description>
                </BillingRow>
                <BillingRow>
                  <Description>{t("Sales tax/VAT")}</Description>
                  <Description>
                    {formatCurrency(
                      order!.prorationPayment.tax,
                      order!.prorationPayment.currency,
                      i18n.language
                    )}
                  </Description>
                </BillingRow>
                <BillingRow>
                  <SubTitle>{t("Total")}</SubTitle>
                  <SubTitle>
                    {formatCurrency(
                      order!.prorationPayment.total,
                      order!.prorationPayment.currency,
                      i18n.language
                    )}
                  </SubTitle>
                </BillingRow>
              </ProrationDetails>
            )}
          </ProrationContainer>
        )}
        {!order?.prorationPayment && !!currentPrice && (
          <ProrationContainer>
            <BillingRow>
              <SubTitle>{t("Amount due today")}</SubTitle>
              <SubTitle>
                {formatCurrency(0, currentPrice!.currency, i18n.language)}
              </SubTitle>
            </BillingRow>
          </ProrationContainer>
        )}
        <BillingRow>
          <Description>{t("Bill to")}</Description>
          <BillingDetails>
            <Description>
              {billingDetails!.firstName} {billingDetails!.lastName}
            </Description>
            <Description>{billingDetails!.emailAddress}</Description>
          </BillingDetails>
        </BillingRow>
        <BillingRow>
          <Description>{t("Payment method")}</Description>
          <div>
            {subscription?.paymentType == PaymentType.Invoice && (
              <Description>{t("Pay-by-invoice")}</Description>
            )}
            {subscription?.paymentType != PaymentType.Invoice && !!billingDetails?.cardLastFourDigits && (
              <Description>{t("Credit card")}</Description>
            )}
            {subscription?.paymentType != PaymentType.Invoice && !!billingDetails?.cardLastFourDigits && (
              <CardDescription>
                <span>{`${CardType[billingDetails.cardType!]}-${billingDetails.cardLastFourDigits}`}</span>
                <IconButton
                  variant={'secondary'}
                  shape={'circular'}
                  action={() => navigate({
                    pathname: `/licenses/${orgService?.id}/edit/payment`,
                    search: `?returnUrl=${location.pathname}`
                  })}>
                  <SystemIcons.Edit color={COLORS.neutral_500}/>
                </IconButton>
              </CardDescription>
            )}
          </div>
        </BillingRow>
      </Container>
    </Wrapper>
  );
};
