import {
  BREAKPOINTS,
  COLORS,
  ModalDialog,
  Size,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from "rooks";
import PropertyWrapper from '../_commonComponents/PropertyWrapper';
import { CreditCardDto } from '../../model/dto/payment/creditCardDto';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

export interface Props {
  isOpen: boolean;
  card: CreditCardDto | undefined;
  onClose: () => void;
}

const PaymentModal = ({isOpen, onClose, card}: Props) => {
  const {t} = useTranslation('Payment');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  
  const close = () => {
    onClose();
  }

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={close}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 closeModalAndClearInput={close}
                 submitAction={(e) => e?.preventDefault()}
                 buttons={[{
                   variant: 'tertiary',
                   action: close,
                   text: t('Close')
                 }
                 ]}
                 title={ t('Card details')}>
      <Wrapper>
        
        
        <PropertyWrapper label={t('Cardholder name')} value={card?.holderName}/>
        <PropertyWrapper label={t('Card number')} value={`**** **** **** ${card?.lastNumbers}`}/>
        <PropertyWrapper label={t('Expiry date')} value={`${card?.expiryMonth} / ${card?.expiryYear}`}/>
        
      </Wrapper>
    </ModalDialog>);
}

export default PaymentModal;