import axios from 'axios';
import {ListContainerDto} from '../../model/dto/listContainerDto';
import AuthApi from './AuthApi';
import {OrderListItemDto} from '../../model/dto/orders/orderListItemDto';
import {OrderDetailsDto} from '../../model/dto/orders/orderDetailsDto';
import {OrderAssetListItemDto} from '../../model/dto/orders/orderAssetListItemDto';
import {BaseApi} from './BaseApi';

class OrdersApi extends BaseApi {
  GetOrders = async (
    itemsPerPage: number,
    pageNumber: number,
    orderBy: string | undefined,
    orderType: string | undefined,
    searchTerm: string | undefined,
    orderNumber: string | undefined
  ): Promise<ListContainerDto<OrderListItemDto>> => {

    const cacheKey = `${itemsPerPage}_${pageNumber}_${orderBy || ''}_${orderType || ''}_${searchTerm || ''}_${orderNumber || ''}`;

    let url = `${process.env.REACT_APP_API_URL}/Orders?itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`;

    if (!!orderBy) {
      url += `&orderBy=${orderBy}`;
    }
    if (!!orderType) {
      url += `&orderType=${orderType}`;
    }
    if (!!searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    const request = async () => axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });

    return this.GetCached('orders', cacheKey, request);
  };

  GetOrderDetails = async (orderNumber: string): Promise<OrderDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Orders/${orderNumber}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetOrderAssets = async (orderNumber: string): Promise<ListContainerDto<OrderAssetListItemDto>> => {
    let url = `${process.env.REACT_APP_API_URL}/orders/${orderNumber}/assets`;

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  ClearCache = () => {
    this.Clear('orders');
  }

}

export default new OrdersApi();
