import {
  Banner,
  BREAKPOINTS,
  LoadingIndicator,
  PageWidth,
  SearchBar,
  SegmentControl,
  Size,
  Table,
  TablePagination,
  VerticalTabs,
} from '@laerdal/life-react-components';
import {TableColumn, TableSortingDirection} from '@laerdal/life-react-components/dist/Table/TableTypes';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';
import { useNavigate } from "react-router";
import PaginationHelper from '../../../services/helpers/PaginationHelper';
import {debounce} from 'lodash';
import styled from 'styled-components';
import {OrderListItemDto} from '../../../model/dto/orders/orderListItemDto';
import OrdersApi from '../../../services/api/OrdersApi';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectGigyaAccount, selectUserProfile } from '../../../store/account/accountSlice';
import { useFeatures } from '../../../hooks/Features';
import { FeatureNames } from '../../../model/constants/FeatureConstants';
import { useDebouncedValue, useMediaMatch } from 'rooks';
import { ListHeader, ListPreTitle, ListSubtitle, ListTitle, StyledPageWidth } from '../../_commonComponents/StyledComponents';
import { EmptyPageBox } from '../../_commonComponents/EmptyPageBox';

const SearchWrapper = styled.div`
  margin:0 0 32px 0 ;
    width: 100%;
    ${BREAKPOINTS.LARGE}{
      width: 50%;
    }
`;

const TableContainer = styled.div`
    overflow-x: auto;
    background: white;
`;

const FullWidth = styled.div`
  width: 100%;  
`;

const VerticalTabWrapper = styled.div`
  width: 350px;
`;

const Row = styled.div`
  width:100%;
  display: flex;
  gap: 32px;

  flex-direction: column;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;

const OrdersPage = () => {
  const [checkFeature, isFeaturesInitialized] = useFeatures();
  const {t, i18n} = useTranslation(['Order', 'Shipment', 'Invoices', 'Common']);
  const navigate = useNavigate()
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  
  const user = useSelector(selectUserProfile);

  const [sortingDirection, setSortingDirection] = useState<TableSortingDirection | undefined>(undefined);
  const [sortingKey, setSortingKey] = useState<string | undefined>(undefined);

  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermDebounce] = useDebouncedValue(searchTerm, 300);
  const account = useSelector(selectGigyaAccount);
  const [loading, setLoading] = useState<boolean>(true);
  const [shipments, setShipments] = useState<OrderListItemDto[]>([]);
  const [pagination, setPagination] = useState<TablePagination>({
    currentPage: 0,
    from: 0,
    rowsPerPage: 20,
    to: 0,
    total: 0,
  } as TablePagination);

  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('OrdersPage Search',{
      term: searchTermDebounce,
    })
    setLoading(true);
    OrdersApi.GetOrders(pagination.rowsPerPage, pagination.currentPage, sortingKey, sortingDirection, searchTerm, undefined).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
      setLoading(false);
      if(response.items.length > 0) setHasItems(true);
      setInitialLoad(false);
    });
  }, [searchTermDebounce]);

  const onPreviousPage = () => {
    //@ts-ignore
    posthog.capture?.('OrdersPage PreviousPage');
    setLoading(true);
    OrdersApi.GetOrders(
      pagination.rowsPerPage,
      pagination.currentPage - 1,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetPrevious(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onNextPage = () => {
    //@ts-ignore
    posthog.capture?.('OrdersPage NextPage');
    setLoading(true);

    OrdersApi.GetOrders(
      pagination.rowsPerPage,
      pagination.currentPage + 1,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetNext(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    //@ts-ignore
    posthog.capture?.('OrdersPage RowsPerPage',{
      rowsPerPage: newRowsPerPage
    });
    setLoading(true);

    OrdersApi.GetOrders(newRowsPerPage, 0, sortingKey, sortingDirection, searchTerm, undefined).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetInitial(newRowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onSortingChanged = (key: string, direction?: TableSortingDirection) => {
    //@ts-ignore
    posthog.capture?.('OrdersPage Sort',{
      key: key,
      direction: direction
    });

    setSortingDirection(direction);
    setSortingKey(key);
    setLoading(true);

    OrdersApi.GetOrders(pagination.rowsPerPage, 0, key, direction, searchTerm, undefined).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const internalSearchTermChanged = useRef(
    debounce(async (
      term: string,
      rowsPerPage: number,
      sortingKey: string | undefined,
      sortingDirection: TableSortingDirection | undefined,
    ) => {
      setSearchTerm(term);
      var response = await OrdersApi.GetOrders(rowsPerPage, 0, sortingKey, sortingDirection, term, undefined);
      setShipments(response.items);
      setPagination(PaginationHelper.GetInitial(rowsPerPage, response.totalCount));
      setLoading(false);
    }, 300),
  ).current;

  useEffect(() => {
    return () => {
      internalSearchTermChanged.cancel();
    };
  }, [internalSearchTermChanged]);

  const openShipmenteDetails = (row: OrderListItemDto) => {
    //@ts-ignore
    posthog.capture?.('OrdersPage OrderDetails',{
      orderNumber: row.orderNumber
    });
    navigate(`/ordersinvoices/orders/details/${row.orderNumber}`);
  };

  const columnsWithPrice = [
    {
      key: 'orderNumber',
      name: t('Order number'),
      type: 'custom',
      customContent: (row: OrderListItemDto, key: string) => !!row.qadOrderNumber ? row.qadOrderNumber: row.orderNumber
    },
    {
      key: 'poNumber',
      name: t('PO number'),
      sortable: true
    },
    {
      key: 'orderStartDate',
      name: t('Order start date'),
      sortable: true,
      width: 230,
      type: 'custom',
      customContent: (row: OrderListItemDto, key: string) => !!row.orderStartDate ? moment(row.orderStartDate)
        .format('ll') : '',
    },
    {
      key: 'orderTotal',
      name: t('Order total'),
      sortable: true,
      justify: 'right',
      type: 'custom',
      customContent: (row: OrderListItemDto, key: string) => parseFloat(row.orderTotal ?? '0').toLocaleString(i18n.language, { style: 'currency', currency: row.currency }),
    }
  ] as TableColumn[];

  const columnsWithoutPrice = [
    {
      key: 'orderNumber',
      name: t('Order number'),
      type: 'custom',
      customContent: (row: OrderListItemDto, key: string) => !!row.qadOrderNumber ? row.qadOrderNumber: row.orderNumber
    },
    {
      key: 'poNumber',
      name: t('PO number'),
      sortable: true
    },
    {
      key: 'orderStartDate',
      name: t('Order start date'),
      sortable: true,
      width: 230,
      type: 'custom',
      customContent: (row: OrderListItemDto, key: string) => !!row.orderStartDate ? moment(row.orderStartDate)
        .format('L') : '',
    },
  ] as TableColumn[];

  const verticalItems =  [
    { requiredLine: t('Orders'), to: '/ordersinvoices/orders' },
    { requiredLine: t('Shipments'), to: '/ordersinvoices/shipments'},
    { requiredLine: t('Invoices'), to: '/ordersinvoices/invoices' },
  ];

  const horizontalOptions = [
    {key: '/ordersinvoices/orders', content: t('Orders') , disabled: false},
    {key: '/ordersinvoices/shipments', content: t('Shipments'), disabled: false},
    {key: '/ordersinvoices/invoices', content: t('Invoices'), disabled: false}
  ];

  return (
    <>
      <Helmet>
        <title>{t('Orders')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <ListHeader>    
            <ListPreTitle>{user!.currentOrganization!.name}</ListPreTitle>
            <ListTitle>{t('Orders & Invoices')}</ListTitle>
            <ListSubtitle>{t('Review order history, track shipments, and download invoices.')}</ListSubtitle>
        </ListHeader>

        <Row>
          {!isMediumScreen && <SegmentControl 
                                selected='/ordersinvoices/orders' 
                                items={horizontalOptions} 
                                onChange={(to)=>{
                                  //@ts-ignore
                                  posthog.capture?.('OrderPage Segment',{
                                    segment: to
                                  });
                                  navigate(to);
                                }} 
                                size={Size.Small}/>}
          {!!isMediumScreen && <VerticalTabWrapper><VerticalTabs  entries={verticalItems} /></VerticalTabWrapper>} 
          
          <FullWidth>
            { initialLoad &&
              <LoadingIndicator/>
            }

            { !initialLoad && !hasItems &&
              <EmptyPageBox 
                title={t('Your orders')} 
                description={t('A summary of your orders will be displayed here.')} 
                ilustrationPath='/assets/webshop_items.svg' />
            }

            { !initialLoad && hasItems &&
              <>
                <SearchWrapper>
                  <SearchBar
                      enterSearch={() => {}}
                      id="orderSearchField"
                      placeholder={t('Search by Order or PO number')}
                      size={Size.Medium}
                      setSearchTerm={setSearchTerm}
                      removeSearch={() => setSearchTerm('')}
                  />
                </SearchWrapper>

                <Banner type='neutral' size={Size.Small}>{t('It may take 30 minutes for new orders to appear')}</Banner>
                
                <TableContainer>
                  <Table
                    noRowsLabel={t('There are no rows to display', {'ns': 'Common'})}
                    rowsPerPageLabel={t('Rows per page', {'ns': 'Common'})}
                    rows={shipments}
                    remoteOperations={true}
                    pagination={pagination}
                    onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
                    onNextPageClick={() => onNextPage()}
                    onPreviousPageClick={() => onPreviousPage()}
                    showLoadingIndicator={loading}
                    onSelectionChange={(row: OrderListItemDto) => openShipmenteDetails(row)}
                    selectable={true}
                    onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onSortingChanged(key, direction)}
                    columns={
                      checkFeature(FeatureNames.HideProductPrices) 
                        ? columnsWithoutPrice 
                        : columnsWithPrice }
                  />
                </TableContainer>
              </>
            }
          </FullWidth>
        </Row>
    
      </StyledPageWidth>
    </>);
};


export default OrdersPage;