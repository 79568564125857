import { AddressDto } from "../model/dto/orders/addressDto";

export const GetAdressString = (value: AddressDto) => {
  let address = '';
  
      let line2 = '';
      if (value.city) line2 += value.city + ', ';
      if (value.state) line2 += value.state + ', ';
      if (value.postalCode) line2 += value.postalCode;

  if(value.street) address += value.street + "\r\n"
  if(line2) address += line2 + "\r\n"
  if(value.country) address += value.country + "\r\n"
  

  return address.trim();
};