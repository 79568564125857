import React from 'react';
import {useSelector} from 'react-redux';
import {selectIsAuthenticated, selectUserProfile} from '../../../store/account/accountSlice';
import {useLocation} from 'react-router';
import {Navigate, Outlet} from 'react-router-dom';

export const AuthRoute = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const langValue = urlParams.get('lang');

  const location = useLocation();
  const returnUrl = location.pathname !== '/' ? encodeURI(location.pathname) : undefined;
  const redirect = `/login?`
                    +`${returnUrl ? `returnUrl=${returnUrl}` : ''}`
                    +`${langValue ? `&lang=${langValue}` : ''}`;

  const render = () => {
    if (!isAuthenticated) {
      return <Navigate to={redirect}/>;
    }

    return <Outlet/>;
  };

  return render();
};