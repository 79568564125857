export enum SubscriptionStatus {
  Draft,
  Active,
  Canceled,
  Expired,
  PastDue,
  DraftTrial,
  Trial,
  Deactivated,
}

export const DRAFT_SUBSCRIPTION_STATUSES = [
  SubscriptionStatus.Draft,
  SubscriptionStatus.DraftTrial,
]

export const VALID_SUBSCRIPTION_STATUSES = [
  SubscriptionStatus.Active,
  SubscriptionStatus.Canceled,
  SubscriptionStatus.PastDue,
  SubscriptionStatus.Trial,
];