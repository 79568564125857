import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../../app/i18n';
import { User } from '../../model/dto/userProfile/user';
import { GigyaUserData } from '../../model/gigya/gigyaUserData';
import { RootState } from '../store';
import { AccountSliceState } from "./accountSlice.state";

const initialState: AccountSliceState = {
  gigyaAccount: undefined,
  userProfile: undefined,
  permissions: []
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    clearAccount: (state, _action: AnyAction) => {
      state.gigyaAccount = initialState.gigyaAccount;
      state.userProfile = initialState.userProfile;
    },

    setGigyaAccount(state, action: PayloadAction<GigyaUserData>)
    {
      state.gigyaAccount = action.payload;
    },

    setUserProfile(state, action: PayloadAction<User | undefined>){
      state.userProfile = action?.payload;
      state.permissions =
        action.payload?.currentOrganization?.members?.find(m => m.user?.id === action.payload?.id)
          ?.permissions
          ?.map(a => a.permissionId) ?? [];
    }
  },
});


export const {
  clearAccount,
  setGigyaAccount,
  setUserProfile,
} = accountSlice.actions;

export const selectIsAuthenticated = (state: RootState) => !!state?.account?.gigyaAccount;
export const selectGigyaAccount = (state: RootState) => state?.account?.gigyaAccount;
export const selectUserProfile = (state: RootState) => state?.account?.userProfile;
export const selectUserPermissions = (state: RootState) => state?.account?.permissions;

export const selectUserWebshopMarketCode = (state: RootState) => {
  var webshopService = state?.account?.userProfile?.currentOrganization?.services?.find(x=>x.name == "Webshop");
  if(webshopService){
    return webshopService.commerceMarket?.code
  }
  return "";
};

export const selectUserWebshopMarketCountry = (state: RootState) => {
  var webshopService = state?.account?.userProfile?.currentOrganization?.services?.find(x=>x.name == "Webshop");
  if(webshopService){
    return webshopService.commerceMarket?.name
  }
  return "";
};

export default accountSlice.reducer;