import styled from 'styled-components';
import {
  BackButton,
  Banner,
  BREAKPOINTS,
  Button,
  COLORS,
  LoadingIndicator,
  PageWidth,
  SearchBar,
  Size,
  SystemIcons,
  ToastColor,
  ToastContext,
  ToastPosition,
} from '@laerdal/life-react-components';
import {useNavigate} from 'react-router';
import {useSelector} from 'react-redux';
import {selectUserWebshopMarketCode} from '../../store/account/accountSlice';
import {useTranslation} from 'react-i18next';
import {useDebouncedValue} from 'rooks';
import AddressApi from '../../services/api/AddressApi';
import { useContext, useEffect, useState } from 'react';
import { AddressDto } from '../../model/dto/address/addressDto';
import { ListHeader, ListSubtitle, ListTitle, StyledPageWidth } from '../_commonComponents/StyledComponents';
import AddressTile from './AddressTile';
import AddressAddModal from './AddressAddModal';
import SettingsApi from '../../services/api/SettingsApi';
import { Country } from '../../model/dto/settings/country';
import AddressEditAddModal from './AddressEditModal';
import AddressActionModal from './AddressActionModal';
import { FailToastOptions } from '../../model/constants/ToastConstants';
import { Helmet } from 'react-helmet';
import { EmptyPageBox } from '../_commonComponents/EmptyPageBox';
import { CreateCaseResponse } from '../../model/dto/address/addressvalidation';

const LoadingContainer = styled.div`
  padding: 50px;
`;

export const Subtitle = styled.h6`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

const AddButtonWrapper = styled.div`
  display:flex;
  & > button {
    width: 100%;
  }

  ${BREAKPOINTS.LARGE}{
    justify-content: end;
    align-items: end;
    flex-grow: 1;

    & > button {
      width: auto;
    }
  }
`;

const AddressTileWrapper = styled.div`
  width: 100%;

  ${BREAKPOINTS.LARGE}{
    width: 32% ;
  }
`;

const Row = styled.div`
  display:flex;
  flex-direction:column;
  ${BREAKPOINTS.LARGE}{
    flex-direction:row;
  }
`;

const HeaderColumn = styled.div`
  display:flex;
  flex-direction:column;
  gap:32px;
`;


const AddressPage = () => {
  const {t} = useTranslation('Address');
  const navigate = useNavigate();
  const {addToast} = useContext(ToastContext);
  
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermDebounce] = useDebouncedValue(searchTerm, 300);
  
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [filteredAdresses, setFilteredAdresses] = useState<AddressDto[]>([]);
  const [adresses, setAdresses] = useState<AddressDto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<AddressDto | undefined>();
  const [isDefaultShippingModalOpen, setIsDefaultShippingModalOpen] = useState<boolean>(false);

  useEffect(() => {
    AddressApi.GetList().then((response) => {
      setAdresses(response);
      setLoading(false);
      if(response?.length > 0) setHasItems(true);
      setInitialLoad(false);
    });
  }, []);
  
  useEffect(() => {
    if(adresses?.length > 0) setHasItems(true);

    setFilteredAdresses(adresses
              .filter(a=>
                !searchTermDebounce
                || (a.billingName?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.line1?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.line2?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.country?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.zipCode?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.city?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1
                || (a.addressNumber?.toLocaleLowerCase().indexOf(searchTermDebounce?.toLocaleLowerCase()) ?? -1) > -1));
    
  }, [searchTermDebounce, adresses]);

  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('AddressPage Search',{
      term:searchTermDebounce
    });
  }, [searchTermDebounce]);
  

  const setDefaultShippingAddress = (a: AddressDto) => { 
    //@ts-ignore
    posthog.capture?.('AddressDefaultShippingModal Opened');
    setSelectedAddress(a); 
    setIsDefaultShippingModalOpen(true); 
  }
  return (
    <>
      <Helmet>
        <title>{t('Address Book')}</title>
      </Helmet>
 
      <AddressActionModal 
        onClose={()=>{
          //@ts-ignore
          posthog.capture?.('AddressDefaultShippingModal Closed');
          setIsDefaultShippingModalOpen(false)
        }} 
        onSubmit={()=> {
          //@ts-ignore
          posthog.capture?.('AddressDefaultShippingModal Submited');
          AddressApi
            .SetDefaultShipping(selectedAddress?.addressId)
              .then(() =>             
                {
                  let updatedAddresses = [...adresses];
                  for( let i=0; i<updatedAddresses.length; i++){
                    updatedAddresses[i].shippingDefault = updatedAddresses[i].addressId == selectedAddress?.addressId;
                  } 
                  setAdresses(updatedAddresses);
                })
              .catch(()=> {
                //@ts-ignore
                posthog.capture?.('AddressDefaultShippingModal Error');
                addToast(t('Something went wrong'), FailToastOptions)
              })
              .finally(() => 
                setIsDefaultShippingModalOpen(false))
          }} 
          mainButtonVariant="primary"
          mainButtonText={t("Set as default")}
          secondaryButtonText={t("Cancel")}
          description={t("Set as the main shipping address for orders you place with Laerdal.")}
          titleText={t('Set as default address')}
          address={selectedAddress} 
          isOpen={isDefaultShippingModalOpen}/>

      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        
        <HeaderColumn>
          <ListHeader>    
              <BackButton size={Size.Small} onClick={() => navigate('/overview')}>{t('Back to overview')}</BackButton>
              <ListTitle>{t('Address Book')}</ListTitle>
              <ListSubtitle>{t('Store address details for receiving order deliveries.')}</ListSubtitle>
          </ListHeader>
          <Banner>{t('Addresses can be added on the Webshop when making a purchase')}</Banner>
          <ListHeader>    
              <Subtitle>{t('Delivery addresses')}</Subtitle>
              <ListSubtitle>{t('These options are available when placing an order on the Laerdal Webshop.')}</ListSubtitle>
          </ListHeader>
        </HeaderColumn>
        

        
        { initialLoad &&
          <LoadingIndicator/>
        }

        { !initialLoad && !hasItems &&
          <EmptyPageBox 
            title={t('Your addresses')} 
            description={t('Saved address information will be displayed here.')}/>
        }
        
        { !initialLoad && hasItems &&
          <>
            <SearchWrapper>
              <SearchBar
                  enterSearch={() => {}}
                  id="addressSearchField"
                  placeholder={t('Search addresses')}
                  size={Size.Medium}
                  setSearchTerm={(term) => {
                    setLoading(false);
                    setSearchTerm(term);
                  }}
                  removeSearch={() => setSearchTerm('')}
              />
            </SearchWrapper>

            {
              loading && <LoadingContainer><LoadingIndicator/></LoadingContainer>
            }

            {   
              adresses && 
              <AddressContainer>
                {filteredAdresses
                  .map(a => 
                    <AddressTileWrapper key={a.addressId}>
                      <AddressTile 
                        address={a} 
                        setDefaultShippingAddress={setDefaultShippingAddress}  />
                    </AddressTileWrapper>
                )}
              </AddressContainer>
            }
            </>
          }

      </StyledPageWidth>
    </>
  );
};


export default AddressPage;