import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../store';
import {FeaturesSliceState} from './featuresSlice.state';
import {Feature} from '../../model/dto/settings/feature';
import { Country } from '../../model/dto/settings/country';

const initialState: FeaturesSliceState = {
  features: undefined,
  countries: undefined,
  initialized: false,
};

export const featuresSlice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures(state, action: PayloadAction<Feature[]>) {
      state.features = action.payload;
      state.initialized = true;
    },
    setCountries(state, action: PayloadAction<Country[]>) {
      state.countries = action.payload;
    },
  },
});


export const {setFeatures, setCountries} = featuresSlice.actions;

export const selectCountries = (state: RootState) => state?.features?.countries;
export const selectFeatures = (state: RootState) => state?.features?.features;
export const selectIsFeaturesInitialized = (state: RootState) => state?.features?.initialized;

export default featuresSlice.reducer;