import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { AppointmentDetailsDto } from "../../model/dto/appointments/appointmentDetailsDto";
import { useContext, useEffect, useState } from "react";
import AppointmentsApi from "../../services/api/AppointmentsApi";
import { Helmet } from "react-helmet";
import {
  BackButton,
  Banner,
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentL,
  ComponentM,
  ComponentS,
  ComponentTextStyle,
  ComponentXL,
  ContentIcons,
  HorizontalCard,
  ListRow,
  LoadingIndicator,
  ModalDialog,
  PageWidth,
  Size,
  SystemIcons,
  ToastContext
} from "@laerdal/life-react-components";
import styled from "styled-components";
import moment from 'moment-timezone';
import { StyledPageWidth } from "../_commonComponents/StyledComponents";
import { FailToastOptions } from "../../model/constants/ToastConstants";
import { useFeatures } from "../../hooks/Features";
import { FeatureNames } from "../../model/constants/FeatureConstants";
import { CountrySupportInfo } from "../../model/constants/CountrySupportInfo";
import { selectUserProfile } from "../../store/account/accountSlice";
import { useSelector } from "react-redux";
import { AppointmentCountryContact } from "../../model/constants/AppointmentCountryContact";


const Devider = styled.div`
  height:100%;
  border-right: 1px solid ${COLORS.neutral_200};
`;

const BasicInfoCell = styled.div`
  width:100%;
  display: flex;  
  margin: 20px 16px;
`;

const Row = styled.div`
  width:100%;
  display: flex;
  gap: 8px;
`;

const Row32 = styled.div`
  height:32px;
  width:100%;
  display: flex;
  gap: 8px;
`;


const RowSpaceBetween = styled.div`
  width:100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items:center;
`;

const AppointmentInfoColumn = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
`;

const Column = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const AppointmentDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  
`;

const SchedulingCardNeutral = styled(HorizontalCard)`
  & > div > div:first-child {
    background-color: ${COLORS.neutral_200};
    padding: 0 10px 0 0;
    margin: 1px;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid ${COLORS.neutral_200};
    height: 100%;
  }
`

const SchedulingCardPrimary = styled(HorizontalCard)`
  & > div > div:first-child {
    background-color: ${COLORS.primary_100};
    padding: 0 10px 0 0;
    margin: 1px;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid ${COLORS.neutral_200};
    height: 100%;
  }
`

const SchedulingCardWarning = styled(HorizontalCard)`
  & > div > div:first-child {
    background-color: ${COLORS.warning_100};
    padding: 0 10px 0 0;
    margin: 1px;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid ${COLORS.neutral_200};
    height: 100%;
  }
`

const SchedulingCardCorrect = styled(HorizontalCard)`
  & > div > div:first-child {
    background-color: ${COLORS.correct_100};
    padding: 0 10px 0 0;
    margin: 1px;
    border-radius: 8px 0 0 8px;
    border-right: 1px solid ${COLORS.neutral_200};
    height: 100%;
  }
`

const SchedulingButton = styled(Button)`
  margin: 0 20px 0 0;
`

const Frame = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: normal;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${COLORS.neutral_200};
    background: ${COLORS.white};
`;

const MainContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 32px;

    ${BREAKPOINTS.MEDIUM}{
        grid-template-columns: 1fr 1fr;
    }
`;

const BasicInfoContainer = styled.div`

    width:100%;
    display: flex;
    flex-direction:row;

    border-radius: 4px;
    border: 1px solid ${COLORS.neutral_200};
    
    margin-bottom:32px;
`;

const LineItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  padding-bottom:24px;
  border-bottom: 1px solid ${COLORS.neutral_200};
`;

const AppointmentDetail = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
const AppointmentDetailCategory = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  gap: 8px;
`;
const AppointmentDetailDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
export const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;

const AppointmentDetailsPage = () => {
  // Route values
  const { appointmentNumber } = useParams<{ appointmentNumber: string }>();

  // Services
  const { t, i18n } = useTranslation('Appointments');

  // Store
  const [loading, setLoading] = useState<boolean>(true);
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsDto | undefined>(undefined);
  const [loadingReports, setLoadingReports] = useState<{ id: string, loading: boolean }[]>([]);

  const [supportInfo, setSupportInfo] = useState<CountrySupportInfo | undefined>(undefined);
  const user = useSelector(selectUserProfile);
  const { addToast } = useContext(ToastContext);


  // Hooks
  const navigate = useNavigate();
  const [checkFeature, isFeaturesInitialized] = useFeatures();

  useEffect(() => {
    setLoading(true);
    AppointmentsApi.GetAppointmentDetails(appointmentNumber!, i18n.language).then((response) => {
      setAppointmentDetails(response);
      let reportsDownloading = response.appointmentDetails.reports.map<{ id: string, loading: boolean }>(x => { return { id: x.id, loading: false } });
      setLoadingReports(reportsDownloading);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!!user) {

      AppointmentCountryContact.forEach(x => {
        if (x.country.toLocaleLowerCase().trim() == user.currentOrganization?.address?.country?.name?.toLocaleLowerCase().trim()) {
          setSupportInfo(x);
        }
      });
    }
  }, [user]);



  return (
    <>
      <Helmet>
        <title>{t('Appointment details page')}</title>
      </Helmet>
      <ModalDialog
        key="smallModalWithoutImage"
        closeModalAndClearInput={() => { setContactModalOpen(false) }}
        title={t('Contact us')}

        size={Size.Large}
        isModalOpen={contactModalOpen}
        closeAction={() => { setContactModalOpen(false) }}
        submitAction={() => { }}
        buttons={[
          {
            action: () => { setContactModalOpen(false) },
            text: 'Okay',
          },
        ]}
      >
        <>
          <Row32>
            <ComponentL>{t('Please contact us to arrange this appointment.')}</ComponentL>
          </Row32>
          <Row32>
            <ComponentL>{t('Service Appointment Number')}:</ComponentL>
            <ComponentL>{appointmentNumber}</ComponentL>
          </Row32>
          <Row32>
            <ComponentL>{t('Phone')}:</ComponentL>
            <ComponentL textStyle={ComponentTextStyle.Bold} color={COLORS.primary_600}>{supportInfo?.phone}</ComponentL>
          </Row32>
          <Row32>
            <ComponentL>{t('Email')}:</ComponentL>
            <ComponentL textStyle={ComponentTextStyle.Bold} color={COLORS.primary_600}>{supportInfo?.email}</ComponentL>
          </Row32>
        </>
      </ModalDialog>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>

        <BackButton size={Size.Small} onClick={() => navigate('/appointments')}>{t('All Appointments')}</BackButton>

        {loading && <LoadingIndicator />}
        {appointmentDetails &&
          <AppointmentDetails>
            <Title>{appointmentDetails.appointmentDetails.workType}</Title>

            <MainContainer>
              <Column>
                <Frame>
                  <RowSpaceBetween>
                    <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Appointment info')}</ComponentL>
                    <Button variant="tertiary" icon={<SystemIcons.Help />} size={Size.Small} onClick={() => setContactModalOpen(true)}>
                      {t('Need help?')}
                    </Button>
                  </RowSpaceBetween>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Unscheduled'
                      && appointmentDetails?.appointmentDetails?.sfStatus != 'Ready to Self-Schedule'
                      &&
                      <SchedulingCardPrimary
                        icon={<ContentIcons.Calendar />}
                        title={t('Schedule appointment')}
                        description={t('This appointment is ready to be scheduled')}
                        actions={
                            [{ componentType: 'custom', content: <SchedulingButton onClick={() => setContactModalOpen(true)}>{t('Contact us')}</SchedulingButton> }]
                        }
                      />
                    }
                  </>
                  <>
                    {appointmentDetails?.appointmentDetails?.sfStatus == 'Ready to Self-Schedule'
                      &&
                      <SchedulingCardPrimary
                        icon={<ContentIcons.Calendar />}
                        title={t('Schedule appointment')}
                        description={t('This appointment is ready to be scheduled')}
                        actions={
                          appointmentDetails.workOrderDetails.canBeScheduled ?
                            [{ componentType: 'custom', content: <SchedulingButton onClick={() => navigate(`/appointments/details/${appointmentDetails.appointmentDetails.serviceAppointmentNumber}/book`)}>{t('Schedule a time')}</SchedulingButton> }] :
                            [{ componentType: 'custom', content: <SchedulingButton onClick={() => setContactModalOpen(true)}>{t('Contact us')}</SchedulingButton> }]
                        }
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Requested'
                      &&
                      <SchedulingCardNeutral
                        icon={<ContentIcons.TimelineReview />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                        description='Your requested time is being reviewed by our team'
                        actions={[{
                          componentType: 'custom',
                          content:
                            <SchedulingButton variant="secondary" onClick={() => navigate(`/appointments/details/${appointmentDetails.appointmentDetails.serviceAppointmentNumber}/book`)}>
                              {t('Change time')}
                            </SchedulingButton>
                        }]}
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Needs Reschedule'
                      &&
                      <SchedulingCardWarning
                        icon={<ContentIcons.Attention />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                        description='The time you requested is unavailable'
                        actions={[{
                          componentType: 'custom',
                          content:
                            <SchedulingButton variant="primary" onClick={() => navigate(`/appointments/details/${appointmentDetails.appointmentDetails.serviceAppointmentNumber}/book`)}>
                              {t('Choose another time')}
                            </SchedulingButton>
                        }]}
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Scheduled'
                      &&
                      <SchedulingCardCorrect
                        icon={<ContentIcons.Calendar />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                        description='Your appointment is confirmed'
                        actions={[{
                          componentType: 'custom',
                          content:
                            <SchedulingButton variant="secondary" onClick={() => navigate(`/appointments/details/${appointmentDetails.appointmentDetails.serviceAppointmentNumber}/book`)}>
                              {t('Change time')}
                            </SchedulingButton>
                        }]}
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Dispatched'
                      &&
                      <SchedulingCardCorrect
                        icon={<ContentIcons.Calendar />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                        description='Your appointment is confirmed'
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Pending'
                      || appointmentDetails?.appointmentDetails?.status == 'Cannot complete'
                      &&
                      <SchedulingCardWarning
                        icon={<ContentIcons.Attention />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                      />
                    }
                  </>

                  <>
                    {appointmentDetails?.appointmentDetails?.status == 'Completed'
                      &&
                      <SchedulingCardNeutral
                        icon={<ContentIcons.Calendar />}
                        title={
                          !!appointmentDetails?.appointmentDetails?.scheduledStart ? 
                           `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`
                           : ''
                        }
                      />
                    }
                  </>

                  <BasicInfoContainer>
                    <BasicInfoCell>
                      <AppointmentInfoColumn>
                        <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_600}>{t('Duration')}</ComponentS>
                        <ComponentL> {appointmentDetails.appointmentDetails.duration} {appointmentDetails.appointmentDetails.durationType} </ComponentL>
                      </AppointmentInfoColumn>
                      <ContentIcons.Stopwatch size="40" />
                    </BasicInfoCell>

                    <Devider></Devider>

                    <BasicInfoCell>
                      <AppointmentInfoColumn>
                        <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_600}>{t('Type')}</ComponentS>
                        <ComponentL>{appointmentDetails.workOrderDetails.deliveryType}</ComponentL>
                      </AppointmentInfoColumn>
                      <ContentIcons.ESimulation size="40" />
                    </BasicInfoCell>
                  </BasicInfoContainer>

                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.neutral_600}>{t('Subject')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM>{appointmentDetails.appointmentDetails.workType}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>

                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.neutral_600}>{t('Customer contact')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM>{appointmentDetails.workOrderDetails.contactName}</ComponentM>
                      <ComponentM>{appointmentDetails.workOrderDetails.contactEmail}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>

                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.neutral_600}>{t('Service contact')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM>{appointmentDetails.appointmentDetails.assignedResource}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>

                  {
                    appointmentDetails.workOrderDetails.deliveryType?.toLowerCase() != "virtual" &&
                    appointmentDetails.workOrderDetails.address &&
                    appointmentDetails.workOrderDetails.address.length > 0 &&
                    <AppointmentDetail>
                      <AppointmentDetailCategory>
                        <ComponentM color={COLORS.neutral_600}>{t('Location')}</ComponentM>
                      </AppointmentDetailCategory>
                      <AppointmentDetailDetail>
                        <ComponentM>{appointmentDetails.workOrderDetails.address[0].street}</ComponentM>
                        <ComponentM>{appointmentDetails.workOrderDetails.address[0].city}</ComponentM>
                        <ComponentM>{appointmentDetails.workOrderDetails.address[0].state}</ComponentM>
                        <ComponentM>{appointmentDetails.workOrderDetails.address[0].country}</ComponentM>
                        <ComponentM>{appointmentDetails.workOrderDetails.address[0].postalCode}</ComponentM>
                      </AppointmentDetailDetail>
                    </AppointmentDetail>
                  }
                </Frame>

                {!!appointmentDetails.workOrderDetails.lines.length &&
                  <Frame>
                    <ComponentL textStyle={ComponentTextStyle.Bold}>{`${t('Related work items')} (${appointmentDetails.workOrderDetails.lines.length})`}</ComponentL>
                    {appointmentDetails?.workOrderDetails.lines?.map(line =>
                      <LineItem>
                        <ComponentL textStyle={ComponentTextStyle.Bold}>{line.workType}</ComponentL>
                        <RowSpaceBetween>
                          <ComponentL >{line.relatedProductName}</ComponentL>
                          <ComponentL >{line.relatedProductCode}</ComponentL>
                        </RowSpaceBetween>
                      </LineItem>
                    )}
                  </Frame>
                }
              </Column>

              <Column>
                <Frame>
                  <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Additional details')}</ComponentL>

                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.neutral_600}>{t('Appointment number')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>{appointmentDetails.appointmentDetails.serviceAppointmentNumber}</AppointmentDetailDetail>
                  </AppointmentDetail>

                  {!!appointmentDetails.workOrderDetails.orderNumber &&
                    <AppointmentDetail>
                      <AppointmentDetailCategory>
                        <ComponentM color={COLORS.neutral_600}>{t('Order number')}</ComponentM>
                      </AppointmentDetailCategory>
                      <AppointmentDetailDetail>{appointmentDetails.workOrderDetails.orderNumber}</AppointmentDetailDetail>
                    </AppointmentDetail>
                  }

                  {!!appointmentDetails.workOrderDetails.productCode &&
                    <AppointmentDetail>
                      <AppointmentDetailCategory>
                        <ComponentM color={COLORS.neutral_600}>{t('Product Code')}</ComponentM>
                      </AppointmentDetailCategory>
                      <AppointmentDetailDetail>{appointmentDetails.workOrderDetails.productCode}</AppointmentDetailDetail>
                    </AppointmentDetail>
                  }

                  {!!appointmentDetails.workOrderDetails.productName &&
                    <AppointmentDetail>
                      <AppointmentDetailCategory>
                        <ComponentM color={COLORS.neutral_600}>{t('Product Name')}</ComponentM>
                      </AppointmentDetailCategory>
                      <AppointmentDetailDetail>{appointmentDetails.workOrderDetails.productName}</AppointmentDetailDetail>
                    </AppointmentDetail>
                  }

                  {!!appointmentDetails?.workOrderDetails?.lines?.length &&
                    <AppointmentDetail>
                      <AppointmentDetailCategory>
                        <ComponentM color={COLORS.neutral_600}>{t('Product Code(s)')}</ComponentM>
                      </AppointmentDetailCategory>
                      <AppointmentDetailDetail>
                        {appointmentDetails?.workOrderDetails?.lines?.map(line =>
                          <ComponentM>{line.relatedProductCode}</ComponentM>
                        )}
                      </AppointmentDetailDetail>
                    </AppointmentDetail>
                  }
                </Frame>

                {!!appointmentDetails?.appointmentDetails?.reports.length &&
                  <Frame>
                    <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Attached files')}</ComponentL>
                    {appointmentDetails?.appointmentDetails?.reports?.map(report =>
                      <ListRow size={Size.Large}
                        mainText={report.serviceReportName}
                        rightSideIcon={loadingReports.find(x => x.id == report.id)?.loading ? <LoadingIndicator /> : <SystemIcons.Download />}
                        rightSideAction={
                          () => {
                            if (loadingReports.find(x => x.id == report.id)?.loading)
                              return;

                            setLoadingReports(loadingReports.map<{ id: string, loading: boolean }>(x => { return { id: x.id, loading: report.id == x.id ? true : x.loading } }));

                            AppointmentsApi.DownloadReport(
                              report.serviceReportName,
                              appointmentDetails?.appointmentDetails.serviceAppointmentNumber,
                              report.id,
                              () => {
                                addToast(t('There was an issue downloading the report'), { ...FailToastOptions, autoClose: false });
                              }).finally(() => {
                                setLoadingReports(loadingReports.map<{ id: string, loading: boolean }>(x => { return { id: x.id, loading: report.id == x.id ? false : x.loading } }));
                              })
                          }
                        } />
                    )}
                  </Frame>
                }
              </Column>
            </MainContainer>
          </AppointmentDetails>
        }
      </StyledPageWidth>
    </>
  )
}
export default AppointmentDetailsPage;