export function isEnableCountry(marketCountry: string | undefined) {
  if(!marketCountry) return false;
  if(!process.env.REACT_APP_ENABLE_MARKETS) return false;

  return process.env.REACT_APP_ENABLE_MARKETS!.indexOf(marketCountry!) > -1;
}

export function isEpiCountry(marketCountry: string| undefined) {
  if(!marketCountry) return false;
  if(!process.env.REACT_APP_ENABLE_MARKETS) return false;
  
  if(process.env.REACT_APP_ENABLE_MARKETS_WITHOUT_COMMERCE!.indexOf(marketCountry!) > -1) return false;

  return process.env.REACT_APP_ENABLE_MARKETS!.indexOf(marketCountry!) == -1;
}
