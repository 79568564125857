import React, {useContext, useEffect, useState} from 'react';
import {OrganizationServiceInvitation} from '../../../model/dto/organization/organizationServiceInvitation';
import styled from 'styled-components';
import {
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  ListRow,
  Size, SystemIcons, ToastContext,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {PermissionsType, usePermissions} from '../../../hooks/Permissions';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '../../../store/account/accountSlice';
import {useMediaMatch} from 'rooks';
import {navigateByUrl} from '../../../util/navigation-helper';
import {Service} from '../../../model/dto/userProfile/service';
import RemoveUserModal from '../../licenseManagementPage/components/RemoveUserModal';
import EditUserModal from '../../licenseManagementPage/components/EditUserModal';
import {
  ServiceMemberItem,
  ServiceMemberItemMember,
} from '../../licenseManagementPage/types';
import {UserPermissionDetailsDto} from '../../../model/dto/userDetails/UserPermissionDetailsDto';
import {ServiceRole} from '../../../model/dto/userProfile/serviceRole';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  
  max-width: 648px;
  box-sizing: border-box;
  border: 1px solid ${COLORS.neutral_200};
  border-radius: 8px;
  background: ${COLORS.white};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.div`
  ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  services: Service[];
  user: UserPermissionDetailsDto;
  onUpdateUser: (user: UserPermissionDetailsDto) => void;
}

export const UserServiceDetails = ({user, services, onUpdateUser}: Props) => {

  const {t} = useTranslation('Users');
  const {addToast} = useContext(ToastContext);
  const {hasPermissions} = usePermissions();
  const [hasService, setHasService] = useState(false);;
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const profile = useSelector(selectUserProfile);

  const [editModalData, setEditModalData] = useState<{ service: Service, orgServiceId: string, member: ServiceMemberItemMember }>();
  const [removeModalData, setRemoveModalData] = useState<{ service: Service, orgServiceId: string, member: ServiceMemberItem }>();

  const orgServices = profile?.currentOrganization?.services || [];

  useEffect(() => {
    if((user.services?.filter(x=>x.name.toLocaleLowerCase() != 'my e-courses' && x.name.toLocaleLowerCase() != 'webshop')?.length 
        || (user.invitations?.filter(x=>x.serviceName.toLocaleLowerCase() != 'my e-courses' && x.serviceName.toLocaleLowerCase() != 'webshop'))?.length))
        setHasService(true);
    else        
      setHasService(false);

  }, [user]);

  const servicesUserIsNotPartOf =
    orgServices.filter(s =>
      !user.services?.find(service => service.serviceId === s.serviceId) ||
      !user.invitations?.find(invitation => invitation.serviceId === s.serviceId),
    );

  const servicesCurrentUserCanManage =
    servicesUserIsNotPartOf.filter(s => hasPermissions(
      PermissionsType.Subscription,
      s.serviceId,
    ));

  const canAddUserToAnyService =
    !!servicesUserIsNotPartOf.length &&
    (
      !!servicesCurrentUserCanManage.length ||
      hasPermissions(PermissionsType.AccountOwner)
    );

  const resendInvitation = (invitation: OrganizationServiceInvitation) => {
    OrganizationApi.RemoveOrganizationServiceInvitation(invitation.organizationServiceId, invitation.code).then((a) => {
      OrganizationApi.InviteMemberToOrganizationService(invitation.organizationServiceId, {
          role: invitation.role!,
          emails: [invitation.email],
          sendEmail: true,
        })
        .then((value) => {
          addToast(t('Invite resent'), SuccessToastOptions);
        })
        .catch((reason) => {
          addToast(t('Invite resend failed'), FailToastOptions);
        });
    });
  };

  const handleContextMenu = (action: string, serviceId: string, orgServiceId: string) => {
    const service = services.find(s => s.id === serviceId)!;
    const orgService = user.services?.find(s => s.id === orgServiceId);

    switch (action) {
      case 'resend':
        resendInvitation(user.invitations?.find(a => a.organizationServiceId === orgServiceId)!);
        break;
      case 'revoke':
        setRemoveModalData({
          service: service,
          orgServiceId: orgServiceId!,
          member: {
            type: 'invitation',
            invitation: user.invitations?.find(i => i.serviceId === serviceId)!,
          },
        });
        break;
      case 'edit':
        setEditModalData({
          service: service,
          orgServiceId: orgServiceId!,
          member: {
            type: 'member',
            member: orgService!.members[0]!,
          },
        });
        break;
      case 'remove':
        setRemoveModalData({
          service: service,
          orgServiceId: orgServiceId!,
          member: {
            type: 'member',
            member: orgService!.members[0]!,
          },
        });
        break;
    }
  };

  const onEdit = (role: ServiceRole) => {
    const orgService = user.services?.find(s => s.id === editModalData!.orgServiceId)!;
    orgService.members[0].role = role;
    onUpdateUser({...user});
  };

  const onRemove = () => {
    switch (removeModalData!.member.type) {
      case 'member':
        user.services = user.services?.filter(a => a.serviceId != removeModalData!.service.id);
        break;
      case 'invitation':
        user.invitations = user.invitations?.filter(a => a.serviceId != removeModalData!.service.id);
        break;
    }
    onUpdateUser({...user});
  };

  return (
    <Wrapper>
      <RemoveUserModal onClose={() => setRemoveModalData(undefined)}
                       isOpen={!!removeModalData}
                       service={removeModalData?.service}
                       organizationServiceId={removeModalData?.orgServiceId}
                       member={removeModalData?.member}
                       handleUserRemove={onRemove}/>

      <EditUserModal onClose={() => setEditModalData(undefined)}
                     isOpen={!!editModalData}
                     service={editModalData?.service}
                     organizationServiceId={editModalData?.orgServiceId}
                     member={editModalData?.member}
                     handleUserEdit={(_, role) => onEdit(role)}/>

      <Header>
        <Title>{t('Subscription access')}</Title>
        
        { hasService &&
          <Description>{t('This person has access to the digital subscriptions listed below.')}</Description>
        }
        { !hasService &&
          <Description>{t('This person has no access to any digital subscriptions.')}</Description>
        }
      </Header>
      <Section>
        {
          user.services
            ?.filter(x=>x.name.toLocaleLowerCase() != 'my e-courses' && x.name.toLocaleLowerCase() != 'webshop')
            ?.map(service =>
            <ListRow key={service.id}
                     mainText={service.name}
                     className={'service-item'}
                     note={service?.members[0]?.role?.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase()
                           ? t('License owner')
                           : undefined}
                     dropdown={{
                       items: [
                         {
                           value: 'edit',
                           displayLabel: t('Edit access'),
                         },
                         {
                           value: 'remove',
                           displayLabel: t('Remove'),
                         },
                       ],
                       onClick: ([value]: string[]) => handleContextMenu(value, service.serviceId, service.id),
                     }}
            />)
        }
        {
          user.invitations
            ?.filter(x=>x.serviceName.toLocaleLowerCase() != 'my e-courses' && x.serviceName.toLocaleLowerCase() != 'webshop')
            ?.map(invitation =>
            <ListRow key={invitation.code}
                     className={'service-item'}
                     mainText={invitation.serviceName}
                     secondaryText={t('Invited')}
                     dropdown={{
                       items: [
                         {
                           value: 'resend',
                           displayLabel: t('Resend invitation email'),
                         },
                         {
                           value: 'revoke',
                           displayLabel: t('Revoke invitation'),
                         },
                       ],
                       onClick: ([value]: string[]) => handleContextMenu(
                         value,
                         invitation.serviceId,
                         invitation.organizationServiceId,
                       ),
                     }}
            />)
        }
      </Section>
      
      {
        canAddUserToAnyService &&
        <Button variant={'secondary'}
                icon={<SystemIcons.OpenNewWindow/>}
                width={'max-content'}
                onClick={() => navigateByUrl('/licenses')}
                size={isMediumScreen ? Size.Medium : Size.Small}>
          {t('Add to more services')}
        </Button>
      }
    </Wrapper>
  );
};