import {AnyAction, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import accountReducer from './account/accountSlice'
import featuresReducer from './features/featuresSlice'
import subscriptionsReducer from "./subscription/subscriptionSlice";

const store = configureStore({
  reducer: {
    account: accountReducer,
    features: featuresReducer,
    subscriptions: subscriptionsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, undefined, AnyAction>;

export default store;