import React, {useContext, useState} from 'react';
import {UserPermissionDetailsDto} from '../../../model/dto/userDetails/UserPermissionDetailsDto';
import styled from 'styled-components';
import {
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentLStyling,
  ComponentSStyling,
  ComponentTextStyle,
  Size,
  SystemIcons, ToastContext,
} from '@laerdal/life-react-components';
import PropertyWrapper from '../../_commonComponents/PropertyWrapper';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import {useMediaMatch} from 'rooks';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';
import {useNavigate} from 'react-router';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 420px;
  flex: 1;
`;

const RemoveButton = styled(Button)`
  align-self: start;
`;

const Initials = styled.h6`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${COLORS.primary_500};
  color: ${COLORS.white};
  
  &.invitation{
    background-color: ${COLORS.neutral_300};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px; 
`;

const Name = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;
const Email = styled.div`
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props {
  showRemoveUserButton: boolean;
  removeUserAction: () => void;
  user: UserPermissionDetailsDto;
  onUpdateUser: (user: UserPermissionDetailsDto) => void;
  type: 'member' | 'invitation';
}


export const UserDetails = ({user, type, onUpdateUser, showRemoveUserButton, removeUserAction}: Props) => {

  const {t} = useTranslation('Users');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const {addToast} = useContext(ToastContext);
  const [resending, setResending] = useState(false);
  const [revoking, setRevoking] = useState(false);
  const navigate = useNavigate();

  const revokeInvitation = () => {
    setRevoking(true);

    OrganizationApi.UpdateOrganizationInvitation(user.inviteCode!, false, true)
      .then(a => {
        addToast(t('Invitation successfully revoked'), SuccessToastOptions);
        navigate('/users');
      })
      .catch(() => {
        addToast(t('Revoking of invitation failed'), FailToastOptions);
      })
      .finally(() => {
        setRevoking(false);
      });
  }

  const resendInvitation = () => {
    setResending(true);
    OrganizationApi.UpdateOrganizationInvitation(user.inviteCode!, true, false)
      .then(a => {
        onUpdateUser({...user, isActive: true});
        addToast(t('Invitation e-mail successfully resent'), SuccessToastOptions);
      })
      .catch(() => {
        addToast(t('Resending of invitation e-mail failed'), FailToastOptions);
      })
      .finally(() => {
        setResending(false);
      });
  }

  return (
    <Wrapper>
      <Initials>
        {
          type === 'member'
            ? ((user.firstName?.charAt(0) || '') + (user.lastName?.charAt(0) || '')) ||
            <SystemIcons.User size={'36px'}/>
            : <SystemIcons.Mail/>
        }
      </Initials>
      <Container>
        <Name>
          {
            type === 'member'
              ? (user.firstName || '') + ' ' + (user.lastName || '')
              : user.email
          }
        </Name>
        <Email>
          {
            type === 'member'
              ? user.email
              : user.isActive ? t('Invited') : t('Invitation Expired')
          }
        </Email>
      </Container>
      {
        type === 'member' &&
        <>
          <PropertyWrapper label={t('Member since')} value={moment(user.memberSince).format('ll')}/>
          {user.lastLogin && <PropertyWrapper label={t('Last active')} value={moment(user.lastLogin).format('ll')}/>}
          {showRemoveUserButton &&
            <RemoveButton variant={'secondary'}
                    className={'action'}
                    onClick={removeUserAction}>
              {t('Remove from organization')}
            </RemoveButton>
          }
        </>
      }
      {
        type === 'invitation' &&
        <Actions>
          <Button variant={'primary'}
                  loading={resending}
                  disabled={revoking}
                  onClick={() => resendInvitation()}
                  size={isMediumScreen ? Size.Medium : Size.Small}>
            {t('Resend invitation email')}
          </Button>
          <Button variant={'tertiary'}
                  disabled={resending}
                  loading={revoking}
                  onClick={() => revokeInvitation()}
                  size={isMediumScreen ? Size.Medium : Size.Small}>
            {t('Cancel invite')}
          </Button>
        </Actions>
      }
    </Wrapper>
  );
};
