import React, {useMemo} from 'react';
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {
  selectSubscription,
  selectSubscriptionAmendmentOptions,
  selectSubscriptionAvailablePlans,
  selectSubscriptionAvailableTerms,
  selectSubscriptionAvailableTiers,
  selectSubscriptionPriceConfigurations,
  selectSubscriptionService,
  setSubscriptionAmendmentOptions
} from "../../../store/subscription/subscriptionSlice";
import {useSelector} from "react-redux";
import {
  BasicDropdown,
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentLStyling,
  ComponentSStyling,
  ComponentTextStyle,
  DropdownItem,
  HorizontalTab,
  HorizontalTabs,
  InputLabel,
  NumberField,
  Size,
  SystemIcons,
  Tag,
  TooltipWrapper
} from "@laerdal/life-react-components";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import {useAppDispatch} from "../../../store/store";
import {ServicePlanTier} from "../../../model/dto/userProfile/servicePlanTier";
import {formatCurrency} from "../../../util/currency-helper";
import {InstanceLimitType} from "../../../model/dto/userProfile/instanceLimitType";
import dayjs from "dayjs";


const BillingCycle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  [role="listbox"], .dropdown {
    min-width: unset;
  }
`;

const BillingCycleNote = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  background: ${COLORS.neutral_50};

  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_500)}
`;

const BillingCycleInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;


  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BillingCycleInputLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  > div {
    width: 20px;
    height: 20px;
  }
`;


const TiersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.LARGE} {
    flex-direction: row;
  }
`;

const Tier = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 320px;
  gap: 16px;
  padding: 56px 16px 16px;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px ${COLORS.neutral_200};

  &.selected {
    box-shadow: inset 0 0 0 2px ${COLORS.primary_500};
  }

  .tag {
    position: absolute;
    top: 16px;
    left: 16px;
  }
`;


const TierItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const TierItemTitle = styled.div`
  ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
`;
const TierItemDescription = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.neutral_600)}
`;

const PlansContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px
`;

export const LicenseAmendmentPlans = () => {
  const {t} = useTranslation('License');
  const dispatch = useAppDispatch();

  const service = useSelector(selectSubscriptionService);
  const availableTerms = useSelector(selectSubscriptionAvailableTerms);
  const availablePlans = useSelector(selectSubscriptionAvailablePlans);
  const availableTiers = useSelector(selectSubscriptionAvailableTiers);
  const prices = useSelector(selectSubscriptionPriceConfigurations);
  const options = useSelector(selectSubscriptionAmendmentOptions);
  const subscription = useSelector(selectSubscription);

  const tabs = useMemo(() => {
    const tmp = [] as HorizontalTab[];

    if (prices?.find(a => a.billingInterval == BillingInterval.Monthly)) {
      tmp.push({
        to: `${BillingInterval.Monthly}#1`,
        selected: options?.billingInterval === BillingInterval.Monthly,
        value: t('Monthly')
      });
    }

    if (prices?.find(a => a.billingInterval != BillingInterval.Monthly)) {
      tmp.push({
        to: `${BillingInterval.Yearly}#12`,
        selected: options?.billingInterval !== BillingInterval.Monthly,
        value: t('Yearly')
      });
    }
    return tmp;
  }, [prices, options]);

  const terms = useMemo(() => {
    const terms: DropdownItem[] = [];
    let sorted = [...availableTerms ?? []];
    sorted.sort((a, b) => b.billingInterval - a.billingInterval);
    sorted.sort((a, b) => a.duration - b.duration);
    for (let term of sorted) {
      if (term.billingInterval === BillingInterval.Yearly && term.duration === 12) {
        terms.push({value: `${term.billingInterval}#${term.duration}`, displayLabel: t('1 Year',)});
      }
      if (term.billingInterval === BillingInterval.Yearly && term.duration > 12) {
        terms.push({
          value: `${term.billingInterval}#${term.duration}`,
          displayLabel: t('{{years}} Years, paid annually', {years: term.duration / 12})
        });
      }
      if (term.billingInterval === BillingInterval.ThreeYear && term.duration === 36) {
        terms.push({value: `${term.billingInterval}#${term.duration}`, displayLabel: t('3 Years, prepaid',)});
      }

      if (term.billingInterval === BillingInterval.FiveYear && term.duration === 60) {
        terms.push({value: `${term.billingInterval}#${term.duration}`, displayLabel: t('5 Years, prepaid',)});
      }
    }

    return terms;
  }, [availableTerms])

  const handleTermChange = (value: string) => {
    const [billingInterval, duration] = value.split('#');

    dispatch(setSubscriptionAmendmentOptions(
      {
        ...options!,
        billingInterval: +billingInterval as BillingInterval,
        duration: +duration,
      }
    ));
  }

  const handleQuantityChange = (value: number) => {
    dispatch(setSubscriptionAmendmentOptions(
      {
        ...options!,
        maxSubscriptionInstances: value,
      }
    ));
  }

  const handleTierChange = (tier: ServicePlanTier) => {
    dispatch(setSubscriptionAmendmentOptions(
      {
        ...options!,
        planTierId: tier.id,
      }
    ));
  }

  const handleTabChange = (value: string) => {
    const [billingInterval, duration] = value.split('#');
    if (+billingInterval != BillingInterval.Monthly && options?.billingInterval == BillingInterval.Monthly) {
      handleTermChange(value)
    }
    if (+billingInterval == BillingInterval.Monthly && options?.billingInterval != BillingInterval.Monthly) {
      handleTermChange(value)
    }
  }

  const formattedTierPrice = (tier: ServicePlanTier) => {
    const price = prices?.find(
      a => a.planId === options?.planId &&
        a.tierId === tier.id &&
        a.billingInterval === options?.billingInterval &&
        a.duration === options?.duration);

    if (!price) return '';

    const amount =
      price.intervalSubtotal
        ? price.intervalSubtotal
        : price.subtotal;

    return formatCurrency(amount * (options?.maxSubscriptionInstances ?? 1), price.currency)
  }

  const maxTierUsers = availableTiers?.reduce((prev, current) => (prev.maxSubscriptionInstances ?? 0) > (current.maxSubscriptionInstances ?? 0) ? prev : current);

  return (
    <>
      <PlansContainer>
        <HorizontalTabs size={Size.Large}
                        tabs={tabs}
                        onTabChange={handleTabChange}
                        variant={'floating'}/>

        <TiersContainer>
          {
            availableTiers?.map(tier =>
              <Tier key={tier.id} className={options?.planTierId == tier?.id ? 'selected' : ''}>
                {
                  subscription?.tier.id === tier.id &&
                  <Tag variant={'accent1'} label={t('Current plan')} className={'tag'}/>
                }
                <TierItem>
                  <TierItemTitle>
                    {tier.name}
                  </TierItemTitle>
                  {
                    !tier.individualLicensing &&
                    tier.maxSubscriptionInstances &&
                    <TierItemDescription>
                      {t('1-{{users}} users', {users: tier.maxSubscriptionInstances})}
                    </TierItemDescription>
                  }
                  {
                    !tier.individualLicensing &&
                    !tier.maxSubscriptionInstances &&
                    <TierItemDescription>
                      {t('{{max}}+ users', {max: maxTierUsers?.maxSubscriptionInstances})}
                    </TierItemDescription>
                  }
                </TierItem>
                <TierItem>
                  <TierItemTitle>
                    {formattedTierPrice(tier)}
                  </TierItemTitle>
                  <TierItemDescription>
                    {
                      options?.billingInterval === BillingInterval.Monthly &&
                      t('per month')
                    }
                    {
                      options?.billingInterval === BillingInterval.Yearly &&
                      t('per year')
                    }
                    {
                      options?.billingInterval === BillingInterval.ThreeYear &&
                      t('per {{years}} years', {years: 3})
                    }
                    {
                      options?.billingInterval === BillingInterval.FiveYear &&
                      t('per {{years}} years', {years: 5})
                    }
                  </TierItemDescription>
                </TierItem>
                {
                  tier.individualLicensing &&
                  <TierItem>
                    <InputLabel inputId={'quantity'}
                                text={service?.instanceLimitType == InstanceLimitType.Session
                                  ? t('Number of seats')
                                  : t('Number of users')}/>
                    <NumberField type={"NumberField"}
                                 id={'quantity'}
                                 minValue={1}
                                 value={options?.maxSubscriptionInstances ?? 1}
                                 onChange={handleQuantityChange}
                    />
                  </TierItem>
                }
                <Button variant={options?.planTierId == tier?.id ? 'primary' : 'secondary'}
                        icon={options?.planTierId == tier?.id ? <SystemIcons.CheckMark/> : undefined}
                        onClick={() => handleTierChange(tier)}
                        size={Size.Medium}>
                  {
                    options?.planTierId == tier?.id ?
                      t('Selected') :
                      t('Select')
                  }
                </Button>
              </Tier>
            )
          }
        </TiersContainer>

      </PlansContainer>

      <BillingCycle>
        {
          options?.billingInterval !== BillingInterval.Monthly &&
          <BillingCycleInput>
            <BillingCycleInputLabel>
              <span>{t('Billing cycle')}</span>
              <TooltipWrapper>
                <SystemIcons.Help size={'20px'}/>
              </TooltipWrapper>
            </BillingCycleInputLabel>
            <div>
              <BasicDropdown list={terms}
                             className={'dropdown'}
                             disableSorting={true}
                             value={options?.billingInterval + '#' + options?.duration}
                             onSelect={handleTermChange}
                             size={Size.Small}/>
            </div>
          </BillingCycleInput>
        }
        {
          options?.autoRenew &&
          <BillingCycleNote>
            <SystemIcons.Information size={'20px'}/>
            <span>
            {
              options?.duration == 1 &&
              t('Plan will automatically renew each month, until canceled.')
            }
              {
                options?.duration === 12 &&
                t('Plan will automatically renew each year, until canceled.')
              }
              {
                options?.duration &&
                options?.duration > 12 &&
                t('Plan will automatically renew every {{amount}} years, until canceled.', {amount: options?.duration / 12})
              }
          </span>
          </BillingCycleNote>
        }
      </BillingCycle>
    </>
  )

}