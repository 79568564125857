import {NavigateFunction} from "react-router-dom";

export const navigateByUrl = (url: string, navigate?: NavigateFunction, target: '_blank' | '_self' = '_blank') => {
  const isExternalURL = new URL(url, window.location.origin).origin !== window.location.origin;
  if (!isExternalURL && !!navigate) {
    navigate(url.replace(window.location.origin, ''));
  } else {
    const win = window.open(url, target);
    if (!!win) {
      win.focus();
    }
  }
};
