import axios from 'axios';
import {ListContainerDto} from '../../model/dto/listContainerDto';
import {InvoiceListItemDto} from '../../model/dto/invoices/invoiceListItemDto';
import {InvoiceDetailsDto} from '../../model/dto/invoices/invoiceDetailsDto';
import AuthApi from './AuthApi';
import {BaseApi} from './BaseApi';

class InvoicesApi extends BaseApi {
  GetInvoices = async (
    itemsPerPage: number,
    pageNumber: number,
    lang: string,
    orderBy: string | undefined,
    orderType: string | undefined,
    searchTerm: string | undefined,
    orderNumber: string | undefined
  ): Promise<ListContainerDto<InvoiceListItemDto>> => {

    const cacheKey = `${itemsPerPage || ''}_${pageNumber || ''}_${orderBy || ''}_${orderType || ''}_${searchTerm || ''}_${orderNumber || ''}`;

    let url = `${process.env.REACT_APP_API_URL}/Invoices?itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`;

    if (!!orderBy) {
      url += `&orderBy=${orderBy}`;
    }
    if (!!orderType) {
      url += `&orderType=${orderType}`;
    }
    if (!!searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }
    if (!!orderNumber) {
      url += `&orderNumber=${orderNumber}`;
    }
    if (!!lang) {
      url += `&lang=${lang}`;
    }

    const request = async () => axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });

    return this.GetCached('invoices', cacheKey, request);
  };

  GetInvoiceDetails = async (
    invoiceNumber: string,    
    lang: string
    ) : Promise<InvoiceDetailsDto> => {

    let url = `${process.env.REACT_APP_API_URL}/Invoices/${encodeURIComponent(invoiceNumber)}`;
    if (!!lang) {
      url += `?lang=${lang}`;
    }

    return axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  DownloadInvoice = async (invoiceNumber: string, handleError: (message: string) => void): Promise<void> => {
    const downloadLink = `${process.env.REACT_APP_API_URL}/Invoices/download?invoiceNumber=${encodeURIComponent(
      invoiceNumber)}`;
    return axios
      .get(downloadLink, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {type: 'application/pdf'});

          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(blob);
          const downloadElement = document.createElement('a');
          downloadElement.href = link;
          downloadElement.setAttribute(
            'download',
            invoiceNumber + `.pdf`,
          );
          document.body.appendChild(downloadElement);
          downloadElement.click();
          downloadElement!.parentNode!.removeChild(downloadElement);
        }
      }).catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 401:
          case 403:
            handleError('Not authorized to download');
            break;
          case 404:
            handleError(
              'The invoice document is not generated yet, please try to download it later. If this error persists please contact support');
            break;
          default:
            handleError('Issue when downloading');
        }
      });
  };

  GetBlobLink = async (invoiceNumber: string, handleLink: (link:string)=>void, handleError: (message: string) => void): Promise<void> => {
    const downloadLink = `${process.env.REACT_APP_API_URL}/Invoices/download?invoiceNumber=${encodeURIComponent(
      invoiceNumber)}`;
    return axios
      .get(downloadLink, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {type: 'application/pdf'});

          const url = window.URL || window.webkitURL;
          handleLink(url.createObjectURL(blob));
        }
      }).catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 401:
          case 403:
            handleError('Not authorized to download');
            break;
          case 404:
            handleError(
              'The invoice document is not generated yet, please try to download it later. If this error persists please contact support');
            break;
          default:
            handleError('Issue when downloading');
        }
      });
  };

  ClearCache = () => {
    this.Clear('invoices');
  }

}

export default new InvoicesApi();

