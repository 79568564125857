import React from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  SystemIcons,
  
} from '@laerdal/life-react-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 16px;
  background-color: ${COLORS.neutral_50};
  border-radius: 4px;
  
  .checkbox{
    width: max-content;
  }

`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 4px;
  max-width: calc(100% - 56px);
`;

const Title = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
`;

const Description = styled.p`
  color: ${COLORS.neutral_600};
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

interface Props {
  id: string;
  name: string;
  description: string;
  selected: boolean;
  selfEditing: boolean;
  onToggle: (checked: boolean) => void;
  locked: boolean;
}

export const SpecialPermission = ({name, description, onToggle, selected, locked, selfEditing}: Props) => {

  return (
    <Wrapper className={`spec-permission`}>
      {
        locked &&
        <Icon><SystemIcons.LockedOn color={COLORS.neutral_600}/></Icon>
      }
      {
        !locked && !selfEditing &&
        <Checkbox className={'checkbox'} selected={selected} select={onToggle}/>
      }
      {
        !locked && selfEditing &&
        <Checkbox className={'checkbox'} selected={true} disabled={true}/>
      }
      <Content>
        <Title>
          {name}
        </Title>
        {!selfEditing && <Description>
          {description}
        </Description>}
      </Content>
    </Wrapper>
  );
};