import React from 'react';
import {useTranslation} from "react-i18next";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {BREAKPOINTS, Button, COLORS} from "@laerdal/life-react-components";
import PropertyWrapper from "../../_commonComponents/PropertyWrapper";
import {useNavigate} from "react-router";

const Wrapper = styled.div`
  height: max-content;
  background-color: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  padding: 16px;
  display: flex;
  gap: 16px;
  flex: 2;
  
  ${BREAKPOINTS.LARGE}{
    flex: 1;
  }

`;

const Initials = styled.h6`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${COLORS.primary_500};
  color: ${COLORS.white};
`;


const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UserInfo = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UserCard = () => {
  const {t} = useTranslation('Profile');
  const user = useSelector(selectUserProfile);
  const navigate = useNavigate();

  return (
    <Wrapper>
      <Initials>
        {user?.firstName?.charAt(0) || ''}{user?.lastName?.charAt(0) || ''}
      </Initials>
      <Content>
        <UserInfo>
          <PropertyWrapper label={t('Full name')} value={`${user?.firstName} ${user?.lastName}`}/>
          <PropertyWrapper label={t('Email / Username')} value={user?.email || ''}/>
        </UserInfo>
        <Button onClick={() => navigate('edit')}>{t('Edit details')}</Button>
      </Content>

    </Wrapper>
  )
}