import {useEffect, useState} from 'react';
import {useDebouncedValue} from 'rooks';
import {OrganizationInvitation} from '../../../model/dto/organization/organizationInvitation';
import {OrganizationMember} from '../../../model/dto/organization/organizationMember';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {useNavigate, useParams} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectUserProfile} from '../../../store/account/accountSlice';
import {PermissionsType, usePermissions} from "../../../hooks/Permissions";
import {Organization} from "../../../model/dto/organization/organization";

export interface ListItem {
  key: string;
  type: 'member' | 'invitation';
  title: string;
  subtitle: string;
  note: string;
  sortValue?: object;
}

export interface UseOrganizationUserManagementHelperResult {
  count: number;
  loading: boolean;
  canManage: boolean;
  page: number
  sortBy: string[];
  setSortBy: (value: (((prevState: string[]) => string[]) | string[])) => void;
  searchTerm: string;
  setSearchTerm: (value: (((prevState: string) => string) | string)) => void;

  listItems: ListItem[];
  members: OrganizationMember[];
  emails: string[];

  handleUserAdd: (newInvitations: OrganizationInvitation[]) => void;

  organization: Organization;
}

export const useUserManagementHelper = (): UseOrganizationUserManagementHelperResult => {
  const {t} = useTranslation('Users');
  const user = useSelector(selectUserProfile);
  const {hasPermissions} = usePermissions();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState(['date']);
  const [searchTermDebounced] = useDebouncedValue(searchTerm, 200);
  const [invitations, setInvitations] = useState<OrganizationInvitation[]>([]);
  const [members, setMembers] = useState<OrganizationMember[]>([]);

  const [pagedListItems, setPagedListItems] = useState<ListItem[]>([]);
  const [listItems, setListItems] = useState<ListItem[]>([]);

  const {page} = useParams<{ page: string }>();

  const formatTitleText = (member: OrganizationMember) => {
    let title = '';
    if (member.user.firstName && member.user.lastName) {
      title = `${member.user.firstName} ${member.user.lastName}`;
    } else {
      title = member.user.email;
    }
    if (member.user.email === user?.email) {
      title = `${title} (${t('You')})`;
    }
    
    return title;
  }
  
  /**
   * Append page param for paginator
   * */
  useEffect(() => !page && navigate('1') || undefined, [page]);

  /**
   * Retrieve all invitations and members for the current organization
   * */
  useEffect(() => {
    const signal = new AbortController();

    Promise.all([
        OrganizationApi.GetOrganizationMembers(signal.signal),
        OrganizationApi.GetOrganizationInvitations(signal.signal),
      ])
      .then(([members, invitations]) => {
        setMembers(members);
        setInvitations(invitations);
      })
      .finally(() => setLoading(false));

    return () => signal.abort();
  }, []);

  /**
   * Map, Merge, Sort and Filter the list of members and invitations
   * */
  useEffect(() => {
    let items = [];
    const [sort] = sortBy;

    items.push(...members.filter(a => !!a.user.identityId).map(member => ({
      type: 'member',
      key: member.user.email,
      note: member.permissions.find(a => a.permissionId == PermissionsType.AccountOwner) ? t('Administrator') : '',
      title: formatTitleText(member),
      subtitle: !!member.user.firstName && !!member.user.lastName ? member.user.email : '',
      sortValue: sort === 'date'
        ? member.created
        : formatTitleText(member).toLowerCase(),
    }) as ListItem));

    items.push(...invitations.map(invitation => ({
      type: 'invitation',
      key: invitation.email,
      title: invitation.email,
      subtitle: !invitation.isActive ? t('Invitation Expired') : t('Invited'),
      sortValue: sort === 'date' ? invitation.createdDate ?? new Date(Date.now()).toISOString() : invitation.email.toLowerCase(),
    }) as ListItem));

    if (searchTermDebounced) {
      items = items.filter(item => item.title.toLowerCase().includes(searchTermDebounced.toLowerCase()) ||
        item.subtitle.toLowerCase().includes(searchTermDebounced.toLowerCase()));
    }

    items.sort((a, b) => {
      let value = 0;
      if (a.sortValue === undefined || b.sortValue === undefined) {
        value = 1;
      } else if (a.sortValue < b.sortValue) {
        value = 1;
      } else if (a.sortValue > b.sortValue) {
        value = -1;
      } else {
        value = 0;
      }
      
      return sort === 'date' ? value : value * -1;
    });

    navigate(`/users/1`);
    setListItems(items);
  }, [invitations, members, searchTermDebounced, sortBy]);

  /**
   * Paginate the list of members and invitations
   * */
  useEffect(() => setPagedListItems(listItems.slice((+page! - 1) * 10, +page! * 10)), [listItems, page]);

  const canManage = hasPermissions(PermissionsType.ManageAccess);

  const handleUserAdd = (newInvitations: OrganizationInvitation[]) => {
    setInvitations([...invitations, ...newInvitations]);
  };

  return {
    canManage,
    loading: loading,
    count: listItems.length,
    page: +page!,
    listItems: pagedListItems,
    emails: listItems.map(a => a.key),
    searchTerm: searchTerm,
    setSearchTerm: setSearchTerm,
    sortBy: sortBy,
    setSortBy: setSortBy,
    members: members,
    handleUserAdd,
    organization: user?.currentOrganization!,
  };
};