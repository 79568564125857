import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Banner} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {selectGigyaAccount} from '../../../store/account/accountSlice';
import Cookie from 'universal-cookie';

export const SingedInOnBehalfBanner: React.FunctionComponent = () => {
  const {t} = useTranslation('Common');

  const account = useSelector(selectGigyaAccount);

  const [hasSignedInOnBehalf, setHasSignedInOnBehalf] = useState(false);
  const cookie = new Cookie();

  useEffect(() => {
    const signInOnBehalfCookie = cookie.get('laerdal-signin-on-behalf');
    setHasSignedInOnBehalf(!!signInOnBehalfCookie);
  }, []);

  const signOut = (e: React.MouseEvent) => {
    e.preventDefault();
    cookie.remove('laerdal-signin-on-behalf', {path: "/", domain: ".laerdal.com"});
    // @ts-ignore
    window.gigya.accounts.logout();
    window.location.reload();
  };

  if (!hasSignedInOnBehalf || !account) {
    return null;
  }

  return <Banner type="critical" link={'#'} linkText={t('End session')} linkAction={signOut}>
    {t('You are currently signed in on behalf of {{email}}.', {email: account?.email})}{' '}
  </Banner>;

};
