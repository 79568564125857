import {
  BREAKPOINTS,
  COLORS,
  ComponentM,
  ComponentSStyling,
  ComponentMStyling,
  ComponentTextStyle, ComponentXXSStyling,
} from '@laerdal/life-react-components';
import styled from 'styled-components';

export const InnerPropertyWrapper = styled.div`
  width: 100%;
`;

export const PropertyLabel = styled.div`
  text-transform: uppercase;
  ${ComponentXXSStyling(ComponentTextStyle.UppercaseBold, COLORS.neutral_400)}
  ${BREAKPOINTS.MEDIUM}{
    ${ComponentXXSStyling(ComponentTextStyle.UppercaseBold, COLORS.neutral_400)}
  }
`;

export const ValueContainer = styled.div`
`;

const Value = styled(ComponentM)`
  word-wrap: anywhere;
`;


type PropertyWrapperProps = {
  label: string;
  value?: string;
  testid?: string
  className?: string;
};

const PropertyWrapper = ({label, value, testid, className}: PropertyWrapperProps) => {
  return (
    <InnerPropertyWrapper className={className}>
      <PropertyLabel>{label}</PropertyLabel>
      <ValueContainer data-testid={testid}>
        {
          value?.split(/\r?\n/).filter(x => x)
            .map((paragraph, i) => <Value key={i}>{paragraph}</Value>)
        }
      </ValueContainer>
    </InnerPropertyWrapper>
  );
};

export default PropertyWrapper;