import React from "react";
import styled from "styled-components";
import {
  BREAKPOINTS,
  Button,
  COLORS,
  ContentIcons,
  HorizontalCard,
  PageWidth,
  SystemIcons,
} from "@laerdal/life-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { UserCard } from "./components/UserCard";
import { useFeatures } from "../../hooks/Features";
import { FeatureNames } from "../../model/constants/FeatureConstants";
import { useSelector } from "react-redux";
import { selectUserProfile } from "../../store/account/accountSlice";
import { StyledPageWidth } from "../_commonComponents/StyledComponents";

const Overview = styled.div`
  order: 1;
  ${BREAKPOINTS.MEDIUM} {
    order: 3;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  order: 2;
  ${BREAKPOINTS.MEDIUM} {
    max-width: calc(100% - 240px - 32px); //shrink for medium to large
  }
`;

const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.MEDIUM} {
    display: flex;
  }
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  color: #000;
`;

const Content = styled.div`
  order: 3;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 2;
  height: max-content;

  .item {
    flex: 1;
    flex-basis: 60%;
    height: max-content;
  }

  .item:nth-child(2n + 1) {
    order: 1;
  }

  .item:nth-child(2n) {
    order: 2;
  }

  ${BREAKPOINTS.MEDIUM} {
    gap: 16px;
    flex-wrap: wrap;
    flex-direction: row;
  }

  ${BREAKPOINTS.LARGE} {
    .item {
      max-width: calc(50% - 8px);
      flex: 1;
      flex-basis: 40%;
      height: max-content;
      order: 1 !important;
    }
  }
`;

export const ProfilePage = () => {
  const { t } = useTranslation("Profile");
  const user = useSelector(selectUserProfile);
  const navigate = useNavigate();

  const hasOrganization = !!user?.currentOrganization;

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
      {hasOrganization && (
        <Overview>
          <Button
            variant="tertiary"
            icon={<SystemIcons.Institute />}
            onClick={() => navigate("/overview")}
          >
            {t("Account overview")}
          </Button>
        </Overview>
      )}
      <Header>
        <Title>{t("My Profile")}</Title>
        <Description>
          {t(
            "Manage your details and how to appear to others at your organization."
          )}
        </Description>
      </Header>
      <Content>
        <UserCard />
        <Navigation>
          <HorizontalCard
            title={t("Language & Region")}
            data-testid="LanguageRegionLink"
            icon={<ContentIcons.Translation />}
            className={"item"}
            action={() => navigate("language-region")}
            actions={[
              {
                componentType: "icon",
                icon: <SystemIcons.ChevronRight />,
                action: () => navigate("language-region"),
              },
            ]}
          />

          <HorizontalCard
            title={t("Change password")}
            data-testid="ChangePasswordLink"
            icon={<ContentIcons.Note />}
            className={"item"}
            action={() => navigate("password")}
            actions={[
              {
                componentType: "icon",
                icon: <SystemIcons.ChevronRight />,
                action: () => navigate("password"),
              },
            ]}
          />

          {user?.switchOrganizationEnabled && (
            <HorizontalCard
              title={t("My organizations")}
              data-testid="MyOrganizationsLink"
              icon={<ContentIcons.Institute />}
              className={"item"}
              action={() => navigate("organizations")}
              actions={[
                {
                  componentType: "icon",
                  icon: <SystemIcons.ChevronRight />,
                  action: () => navigate("organizations"),
                },
              ]}
            />
          )}
          {hasOrganization && (
            <HorizontalCard
              title={t("About you")}
              data-testid="AboutProfileLink"
              icon={<ContentIcons.SwitchRoles />}
              className={"item"}
              action={() => navigate("roles")}
              actions={[
                {
                  componentType: "icon",
                  icon: <SystemIcons.ChevronRight />,
                  action: () => navigate("roles"),
                },
              ]}
            />
          )}

          {/* This can be returned once we have something on the user settings page */}
          {/* <HorizontalCard title={t('Settings')}
                          data-testid='SettingsLink'
                          icon={<ContentIcons.Settings/>}
                          className={'item'}
                          action={() => navigate('settings')}
                          actions={[
                            {
                              componentType: 'icon',
                              icon: <SystemIcons.ChevronRight/>,
                              action: () => navigate('settings'),
                            },
                          ]}
          /> */}
        </Navigation>
      </Content>
    </StyledPageWidth>
  );
};
