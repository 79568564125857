import {Banner, BREAKPOINTS, LoadingIndicator, PageWidth} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {selectIsAuthenticated, selectUserProfile} from '../../store/account/accountSlice';
import {useEffect} from "react";
import {useNavigate} from "react-router";
import { StyledPageWidth } from '../_commonComponents/StyledComponents';

const LoadingContainer = styled.div`
  padding: 50px;
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const profile = useSelector(selectUserProfile);
  const hasOrganization = !!profile?.currentOrganization?.id;

  useEffect(() => {
    if(isAuthenticated && profile && hasOrganization) {
      //@ts-ignore
      posthog.capture?.('Landing Overview');
      navigate('/overview', {replace: true} );
    }
    if(isAuthenticated && profile && !hasOrganization) {
      //@ts-ignore
      posthog.capture?.('Landing Profile');
      navigate('/profile', {replace: true} );
    }
  }, [isAuthenticated, profile, hasOrganization]);

  return (
    <>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
          <LoadingContainer>
            <LoadingIndicator/>
          </LoadingContainer>
      </StyledPageWidth>
    </>);
};

export default LandingPage;