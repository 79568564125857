import React from "react";
import {
  BREAKPOINTS,
  COLORS,
  ModalDialog,
  Size
} from "@laerdal/life-react-components";
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import {useMediaMatch} from "rooks";

const Wrapper = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
  max-width: 480px;
  
  span{
    font-weight: 700;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export const AdminPermissionsRequiredModal = ({isOpen, onClose}: Props) => {

  const {t} = useTranslation('Organization');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  return <ModalDialog isModalOpen={isOpen}
                      closeModalAndClearInput={onClose}
                      closeAction={onClose}
                      submitAction={e => e?.preventDefault()}
                      size={isMediumScreen ? Size.Medium : Size.Small}
                      title={t('Action unavailable')}
                      buttons={[{
                        text: t('Okay'),
                        action: onClose,
                        variant: 'primary',
                      }]}>
    <Wrapper>
      <Trans t={t} ns={'Organization'} key={'AdminPermissionsRequiredMessage'}>
        Only people with <span>Administrator</span> permissions can remove other administrator.
      </Trans>
    </Wrapper>
  </ModalDialog>
};