import {
  BREAKPOINTS,
  ComponentM,
  ComponentTextStyle,
  ComponentXS,
  ModalDialog,
  Size,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from "rooks";
import AddressFormat from './AddressFormat';
import { AddressDto } from '../../model/dto/address/addressDto';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Par = styled.p`
  ${BREAKPOINTS.LARGE}{
    line-height: 32px; 
    font-size: 20px;
  }

  ${BREAKPOINTS.MEDIUM}{
    font-size: 18px;
    line-height: 28px;
  }

  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: Lato;
  font-style: normal;
`;

export interface Props {
  isOpen: boolean;
  address?: AddressDto;
  description?: string;
  mainButtonVariant: 'primary' | 'secondary' | 'tertiary' | 'positive' | 'critical';
  mainButtonText: string;
  secondaryButtonText?: string;
  titleText: string;

  onSubmit: () => void;
  onClose: () => void;
}

const AddressActionModal = ({isOpen, address, description, onClose, onSubmit, mainButtonVariant, mainButtonText, titleText, secondaryButtonText}: Props) => {
  const {t} = useTranslation('Address');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);
  
  const close = () => {
    onClose();
  }

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={close}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 closeModalAndClearInput={close}
                 submitAction={(e) => e?.preventDefault()}
                 buttons={[{
                   variant: 'tertiary',
                   disabled: isLoading,
                   action: close,
                   text: secondaryButtonText ?? t('Close')
                 },{
                  variant: mainButtonVariant,
                  disabled: isLoading,
                  loading: isLoading,
                  action: ()=>{
                    setIsLoading(true);
                    onSubmit()
                  },
                  text: mainButtonText
                }
                 ]}
                 title={ titleText }>
      <Wrapper>
        <Par>
          {description}
        </Par>
      </Wrapper>
    </ModalDialog>);
}

export default AddressActionModal;