import React from 'react';
import styled from "styled-components";
import {Button, COLORS, ComponentM, ComponentTextStyle} from "@laerdal/life-react-components";
import {useSelector} from "react-redux";
import {selectUserProfile} from "../../../store/account/accountSlice";
import {useNavigate} from "react-router";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  width: 320px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  background-color: ${COLORS.white};
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  height: max-content;
`;

const Initials = styled.h6`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: ${COLORS.primary_500};
  color: ${COLORS.white};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

const Email = styled(ComponentM)`
  word-break: break-word;
`;


export const Profile = () => {
  const {t} = useTranslation('Overview');

  const navigate = useNavigate();
  const user = useSelector(selectUserProfile);

  const initials = (user?.firstName?.charAt(0) || '') + (user?.lastName?.charAt(0) || '');
  return (
    <Wrapper>
      <Initials>
        {initials}
      </Initials>
      <Content>
        <ComponentM textStyle={ComponentTextStyle.Bold}>{user?.firstName} {user?.lastName}</ComponentM>
        <Email>{user?.email}</Email>
      </Content>
      <Actions>
        <Button variant={'secondary'}
                onClick={() => navigate('/profile')}>
          {t('Edit profile')}
        </Button>
        <Button variant={'tertiary'}
                onClick={() => navigate('/profile/password')}>
          {t('Change password')}
        </Button>
      </Actions>
    </Wrapper>
  )

};