import { useSelector } from "react-redux";
import {
  selectFeatures,
  selectIsFeaturesInitialized,
} from "../store/features/featuresSlice";
import { FeatureNames } from "../model/constants/FeatureConstants";
import { User } from "../model/dto/userProfile/user";
import { Feature } from "../model/dto/settings/feature";
import {
  selectUserProfile,
  selectUserWebshopMarketCountry,
} from "../store/account/accountSlice";

const checkSupportedCountries = (feature: Feature, user?: User) => {
  return (
    feature.enabled &&
    !feature.countries?.find(
      (a) => a === user?.currentOrganization?.address?.country?.codeAlpha3
    ) &&
    !feature.additional?.find((a) => a === user?.email)
  );
};

export const useFeatures = (): [(name: FeatureNames) => boolean, boolean] => {
  const features = useSelector(selectFeatures);
  const marketCountry = useSelector(selectUserWebshopMarketCountry);

  const initialized = useSelector(selectIsFeaturesInitialized);
  const user = useSelector(selectUserProfile);

  const checkFeature = (name: FeatureNames) => {
    if (!features) return false;

    const feature = features?.find((f) => f.name === name) as Feature;

    switch (feature?.name) {
      case FeatureNames.HideProductPrices:
        return (
          feature.enabled &&
          !!feature?.countries?.find(
            (a) => a === user?.currentOrganization?.address?.country?.codeAlpha3
          )
        );
      default:
        return feature?.enabled ?? false;
    }
  };

  return [checkFeature, initialized];
};
