import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {
  BREAKPOINTS, LoadingIndicator
} from "@laerdal/life-react-components";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import { StyledPageWidth } from '../_commonComponents/StyledComponents';
import {useSelector} from "react-redux";
import {selectUserProfile} from "../../store/account/accountSlice";

const Content = styled.div`
  min-height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  ${BREAKPOINTS.MEDIUM} {
    .screenset-container {
      width: 50%;
    }
  }
`;

export const ChangePasswordPage = () => {
  const {t, i18n} = useTranslation('Profile');
  const profile = useSelector(selectUserProfile);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleBack = () => navigate('/profile');

  useEffect(() => {
    // @ts-ignore
    window.gigya?.accounts?.showScreenSet({
      screenSet: 'Auth2-ProfileUpdate',
      containerID: 'gigya-container',
      startScreen: 'gigya-change-password-screen',
      lang: i18n.language,
      context: {
        lang: i18n.language,
        loginID: profile?.email,
        environment: process.env.REACT_APP_GIGYA_ENV,
        onCancelFn: () => handleBack(),
        onSuccessFn: () => handleBack(),
        onAfterScreenLoadFn: (event: any) => {
          setLoading(false);
        }
      }
    });

  }, []);

  return (
    <StyledPageWidth useMaxWidth={true} maxWidth={800}>
      {
        loading && <LoadingIndicator/>
      }
      <Content style={{visibility: loading ? 'hidden' : 'visible'}}>
        <div id="gigya-container"/>
      </Content>
    </StyledPageWidth>
  )

};