import axios from 'axios';
import AuthApi from './AuthApi';
import {IdPConfigDto} from '../../model/dto/idP/IdPConfig';
import {SPConfigDto} from '../../model/dto/idP/SPConfig';
import {BaseApi} from './BaseApi';

class SAMLApi extends BaseApi {
  static instance = new SAMLApi();

  constructor() {
    super();
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
  }

  GetSPConfig = async (): Promise<SPConfigDto> => {
    const request = async () => axios.get(`${process.env.REACT_APP_API_URL}/saml/sp/config`, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(r => r.data).catch(a => a.response);

    return this.GetCached('idp', 'GetSPConfig', request);
  };


  GetIdP = async (): Promise<IdPConfigDto> => {
    const request = async () => axios.get(`${process.env.REACT_APP_API_URL}/saml/IdP`, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(r => r.data).catch(a => a.response);

    return this.GetCached('idp', 'GetIdP', request);
  };

  RegisterIdP = async (config: IdPConfigDto): Promise<string> => {
    return axios.post(`${process.env.REACT_APP_API_URL}/saml/IdP`, config, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(r => r.data).then(a => {
      this.Clear('idp');
      return a;
    });
  };

  ImportIdPUrl = async (url: string): Promise<IdPConfigDto> => {
    return axios.get(`${process.env.REACT_APP_API_URL}/saml/IdP/import/url?url=${encodeURIComponent(url)}`, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(r => r.data);
  };

  ImportIdPFile = async (file: File): Promise<IdPConfigDto> => {
    const data = new FormData();
    data.append('file', file);
    return axios.post(`${process.env.REACT_APP_API_URL}/saml/IdP/import/xml`, data, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    }).then(r => r.data);
  };

}


export default SAMLApi.instance;
