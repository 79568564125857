import React from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  COLORS,
  ComponentSStyling,
  ComponentTextStyle,
  SystemIcons,
} from '@laerdal/life-react-components';
import { Trans } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 8px 16px;
  
  .checkbox{
    width: max-content;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  gap: 4px;
  flex: 1;
`;

const Title = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  
  .disabled & {
    color: ${COLORS.neutral_300};
  }
`;

const Description = styled.p`
  color: ${COLORS.neutral_600};

  .disabled & {
    color: ${COLORS.neutral_300};
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
`;

interface Props {
  id: string;
  name: string;
  description: string;
  selected: boolean;
  onToggle: (checked: boolean) => void;
  disabled?: boolean;
  locked?: boolean;
}

export const Permission = ({name, description, selected, onToggle, disabled, locked}: Props) => {

  return (
    <Wrapper className={`permission`}>
      {
        (disabled || !locked) &&
        <Checkbox className={'checkbox'} disabled={disabled} selected={selected} select={onToggle}/>
      }
      {
        !disabled && locked &&
        <Icon><SystemIcons.LockedOn color={COLORS.neutral_600}/></Icon>
      }
      <Content>
        <Title>
          {name}
        </Title>
        <Description>
          <Trans>
            {description}
          </Trans>
        </Description>
      </Content>
    </Wrapper>
  );
};