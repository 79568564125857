import babel from "@babel/core";
import { GigyaUserData } from "../../model/gigya/gigyaUserData";
import { isExternalEmail } from "./googleAnalytics";

class HotjarService {
    StartAnalytics = (account: GigyaUserData | undefined): void => {
        if (!!account 
            && isExternalEmail(account.email)
            && !!process.env.REACT_APP_HOTJAR) {
                setTimeout(`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`, 100);
        }

        if (!!account 
            && isExternalEmail(account.email)
            && !!process.env.REACT_APP_HOTJAR_ECOMMERCE) {
                setTimeout(`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_ECOMMERCE},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`, 100);
        }
    }
}

export default new HotjarService();