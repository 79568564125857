import {LoadingIndicator, PageWidth, ScreenSetsContainer} from '@laerdal/life-react-components';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {
  selectIsAuthenticated, selectUserProfile,
  setGigyaAccount,
  setUserProfile,
} from '../../store/account/accountSlice';
import qs from 'query-string';
import {useNavigate} from 'react-router';
import {useAppDispatch} from '../../store/store';
import AuthApi from '../../services/api/AuthApi';
import UserApi from '../../services/api/UserApi';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  padding: 50px;
`;

const LoginPage = () => {
  const {t, i18n} = useTranslation('Login');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const profile = useSelector(selectUserProfile);

  const handleLogin = (eventObj: any) => {
    AuthApi.retrieveAccountInfo()
      .then(response => {
        dispatch(
          setGigyaAccount({
            country: response.data?.organization?.country,
            dateRegistered: response.registered,
            email: response.profile.email,
            firstName: response.profile.firstName,
            identityId: response.UID,
            lastLogin: response.lastLogin,
            lastName: response.profile.lastName,
            locale: response.profile.locale ?? process.env.REACT_APP_DEFAULT_LANGUAGE,
            market: response.data.commerce?.market,
          }),
        );
      })
      .then(() => UserApi.getUser().then((user) => {
        dispatch(setUserProfile(user));
      }));
  };

  useEffect(() => {
    const isBookingPge = window.location.href.indexOf('/appointments/details/') > 0 && window.location.href.indexOf('/book/')  > 0;

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const langValue = urlParams.get('lang');

    if (!isAuthenticated) {
      // @ts-ignore
      window.gigya.sso.login({
        authFlow: 'redirect',
        redirectURL: window.location.href,
        useChildContext: true,
        context: {
          application: 'Account',
          allowRegistration: isBookingPge,
          lng: langValue
        }
      });
    } else {
      if (profile) {
        const returnUrl = qs.parse(window.location.search)['returnUrl'] as string;
        navigate(returnUrl || '/');
      }
    }
  }, [isAuthenticated, profile]);

  useEffect(() => {
    // @ts-ignore
    window.gigya.accounts.addEventHandlers({onLogin: handleLogin});
  }, []);

  return (
    <>
      <PageWidth useMaxWidth={true} maxWidth={1600}>
        {!isAuthenticated && <ScreenSetsContainer centerContents={true} id="gigya-container-mlp"/>}
        {isAuthenticated && <LoadingContainer><LoadingIndicator/></LoadingContainer>}
      </PageWidth>

    </>);
};

export default LoginPage;