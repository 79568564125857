import React from 'react';
import styled from 'styled-components';
import {BREAKPOINTS, COLORS, ComponentMStyling, ComponentTextStyle,} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {selectUserProfile} from "../../../store/account/accountSlice";
import PropertyWrapper from "../../_commonComponents/PropertyWrapper";
import {Industry} from "../../../model/dto/userProfile/industry";
import moment from 'moment-timezone';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  
  .first{
    display: none;
  }
  ${BREAKPOINTS.MEDIUM}{
    .first{
      display: block;
    }
  }

  .item{
    flex: 1;
    flex-basis: 51%;
  }
  ${BREAKPOINTS.MEDIUM}{
    .item{
      flex-basis: 34%;
    }
  }
`;

const Title = styled.div`
  display: none;
  ${BREAKPOINTS.MEDIUM}{
    display: block;
    flex-basis: 100%;
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
`;

export const OrganizationDetails = () => {
  const {t} = useTranslation('Organization');
  const user = useSelector(selectUserProfile);

  const organization = user!.currentOrganization!;

  return <Wrapper>
    <Title>{t('Account info')}</Title>
    <PropertyWrapper label={t('Name')} className={'item first'} value={organization.name}/>
    
    {organization.address?.country?.name && <PropertyWrapper label={t('Country/Region')} className={'item'} value={organization.address?.country?.name}/>}
    {!!organization.customerNo && <PropertyWrapper label={t('Account number (Customer ID)')} className={'item'} value={organization.customerNo}/>}

  </Wrapper>;
};