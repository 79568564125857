import axios from 'axios';
import {AppointmentsDto} from '../../model/dto/appointments/appointmentsDto';
import AuthApi from './AuthApi';
import {AppointmentDetailsDto} from '../../model/dto/appointments/appointmentDetailsDto';
import {BaseApi} from './BaseApi';
import { AppointmentBookingSlotDto } from '../../model/dto/appointments/appointmentBookingSlotDto';
import { AppointmentBookDto } from '../../model/dto/appointments/appointmentBookDto';

class AppointmentsApi extends BaseApi {
  GetAppointments = async (
    lang: string,
    searchTerm: string | undefined,
    serviceAppointmentStatus: string,
    workOrderType: string,
    deliveryType: string
  ): Promise<AppointmentsDto> => {

    

    let additionalQueryParameters = '';
    if (!!searchTerm) {
      additionalQueryParameters += `&searchTerm=${encodeURIComponent(searchTerm)}`;
    }
    if (!!deliveryType) {
      additionalQueryParameters += `&deliveryType=${encodeURIComponent(deliveryType)}`;
    }
    if (!!workOrderType) {
      additionalQueryParameters += `&workOrderType=${encodeURIComponent(workOrderType)}`;
    }
    if (!!serviceAppointmentStatus) {
      additionalQueryParameters +=
        `&status=${encodeURIComponent(serviceAppointmentStatus)}`;
    }
    if (!!lang) {
      additionalQueryParameters += `&lang=${lang}`;
    }

    return axios.get(
        `${process.env.REACT_APP_API_URL}/Appointments?` + additionalQueryParameters,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetAppointmentDetails = async (appointmentNumber: string, lang: string, s?: string,): Promise<AppointmentDetailsDto> => {
    let url = `${process.env.REACT_APP_API_URL}/Appointments/${appointmentNumber}?lang=${lang}`;

    if (!!s) {
      url += `&s=${s}`;
    }       

    return axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      });
  }

  GetAppointmentSlots = async (appointmentNumber: string, utcDate: string): Promise<AppointmentBookingSlotDto[]> => {
    
    return axios.get(
        `${process.env.REACT_APP_API_URL}/Appointments/slots?appointmentNumber=${appointmentNumber}&date=${utcDate}`,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      });
  }

  BookAppointmentSlot = async (appointmentNumber: string, date: string): Promise<void> => {
    const appointmentBookDto: AppointmentBookDto ={
      appointmentNumber: appointmentNumber,
      date: date
    }
    return axios.post(`${process.env.REACT_APP_API_URL}/Appointments/book`, appointmentBookDto,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data
        }
      });
  }
  
  DownloadReport = async (reportName: string, appointmentNumber: string, id: string, handleError: (message: string) => void): Promise<void> => {
    const downloadLink = `${process.env.REACT_APP_API_URL}/Appointments/download?appointmentNumber=${appointmentNumber}&id=${id}`;
    return axios
      .get(downloadLink, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {type: 'application/pdf'});

          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(blob);
          const downloadElement = document.createElement('a');
          downloadElement.href = link;
          downloadElement.setAttribute(
            'download',
            reportName + `.pdf`,
          );
          document.body.appendChild(downloadElement);
          downloadElement.click();
          downloadElement!.parentNode!.removeChild(downloadElement);
        }
      }).catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 401:
          case 403:
            handleError('Not authorized to download');
            break;
          case 404:
            handleError(
              'The invoice document is not generated yet, please try to download it later. If this error persists please contact support');
            break;
          default:
            handleError('Issue when downloading');
        }
      });
  };

  ClearCache = () => {
    this.Clear('appointments');
  };

}

export default new AppointmentsApi();