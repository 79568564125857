import {
  BREAKPOINTS,
  Button,
  ComponentTextStyle,
  ComponentXL,
  ComponentL,
  BackButton,
  LoadingIndicator,
  PageWidth,
  Size,
  ComponentM,
} from '@laerdal/life-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import PropertyWrapper from '../../_commonComponents/PropertyWrapper';
import { OrderDetailsDto } from '../../../model/dto/orders/orderDetailsDto';
import OrdersApi from '../../../services/api/OrdersApi';
import { OrderAssetsTable } from '../../_commonComponents/OrderAssetsTable';
import { useMediaMatch } from 'rooks';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectGigyaAccount } from '../../../store/account/accountSlice';
import { useFeatures } from '../../../hooks/Features';
import { FeatureNames } from '../../../model/constants/FeatureConstants';
import { BasicCard, SmallCard } from '../../_commonComponents/BasicBox';
import { GetAdressString } from '../../../util/adress-helper';
import ShipmentsApi from '../../../services/api/ShipmentsApi';
import { ShipmentListItemDto } from '../../../model/dto/shipments/shipmentListItemDto';
import { InvoiceListItemDto } from '../../../model/dto/invoices/invoiceListItemDto';
import InvoicesApi from '../../../services/api/InvoicesApi';
import OrderShipmentsModal from './OrderShipmentsModal';
import OrderInvoicesModal from './OrderInvoicesModal';
import { StyledPageWidth } from '../../_commonComponents/StyledComponents';

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;
const DetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow:1;
`;

const MainContent = styled.div`
  margin:32px 0 0 0;
  display:flex;
  flex-direction:column;
  gap: 32px;
`;


const Row = styled.div`
  width:100%;
  display: flex;
  gap: 8px;

  flex-direction: column;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;



const OrderDetailsPage = () => {
  const navigate = useNavigate();

  const [checkFeature, isFeaturesInitialized] = useFeatures();
  const { orderNumber } = useParams<{ orderNumber: string }>();
  const { t, i18n } = useTranslation('Order');
  const account = useSelector(selectGigyaAccount);

  const [loading, setLoading] = useState<boolean>(true);
  const [orderDetails, setOrderDetails] = useState<OrderDetailsDto | undefined>(undefined);
  const [orderSurcharges, setOrderSurcharges] = useState<number>(0);

  const [isShipmentsModalOpen, setShipmentsModalOpen] = useState<boolean>(false);
  const [shipments, setShipments] = useState<ShipmentListItemDto[]>([]);
  const [loadingShipments, setLoadingShipments] = useState<boolean>(true);

  const [isInvoicesModalOpen, setInvoicesModalOpen] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceListItemDto[]>([]);
  const [loadingInvoices, setLoadingInvoices] = useState<boolean>(true);

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  useEffect(() => {
    OrdersApi.GetOrderDetails(orderNumber!).then((response) => {
      setOrderDetails(response);
      setLoading(false);

      let charge = 0
      if (response.trailerCode1Charge) charge += Number(response.trailerCode1Charge);
      if (response.trailerCode2Charge) charge += Number(response.trailerCode2Charge);
      if (response.trailerCode3Charge) charge += Number(response.trailerCode3Charge);
      setOrderSurcharges(charge)
    });

    ShipmentsApi.GetShipments(
      1000,
      0, 
      i18n.language,
      undefined,
      undefined,
      undefined,
      orderNumber
    ).then((response) => {
      setShipments(response.items);
      setLoadingShipments(false);
    });

    InvoicesApi.GetInvoices(
      1000,
      0,
      i18n.language,
      undefined,
      undefined,
      undefined,
      orderNumber
    ).then((response) => {
      setInvoices(response.items);
      setLoadingInvoices(false)
    });

  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Order details page')}</title>
      </Helmet>

      <OrderShipmentsModal 
        isOpen={isShipmentsModalOpen} 
        onClose={() => {
          setShipmentsModalOpen(false)
        }} 
        shipments={shipments} 
        loading={loadingShipments} />

      <OrderInvoicesModal 
        isOpen={isInvoicesModalOpen} 
        onClose={() => setInvoicesModalOpen(false)} 
        invoices={invoices} 
        loading={loadingInvoices} />


      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <BackButton 
          size={Size.Small} 
          onClick={() => {
            //@ts-ignore
            posthog.capture?.('OrderDetails BackToOrders');
            navigate('/ordersinvoices/orders')
          }}>
            {t('All Orders')}
          </BackButton>

        {(loading || loadingShipments || loadingInvoices) && <LoadingIndicator />}
        {orderDetails && shipments && invoices &&
          <>
            <div>
              <ComponentXL textStyle={ComponentTextStyle.Bold} data-testid="title-ordernumber" >{!!orderDetails.qadOrderNumber ? orderDetails.qadOrderNumber: orderDetails.orderNumber}</ComponentXL>

              <Row>
                { 
                  shipments.length > 0 && 
                  <Button 
                    size={isMediumScreen ? Size.Medium : Size.Small} 
                    onClick={() => { 
                      //@ts-ignore
                      posthog.capture?.('OrderDetails ShipmentsModalOpen');
                      setShipmentsModalOpen(true) 
                    }} >
                    { shipments.length == 1 ? t('Track shipment') : t('Track shipments')}
                  </Button>
                }
                { 
                  invoices.length > 0 && 
                  <Button 
                    size={isMediumScreen ? Size.Medium : Size.Small} 
                    onClick={() => { 
                      //@ts-ignore
                      posthog.capture?.('OrderDetails InvoiceModalOpen');
                      setInvoicesModalOpen(true)
                    }} 
                    variant="secondary">
                    { invoices.length == 1 ? t('View invoice') : t('View invoices')}
                  </Button>
                }
              </Row>
            </div>
            <MainContent>

              <Row>
                <BasicCard>
                  <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Order summary')}</ComponentM>

                  <DetailsContainer>
                    <DetailsContainerColumn>
                      <PropertyWrapper label={t('Account name')} testid='account-name' value={orderDetails.accountName} />
                      {orderDetails.orderNumber && <PropertyWrapper label={t('Order number')} testid='order-number' value={!!orderDetails.qadOrderNumber ? orderDetails.qadOrderNumber: orderDetails.orderNumber} />}
                      {orderDetails.poNumber && <PropertyWrapper label={t('PO number')} testid='po-number' value={orderDetails.poNumber} />}
                      {orderDetails.orderStartDate && <PropertyWrapper label={t('Order start date')} testid='order-start-date' value={moment(orderDetails.orderStartDate).format('LL')} />}
                    </DetailsContainerColumn>

                    <DetailsContainerColumn>
                      <PropertyWrapper label={t('Account number')} testid='account-number' value={orderDetails.accountNumber} />

                      {!checkFeature(FeatureNames.HideProductPrices) &&
                        orderDetails.orderTotal && <PropertyWrapper label={t('Order total')} testid='order-total' value={parseFloat(orderDetails.orderTotal ?? '0').toLocaleString(i18n.language, { style: 'currency', currency: orderDetails.currency })} />
                      }

                      {!checkFeature(FeatureNames.HideProductPrices) &&
                        orderDetails.totalTaxAmount &&
                        <PropertyWrapper label={t('Total tax amount')} testid='total-tax-amount' value={parseFloat(orderDetails.totalTaxAmount ?? '0').toLocaleString(i18n.language, { style: 'currency', currency: orderDetails.currency })} />
                      }
                      {!checkFeature(FeatureNames.HideProductPrices) &&
                        orderDetails.currency &&
                        <PropertyWrapper label={t('Currency')} testid='currency' value={orderDetails.currency} />
                      }
                      {!checkFeature(FeatureNames.HideProductPrices) &&
                        orderSurcharges > 0 &&
                        <PropertyWrapper
                          label={t('Shipping, handling and surcharges')}
                          testid='shipping-handling-surcharges'
                          value={orderSurcharges.toLocaleString(i18n.language, { style: 'currency', currency: orderDetails.currency })}
                        />
                      }
                    </DetailsContainerColumn>
                  </DetailsContainer>
                </BasicCard>

                <SmallCard>
                  <ComponentM textStyle={ComponentTextStyle.Bold} >{t('Shipment & Billing')}</ComponentM>
                  {orderDetails.billToAccount && <PropertyWrapper label={t('Bill to account')} testid='bill-to-account' value={orderDetails.billToAccount} />}
                  {orderDetails.billToAccount && <PropertyWrapper label={t('Account number')} testid='bill-to-account-number' value={orderDetails.billToAccountNumber} />}
                  {orderDetails.billToContact && <PropertyWrapper label={t('Bill to contact')} testid='bill-to-contact' value={orderDetails.billToContact} />}
                  {orderDetails.billToContactEmail && <PropertyWrapper label={t('Bill to contact email')} testid='bill-to-contact-email' value={orderDetails.billToContactEmail} />}
                  {orderDetails.shipToContact && <PropertyWrapper label={t('Ship to contact')} testid='ship-to-contact' value={orderDetails.shipToContact} />}
                  {orderDetails.billingAddress && <PropertyWrapper label={t('Billing address')} testid='billing-address' value={GetAdressString(orderDetails.billingAddress)} />}
                  {orderDetails.shippingAddress && <PropertyWrapper label={t('Shipping address')} testid='shipping-address' value={GetAdressString(orderDetails.shippingAddress)} />}
                </SmallCard>
              </Row>

              <div>
                <ComponentL textStyle={ComponentTextStyle.Bold} >{t('Products')}</ComponentL>
                <OrderAssetsTable orderNumber={orderDetails.orderNumber} />
              </div>
            </MainContent>
          </>
        }
      </StyledPageWidth>
    </>);
};

export default OrderDetailsPage;