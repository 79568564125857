import {
  BREAKPOINTS,
  COLORS,
  ListRow,
  LoadingIndicator,
  ModalDialog,
  Size,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import {useContext} from 'react';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from "rooks";
import { InvoiceListItemDto } from '../../../model/dto/invoices/invoiceListItemDto';
import moment from 'moment';
import InvoicesApi from '../../../services/api/InvoicesApi';
import { FailToastOptions } from '../../../model/constants/ToastConstants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

export interface Props {
  invoices: InvoiceListItemDto[],
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const OrderInvoicesModal = ({isOpen, onClose, loading, invoices}: Props) => {
  const {t} = useTranslation('Order');
  const {addToast} = useContext(ToastContext);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const handleDownloadError = (errorMessage: string) => {
    //@ts-ignore
    posthog.capture?.('OrderInvoicesModal DownloadInvoiceError');
    addToast(t(errorMessage), {...FailToastOptions, autoClose: false});
  };
  
  const close = () => {
    onClose();
  }

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={close}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 closeModalAndClearInput={close}
                 submitAction={(e) => e?.preventDefault()}
                 buttons={[{
                   variant: 'tertiary',
                   action: close,
                   text: t('Close')
                 }
                 ]}
                 title={ invoices?.length == 1 ? t('View invoice') : t('View invoices')}>
      <Wrapper>
        <Description>
          {t('Invoices related to this order are listed below.')}
        </Description>

        {loading && <LoadingIndicator />}

        {invoices && invoices.map(i => 
                            <ListRow mainText={i.invoiceNumber }
                              secondaryText={moment(i.invoiceDate).format('LL')}
                              note={t('Download')}
                              size={Size.Small}
                              rightSideIcon={<SystemIcons.Download />}
                              rightSideAction={() => {
                                //@ts-ignore
                                posthog.capture?.('OrderInvoicesModal DownloadInvoice');
                                InvoicesApi.DownloadInvoice(i.invoiceNumber, handleDownloadError)
                              }}
                              />
          )}
        
      </Wrapper>
    </ModalDialog>);
}

export default OrderInvoicesModal;