import { GigyaUserData } from "../../model/gigya/gigyaUserData";
import Cookie from 'universal-cookie';

class GoogleAnalyticsService {
    StartAnalytics = (account: GigyaUserData | undefined): void => {
        if(!!account 
            && isExternalEmail(account.email)
            && !!process.env.REACT_APP_GOOGLE_ANALYTICS){   

            // @ts-ignore
            window.dataLayer = window.dataLayer || [];
            // @ts-ignore
            function gtag(){dataLayer.push(arguments);}
            // @ts-ignore
            gtag('js', new Date());
            // @ts-ignore
            gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS);
          }
    }
  
  }
  
  export default new GoogleAnalyticsService();

  export function isExternalEmail(email: string){
    const cookie = new Cookie();
    const signInOnBehalfCookie = cookie.get('laerdal-signin-on-behalf');

    return email.indexOf('@laerdal') == -1          //laerdal emails
        && email.indexOf('laerdaltest01') == -1     //test accounts
        && !signInOnBehalfCookie;                   //log in as users
  }