import {useSelector} from "react-redux";
import {
  selectSubscription,
  selectSubscriptionAmendmentOptions,
  selectSubscriptionPriceConfigurations,
  selectSubscriptionService,
  setSubscriptionAmendmentOptions,
  setSubscriptionAmendmentPrice,
  setSubscriptionAvailablePlans,
  setSubscriptionAvailableTerms,
  setSubscriptionAvailableTiers,
  setSubscriptionHasChangedOptions
} from "../../../store/subscription/subscriptionSlice";
import {useEffect} from "react";
import {useAppDispatch} from "../../../store/store";
import {SubscriptionOptions} from "../../../model/dto/organization/subscriptionOptions";
import dayjs from "dayjs";
import {ServicePlanTier} from "../../../model/dto/userProfile/servicePlanTier";
import {useMatch} from "react-router-dom";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import {LicenseInformationHelper} from "./LicenseInformationHelper";

export const useLicenseAmendment = () => {
  const dispatch = useAppDispatch();
  const subscription = useSelector(selectSubscription);
  const options = useSelector(selectSubscriptionAmendmentOptions);
  const prices = useSelector(selectSubscriptionPriceConfigurations);
  const service = useSelector(selectSubscriptionService);

  const renew = !!useMatch('/licenses/:id/edit/renew');

  useEffect(() => {
    if (!subscription) return;

    const duration =
      subscription.billingInterval == BillingInterval.Monthly
        ? 1
        : subscription.billingInterval == BillingInterval.ThreeYear
          ? 36
          : subscription.billingInterval == BillingInterval.FiveYear
            ? 60
            : dayjs(subscription?.expirationDate).diff(dayjs(subscription?.startDate), 'month')

    dispatch(setSubscriptionAmendmentOptions({
      planId: subscription!.plan!.id,
      planTierId: subscription!.tier!.id,
      duration: duration,
      billingInterval: subscription!.billingInterval,
      autoRenew: renew || subscription!.autoRenew,
      maxSubscriptionInstances:
        subscription.tier.individualLicensing
          ? subscription!.maxSubscriptionInstances
          : null,
    } as SubscriptionOptions))
  }, [subscription])

  useEffect(() => {
    if (!options || !subscription) return;

    const existingDuration =
      subscription.billingInterval == BillingInterval.Monthly
        ? 1
        : subscription.billingInterval == BillingInterval.ThreeYear
          ? 36
          : subscription.billingInterval == BillingInterval.FiveYear
            ? 60
            : dayjs(subscription?.expirationDate).diff(dayjs(subscription?.startDate), 'month')

    const hasChangedOptions =
      options.autoRenew != subscription?.autoRenew ||
      options.billingInterval != subscription?.billingInterval ||
      options.duration != existingDuration ||
      (!!options.maxSubscriptionInstances && subscription?.maxSubscriptionInstances && options.maxSubscriptionInstances != subscription?.maxSubscriptionInstances) ||
      options.planId != subscription?.plan?.id ||
      options.planTierId != subscription?.tier?.id;

    dispatch(setSubscriptionHasChangedOptions(hasChangedOptions));
  }, [options]);

  useEffect(() => {
    if (!options || !prices?.length) return;

    const entry = prices.find(p =>
      p.planId == options.planId &&
      p.tierId == options.planTierId &&
      p.billingInterval == options.billingInterval &&
      p.duration == options.duration
    );

    if (!entry) return;

    const price = {
      price: (entry?.intervalSubtotal || entry?.subtotal || 0) * (options.maxSubscriptionInstances ?? 1),
      currency: entry?.currency ?? ''
    };

    dispatch(setSubscriptionAmendmentPrice(price));
  }, [options]);

  useEffect(() => {
    if (!options || !prices || !service) return;

    const plan = service?.availablePlans?.find(a => a.id === options?.planId);
    const tier = plan?.tiers?.find(a => a.id === options?.planTierId);

    const availablePrices = prices?.filter(a => a.planId === plan?.id && a.tierId === tier?.id);
    const terms = availablePrices.map(a => ({billingInterval: a.billingInterval, duration: a.duration}));

    dispatch(setSubscriptionAvailableTerms(terms));
  }, [options, prices, service]);

  useEffect(() => {
    if (!service || !prices) return;

    let plans = service.availablePlans?.filter(a => prices.some(p => p.planId === a.id));
    let tiers = plans?.reduce((prev, cur) => [...prev, ...cur.tiers], [] as ServicePlanTier[]).filter(a => prices.some(p => p.tierId === a.id));
    const terms = prices.map(a => ({billingInterval: a.billingInterval, duration: a.duration}));

    plans = plans?.sort((a, b) => (a.upgradeOrder ?? 0) - (b.upgradeOrder ?? 0)) ?? [];
    tiers = tiers?.sort((a, b) => (a.upgradeOrder ?? 0) - (b.upgradeOrder ?? 0)) ?? [];

    dispatch(setSubscriptionAvailablePlans(plans));
    dispatch(setSubscriptionAvailableTiers(tiers));
    dispatch(setSubscriptionAvailableTerms(terms));
  }, [service, prices]);

}