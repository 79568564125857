import {
  BREAKPOINTS,
  Button,
  COLORS,
  ComponentM,
  ComponentTextStyle,
  ComponentXL,
  LoadingIndicator,
  PageWidth,
  Size,
  SystemIcons,
  ToastContext,
  BackButton,
  ComponentL,
  Banner
} from '@laerdal/life-react-components';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import PropertyWrapper from '../../_commonComponents/PropertyWrapper';
import InvoicesApi from '../../../services/api/InvoicesApi';
import { useMediaMatch } from 'rooks';
import moment from 'moment';
import { InvoiceDetailsDto } from '../../../model/dto/invoices/invoiceDetailsDto';
import { FailToastOptions, InfoToastOptions } from '../../../model/constants/ToastConstants';
import { useSelector } from 'react-redux';
import { selectGigyaAccount, selectUserProfile } from '../../../store/account/accountSlice';
import { GigyaUserData } from '../../../model/gigya/gigyaUserData';
import { BasicCard } from '../../_commonComponents/BasicBox';
import InvoiceStatus from './InvoiceStatus';
import { StyledPageWidth } from '../../_commonComponents/StyledComponents';

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;
const DetailsContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow:1;
`;

const TrackingButton = styled(Button)`
  margin: 8px 0 32px 0;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;


  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;

export const FramesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 32px;

  ${BREAKPOINTS.MEDIUM}{
    flex-direction: row;
  }
`;

export const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;
const PdfContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  min-height: 1200px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: normal;
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.white};

  embed {
    
    height: 1200px;
  }
`;
const Frame = styled.div`
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: normal;
    gap: 16px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid ${COLORS.neutral_200};
    background: ${COLORS.white};
`;

const AppointmentDetail = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;

  ${BREAKPOINTS.LARGE}{
    width: 350px;
  }
  
`;
const AppointmentDetailCategory = styled.div`
  display: flex;
  width: 200px;
  align-items: center;
  gap: 8px;
`;
const AppointmentDetailDetail = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.neutral_100}
`;

const InvoiceDetailsContainer = styled.div`
`

const InvoiceDetailsPage = () => {
  const { addToast } = useContext(ToastContext);
  const navigate = useNavigate();
  
  const { invoiceNumber } = useParams<{ invoiceNumber: string }>();
  const { t, i18n } = useTranslation('Invoices');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingPreview, setLoadingPreview] = useState<boolean>(true);
  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsDto | undefined>(undefined);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [previewLink, setPreviewLink] = useState<string>('');
  const [invoiceProcessing, setInvoiceProcessing] = useState<boolean>(false); 


  const handleDownloadError = (errorMessage: string) => {
    addToast(t('This invoice is being processed and will be available for download soon.'), { ...InfoToastOptions, autoClose: false });
  };

  const getCurrency = (invoiceDetails: InvoiceDetailsDto | undefined, account: GigyaUserData | undefined): string => {
    if (!invoiceDetails || !account)
      return "";

    if (!invoiceDetails.currency)
      return invoiceDetails.invoiceTotalAmount;

    return parseFloat(invoiceDetails.invoiceTotalAmount ?? '0').toLocaleString(i18n.language, { style: 'currency', currency: invoiceDetails.currency });

  };

  const handleDownload = () => {
    //@ts-ignore
    posthog.capture?.('InvoiceDetails DownloadInvoice');
    setDownloading(true);
    InvoicesApi.DownloadInvoice(invoiceDetails!.invoiceNumber, handleDownloadError)
      .finally(() => setDownloading(false));
  };

  useEffect(() => {
    InvoicesApi.GetInvoiceDetails(invoiceNumber!, i18n.language).then((response) => {
      setInvoiceDetails(response);
      setLoading(false);
    });

    InvoicesApi.GetBlobLink(invoiceNumber!, (link) => { setPreviewLink(link) }, () => {})
      .finally(() => setLoadingPreview(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('Invoice details page')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>

        <BackButton 
          size={Size.Small} 
          onClick={() =>{
            //@ts-ignore
            posthog.capture?.('InvoiceDetails BackToInvoices');
            navigate('/ordersinvoices/invoices')
          }}>
            {t('Orders & Invoices')}
        </BackButton>

        {loading && <LoadingIndicator />}
        {invoiceDetails &&
          <InvoiceDetailsContainer>
            <Row>
              <Title>{t('Your Invoice')}</Title>
              <TrackingButton icon={<SystemIcons.CloudDownload />} onClick={handleDownload} variant="primary" disabled={!previewLink}
                  loading={downloading}>{t('Download Invoice')}</TrackingButton>
            </Row>

            <FramesContainer>
              <Frame>
                <AppointmentDetail>
                  <AppointmentDetailCategory>
                    <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Summary')}</ComponentL>
                  </AppointmentDetailCategory>
                </AppointmentDetail>

                <AppointmentDetail>
                  <AppointmentDetailCategory>
                    <ComponentM color={COLORS.neutral_600}>{t('Invoice number')}</ComponentM>
                  </AppointmentDetailCategory>
                  <AppointmentDetailDetail>
                    <ComponentM>{invoiceDetails.invoiceNumber}</ComponentM>
                  </AppointmentDetailDetail>
                </AppointmentDetail>
                
                { invoiceDetails.poNumber &&
                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.black}>{t('PO number')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM color={COLORS.black}>{invoiceDetails.poNumber}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>
                }

                <AppointmentDetail>
                  <AppointmentDetailCategory>
                    <ComponentM color={COLORS.black}>{t('Account name')}</ComponentM>
                  </AppointmentDetailCategory>
                  <AppointmentDetailDetail>
                    <ComponentM color={COLORS.black}>{invoiceDetails.accountName}</ComponentM>
                  </AppointmentDetailDetail>
                </AppointmentDetail>
                
                <AppointmentDetail>
                  <AppointmentDetailCategory>
                    <ComponentM color={COLORS.black}>{t('Account number')}</ComponentM>
                  </AppointmentDetailCategory>
                  <AppointmentDetailDetail>
                    <ComponentM>{invoiceDetails.accountNumber}</ComponentM>
                  </AppointmentDetailDetail>
                </AppointmentDetail>

                <Line />

                <AppointmentDetail>
                  <AppointmentDetailCategory>
                    <ComponentM color={COLORS.black}>{t('Invoice type')}</ComponentM>
                  </AppointmentDetailCategory>
                  <AppointmentDetailDetail>
                    <ComponentM color={COLORS.black}>{invoiceDetails.invoiceType}</ComponentM>
                  </AppointmentDetailDetail>
                </AppointmentDetail>

                {
                  invoiceDetails.currency && 
                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.black}>{t('Currency')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM color={COLORS.black}>{invoiceDetails.currency}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>
                }

                { invoiceDetails.invoiceDate &&
                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.black}>{t('Invoice date')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM color={COLORS.black}>{moment(invoiceDetails.invoiceDate).format('LL')}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>
                }


                { invoiceDetails.paymentDueDate &&
                  <AppointmentDetail>
                    <AppointmentDetailCategory>
                      <ComponentM color={COLORS.black}>{t('Payment due date')}</ComponentM>
                    </AppointmentDetailCategory>
                    <AppointmentDetailDetail>
                      <ComponentM color={COLORS.black}>{ moment(invoiceDetails.paymentDueDate).format('LL')}</ComponentM>
                    </AppointmentDetailDetail>
                  </AppointmentDetail>
                }

              </Frame>

              
              <PdfContainer>
                <ComponentL textStyle={ComponentTextStyle.Bold} >{t('Document preview')}</ComponentL>

                { loadingPreview &&
                  <LoadingIndicator/>
                }
                { !loadingPreview && !!previewLink &&
                  <embed src={previewLink}/>
                }
                { !loadingPreview && !previewLink &&
                  <Banner type='neutral'>
                    { t('This invoice is being processed and will be available for download soon.')}
                  </Banner> 
                }
              </PdfContainer>
              
            </FramesContainer>

            

            
          </InvoiceDetailsContainer>
        }
      </StyledPageWidth>
    </>);
};


export default InvoiceDetailsPage;