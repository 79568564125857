import {
  BREAKPOINTS,
  COLORS,
  HyperLink,
  LoadingIndicator,
  PageWidth,
  SearchBar,
  SegmentControl,
  Size,
  SystemIcons,
  Table,
  TablePagination,
  VerticalTabs
} from '@laerdal/life-react-components';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ShipmentListItemDto } from '../../../model/dto/shipments/shipmentListItemDto';
import { useDebouncedValue, useMediaMatch } from 'rooks';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../store/account/accountSlice';
import { useNavigate } from 'react-router-dom';
import { ListHeader, ListPreTitle, ListSubtitle, ListTitle, StyledPageWidth } from '../../_commonComponents/StyledComponents';
import moment from 'moment';
import ShipmentStatus from './ShipmentStatus';
import ShipmentsApi from '../../../services/api/ShipmentsApi';
import PaginationHelper from '../../../services/helpers/PaginationHelper';
import { TableSortingDirection } from '@laerdal/life-react-components/dist/Table/TableTypes';
import { EmptyPageBox } from '../../_commonComponents/EmptyPageBox';


const SearchWrapper = styled.div`
  margin:0 0 32px 0 ;
  width: 100%;
  ${BREAKPOINTS.LARGE}{
    width: 50%;
  }
`;

const FullWidth = styled.div`
  width: 100%;  
`;

const VerticalTabWrapper = styled.div`
  width: 350px;
`;

const Row = styled.div`
  width:100%;
  display: flex;
  gap: 32px;

  flex-direction: column;
  ${BREAKPOINTS.LARGE}{
    flex-direction: row;
  }
`;

const Wrapper = styled.div`
  overflow-x: auto;
  background: white;
`;

const TrackingLink = styled(HyperLink)`
  display:flex;
`;

const InitialPagination: TablePagination = {
  currentPage: 0,
  from: 0,
  rowsPerPage: 20,
  to: 0,
  total: 0,
};

const ShipmentsPage = () => {
  const { t, i18n } = useTranslation(['Shipment', 'Order', 'Invoices']);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchTermDebounced] = useDebouncedValue(searchTerm, 300);
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  const user = useSelector(selectUserProfile);

  const openShipmentDetails = (row: ShipmentListItemDto) => {
    //@ts-ignore
    posthog.capture?.('ShipmentsPage ShipmentDetails',{
      shipmentNumber: row.shipmentNumber
    });
    navigate(`/ordersinvoices/shipments/details/${row.shipmentNumber}`);
  };


  const verticalItems = [
    { requiredLine: t('Orders'), to: '/ordersinvoices/orders' },
    { requiredLine: t('Shipments'), to: '/ordersinvoices/shipments' },
    { requiredLine: t('Invoices'), to: '/ordersinvoices/invoices' },
  ];

  const horizontalOptions = [
    { key: '/ordersinvoices/orders', content: t('Orders'), disabled: false },
    { key: '/ordersinvoices/shipments', content: t('Shipments'), disabled: false },
    { key: '/ordersinvoices/invoices', content: t('Invoices'), disabled: false }
  ];


  const [shipments, setShipments] = useState<ShipmentListItemDto[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePagination>(InitialPagination);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasItems, setHasItems] = useState<boolean>(false);

  const [sortingDirection, setSortingDirection] = useState<TableSortingDirection | undefined>('desc');
  const [sortingKey, setSortingKey] = useState<string | undefined>('shippedDate');

  useEffect(() => {
    //@ts-ignore
    posthog.capture?.('ShipmentsPage Search',{
      term: searchTermDebounced,
    })
    setLoading(true);

    ShipmentsApi.GetShipments(pagination.rowsPerPage, 0, i18n.language, sortingKey, sortingDirection, searchTerm, undefined)
      .then((response) => {
        setShipments(response.items);
        setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
        setLoading(false);
        setInitialLoad(false);
        if (response.items.length > 0) setHasItems(true);
      })
      .finally(() => setLoading(false));

  }, [searchTermDebounced]);

  const onPreviousPage = () => {
    //@ts-ignore
    posthog.capture?.('ShipmentsPage PreviousPage');
    setLoading(true);
    ShipmentsApi.GetShipments(
      pagination.rowsPerPage,
      pagination.currentPage - 1,
      i18n.language,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetPrevious(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onNextPage = () => {
    //@ts-ignore
    posthog.capture?.('ShipmentsPage NextPage');
    setLoading(true);

    ShipmentsApi.GetShipments(
      pagination.rowsPerPage,
      pagination.currentPage + 1,
      i18n.language,
      sortingKey,
      sortingDirection,
      searchTerm,
      undefined
    ).then((response) => {
      setShipments(response.items);
      setPagination(PaginationHelper.GetNext(pagination.currentPage, pagination.rowsPerPage, response.totalCount));
      setLoading(false);
    });
  };

  const onRowsPerPageChange = (newRowsPerPage: number) => {
    setLoading(true);

    ShipmentsApi.GetShipments(newRowsPerPage, 0, i18n.language, sortingKey, sortingDirection, searchTerm, undefined)
      .then((response) => {
        setShipments(response.items);
        setPagination(PaginationHelper.GetInitial(newRowsPerPage, response.totalCount));
        setLoading(false);
      });
  };

  const onSortingChanged = (key: string, direction?: TableSortingDirection) => {
    setSortingDirection(direction);
    setSortingKey(key);
    setLoading(true);

    ShipmentsApi.GetShipments(pagination.rowsPerPage, 0, i18n.language, key, direction, searchTerm, undefined)
      .then((response) => {
        setShipments(response.items);
        setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
        setLoading(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>{t('Shipments')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        <ListHeader>
          <ListPreTitle color={COLORS.neutral_600}>{user!.currentOrganization!.name}</ListPreTitle>
          <ListTitle>{t('Orders & Invoices')}</ListTitle>
          <ListSubtitle>{t('Review order history, track shipments, and download invoices.')}</ListSubtitle>
        </ListHeader>

        <Row>
          {!isMediumScreen && <SegmentControl 
                                selected='/ordersinvoices/shipments' 
                                items={horizontalOptions} 
                                onChange={(to) => { 
                                  //@ts-ignore
                                  posthog.capture?.('ShipmentsPage Segment',{
                                    segment: to
                                  });
                                  navigate(to); 
                                }} 
                                size={Size.Small} />}
          {!!isMediumScreen && <VerticalTabWrapper><VerticalTabs entries={verticalItems} /></VerticalTabWrapper>}

          <FullWidth>

            {initialLoad &&
              <LoadingIndicator />
            }

            {!initialLoad && !hasItems &&
              <EmptyPageBox
                title={t('Your shipments')}
                description={t('Tracking information for orders that have been shipped will be displayed here.')}
                ilustrationPath='/assets/track_shipment.svg' />
            }

            {!initialLoad && hasItems &&
              <>
                <SearchWrapper>
                  <SearchBar
                    enterSearch={() => { }}
                    id="orderSearchField"
                    placeholder={t('Search by Tracking, Order or PO number')}
                    size={Size.Medium}
                    setSearchTerm={setSearchTerm}
                    removeSearch={() => setSearchTerm('')}
                  />
                </SearchWrapper>

                <Wrapper>
                  <Table
                    noRowsLabel={t('There are no rows to display', { 'ns': 'Common' })}
                    rowsPerPageLabel={t('Rows per page:', { 'ns': 'Common' })}
                    rows={shipments || []}
                    remoteOperations={true}
                    pagination={pagination}
                    onRowsPerPageChange={(newRowsPerPage: number) => onRowsPerPageChange(newRowsPerPage)}
                    onNextPageClick={() => onNextPage()}
                    onPreviousPageClick={() => onPreviousPage()}
                    showLoadingIndicator={loading}
                    onSelectionChange={(row: ShipmentListItemDto) => openShipmentDetails(row)}
                    selectable={true}
                    onTriggerSortingChange={(key: string, direction?: TableSortingDirection) => onSortingChanged(key, direction)}
                    columns={[
                      {
                        key: 'orderNumber',
                        name: t('Order number'),
                        sortable: false,
                        type: 'custom',
                        customContent: (row: ShipmentListItemDto, key: string) => !!row.qadOrderNumber ? row.qadOrderNumber : row.orderNumber,
                      },
                      {
                        key: 'poNumber',
                        name: t('PO number'),
                        sortable: true,
                      },
                      {
                        key: 'trackingStatusTranslated',
                        name: t('Tracking status'),
                        sortable: true,
                        type: 'custom',
                        customContent: (row: ShipmentListItemDto, key: string) => <ShipmentStatus status={row.trackingStatus} statusTranslated={row.trackingStatusTranslated} />
                      },
                      {
                        key: 'shippingProvider',
                        name: t('Shipping provider'),
                        sortable: true,
                      },
                      {
                        key: 'shippedDate',
                        name: t('Shipped date'),
                        sortable: true,
                        width: 230,
                        type: 'custom',
                        customContent: (row: ShipmentListItemDto, key: string) => !!row.shippedDate
                          ? moment(row.shippedDate).format('ll')
                          : '',
                      },
                      {
                        key: 'estimatedDeliveryDate',
                        name: t('Estimated delivery date'),
                        sortable: true,
                        width: 230,
                        type: 'custom',
                        customContent: (row: ShipmentListItemDto, key: string) => !!row.estimatedDeliveryDate
                          ? moment(row.estimatedDeliveryDate).format('ll')
                          : '',
                      },
                      {
                        key: 'trackingNumber',
                        name: t('Tracking number'),
                        sortable: true,
                        type: 'custom',
                        customContent: (row: ShipmentListItemDto, key: string) =>
                          !! row.trackingUrl?
                            <TrackingLink id="HyperLinkDefault" variant="default" href={row.trackingUrl ?? ""} >{row.trackingNumber}<SystemIcons.OpenNewWindow size='20' /></TrackingLink>
                            : row.trackingNumber
                      },
                    ]}
                  />
                </Wrapper>
              </>
            }

          </FullWidth>
        </Row>
      </StyledPageWidth>
    </>);
};

export default ShipmentsPage;