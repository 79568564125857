import { useNavigate, useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { AppointmentDetailsDto } from "../../model/dto/appointments/appointmentDetailsDto";
import { useContext, useEffect, useState } from "react";
import AppointmentsApi from "../../services/api/AppointmentsApi";
import { Helmet } from "react-helmet";
import {
  BREAKPOINTS,
  BackButton,
  Button,
  COLORS,
  ChipContainer,
  ComponentL,
  ComponentM,
  ComponentS,
  ComponentTextStyle,
  ContentIcons,
  FilterChip,
  HorizontalCard,
  ListRow,
  LoadingIndicator,
  ModalDialog,
  PageWidth,
  Size,
  SystemIcons,
  ToastContext
} from "@laerdal/life-react-components";
import styled from "styled-components";
import moment from 'moment-timezone';
import { StyledPageWidth } from "../_commonComponents/StyledComponents";
import Calendar from 'react-calendar'
import { LooseValue } from "react-calendar/dist/cjs/shared/types";
import 'react-calendar/dist/Calendar.css';
import '../_commonComponents/CalendarStyles.css';
import ProgressComponent from "../_commonComponents/ProgressComponent";
import { AppointmentBookingSlotDto } from "../../model/dto/appointments/appointmentBookingSlotDto";
import { CountriesSupportInfo, CountrySupportInfo } from "../../model/constants/CountrySupportInfo";
import { useSelector } from "react-redux";
import { selectGigyaAccount, selectUserProfile, setUserProfile } from "../../store/account/accountSlice";
import { useFeatures } from "../../hooks/Features";
import { FeatureNames } from "../../model/constants/FeatureConstants";
import { SuccessToastOptions, FailToastOptions } from "../../model/constants/ToastConstants";
import qs from 'query-string';
import UserApi from "../../services/api/UserApi";
import { useAppDispatch } from "../../store/store";

const Row = styled.div`
  width:100%;
  display: flex;
  gap: 8px;
`;

const Row32 = styled.div`
  height:32px;
  width:100%;
  display: flex;
  gap: 8px;
`;

const AppointmentInfoColumn = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
`;

const Timezone = styled.div`
  align-self: self-start;

  margin-top: 16px; 
  color: ${COLORS.neutral_500};
  font-feature-settings: 'liga' off;
  
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; 
`;

const TimeSlots = styled(ChipContainer)` 
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;
`

const FirstColumn = styled.div`display: flex;
  min-width: 280px;
  max-width: 360px;
  padding: 32px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px 0 0 8px;
  border-right: 1px solid ${COLORS.neutral_200};
  background: ${COLORS.primary_20};
`
const SecondColumn = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  flex-grow: 1;
`

const Notes = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const NotesText = styled.div`
  color: ${COLORS.neutral_600};
  font-feature-settings: 'liga' off;

  font-family: Lato;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 22.5px */
`


const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`
const HeaderDetails = styled.div`
  display: flex;
  padding: 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const DateTimeContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
`

const CalendarContainer = styled.div`
  display: flex;
  min-width: 336px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`
const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
`

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`

const StepText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`

const SlotsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
`

const NoDateSelected = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: ${COLORS.neutral_20};
`

const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`

const InfoButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const FormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
`

const DesktopH3 = styled.div`
  color: #000;

  font-feature-settings: 'liga' off;
  font-family: Lato;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; 
`

const DesktopParagraph = styled.div`
  color: var(--base-black, #1F1F1F);
  font-feature-settings: 'liga' off;

  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 30px */
`
const DesktopH5 = styled.div`
  color: #000;

  font-feature-settings: 'liga' off;
  /* L - Desktop/H5 */
  font-family: Lato;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 36px */
`

const SchedulingFrame = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid ${COLORS.neutral_200};
  background-color: ${COLORS.white};
`;

export const Title = styled.h3`
  margin: 0;

  ${BREAKPOINTS.LARGE}{
    display: flex;
  }
`;


const AppointmentBookingPage = () => {
  // Route values
  const { appointmentNumber, s } = useParams<{ appointmentNumber: string, s?: string }>();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const source = urlParams.get('source');

  // Services
  const { t, i18n } = useTranslation('Appointments');

  
  const dispatch = useAppDispatch();
  const user = useSelector(selectUserProfile);
  
  // Store
  const [loading, setLoading] = useState<boolean>(true);
  
  const [loadingSlots, setLoadingSlots] = useState<boolean>(false);
  const [requestingSlot, setRequestingSlot] = useState<boolean>(false);
  const [calendarValue, setCalendarValue] = useState<LooseValue | undefined>(undefined);
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [slots, setSlots] = useState<AppointmentBookingSlotDto[] | undefined>(undefined);
  const [selectedSlotDateTime, setSelectedSlotDateTime] = useState<string>('');
  const [contactModalOpen, setContactModalOpen] = useState<boolean>(false);
  const [organizationChangeModalOpen, setOrganizationChangeModalOpen] = useState<boolean>(false);  
  const [supportInfo, setSupportInfo] = useState<CountrySupportInfo | undefined>(undefined);
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentDetailsDto | undefined>(undefined);
  
  const { addToast } = useContext(ToastContext);
  
  const account = useSelector(selectGigyaAccount);
  
  function isSelfSchedulingStatus(appointmentDetails: AppointmentDetailsDto): boolean {
    return appointmentDetails.appointmentDetails.sfStatus == 'Ready to Self-Schedule'
      || appointmentDetails.appointmentDetails.status == 'Requested'
      || appointmentDetails.appointmentDetails.status == 'Scheduled'
      || appointmentDetails.appointmentDetails.status == 'Needs Reschedule';
  }
  
  // Hooks
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user) {
      CountriesSupportInfo.forEach(x => {
        if (x.country.toLocaleLowerCase().trim() == user.currentOrganization?.address?.country?.name?.toLocaleLowerCase().trim()) {
          setSupportInfo(x);
        }
      });
    }
  }, [user]);

  
  useEffect(() => {
    if (!!appointmentDetails && !appointmentDetails.userChangedOrg) {
      // If can not be self scheduled
      // If Work type is not enabled to be self scheduled
      // If status is not from the self schedule workflow
      if(
        (appointmentDetails.workOrderDetails.canBeScheduled == false
          || isSelfSchedulingStatus(appointmentDetails) == false)
        ){
          //@ts-ignore
          posthog.capture?.('AppointmentBookingPage CantScheduleNavigateToDetails',{
            service_appointment: appointmentNumber,
            can_be_scheduled:appointmentDetails.workOrderDetails.canBeScheduled,
            is_self_scheduling_status: isSelfSchedulingStatus(appointmentDetails)
          });
          navigate(`/appointments/details/${appointmentNumber}`)
        }
    }

    if(!!appointmentDetails){  
      //@ts-ignore
      posthog.capture?.('AppointmentBookingPage Opened',{
        source: source ?? "web",
        status: appointmentDetails.appointmentDetails.status
      });
    }
  }, [appointmentDetails]);


  useEffect(() => {
    setLoading(true);

    AppointmentsApi.GetAppointmentDetails(appointmentNumber!, i18n.language, s).then((response) => {
      setAppointmentDetails(response);
      if(response.userChangedOrg)
      {        
        //@ts-ignore
        posthog.capture?.('AppointmentBookingPage UserChangedOrg',{
          service_appointment: appointmentNumber
        });

        dispatch(setUserProfile(undefined));

        UserApi.getUser().then((user) => {
          dispatch(setUserProfile(user));
          setOrganizationChangeModalOpen(true);
        });
      }
      else if(user?.currentOrganization == null)
      {        
        dispatch(setUserProfile(undefined));

        UserApi.getUser().then((user) => {
          dispatch(setUserProfile(user));
        });
      }
      
      let limitFromCurrentDate = new Date();
      
      if(limitFromCurrentDate.getDay() == 6) limitFromCurrentDate.setDate(limitFromCurrentDate.getDate() + 5);
      else if(limitFromCurrentDate.getDay() >= 3) limitFromCurrentDate.setDate(limitFromCurrentDate.getDate() + 6);
      else limitFromCurrentDate.setDate(limitFromCurrentDate.getDate() + 4);

      setMinDate(limitFromCurrentDate);

      let maxLimitFromCreateDate = new Date(response.appointmentDetails.createdDate);
      maxLimitFromCreateDate.setFullYear(maxLimitFromCreateDate.getFullYear() + 1);
      setMaxDate(maxLimitFromCreateDate);

      if(isSelfSchedulingStatus(response) 
        && response?.workOrderDetails?.canBeScheduled) 
        setLoading(false);      
    });
  }, []);


  const dateSelected = (selectedDate: Date) => {
    if(loadingSlots) return;

    //@ts-ignore
    posthog.capture?.('AppointmentBookingPage DateSelected',{
      service_appointment: appointmentNumber,
      selected_date: selectedDate
    });

    setSlots(undefined);
    setCalendarValue(selectedDate);
    setLoadingSlots(true);

    AppointmentsApi.GetAppointmentSlots(appointmentNumber!, selectedDate.toISOString()).then( response => {
      setSlots(response.sort((a,b) => {
        let dateA = new Date(a.interval.start);
        let dateB = new Date(b.interval.start);

        return +dateA - +dateB;
      } ))
    }).finally(() =>{
      setLoadingSlots(false);
    });
  };

  const requestSlot = () => { 
    setRequestingSlot(true);

    AppointmentsApi.BookAppointmentSlot(appointmentNumber!, selectedSlotDateTime).then( response => {
      //@ts-ignore
      posthog.capture?.('AppointmentBookingPage Requested',{
        service_appointment: appointmentNumber,
        selected_date: selectedSlotDateTime,
        status: appointmentDetails?.appointmentDetails?.status,
        source: source ?? "web"
      });
      addToast(t('Request sent'), SuccessToastOptions)
      navigate(`/appointments/details/${appointmentNumber}`)
    }).catch(()=>{
      //@ts-ignore
      posthog.capture?.('AppointmentBookingPage RequestError',{
        service_appointment: appointmentNumber,
        selected_date: selectedSlotDateTime,
        status: appointmentDetails?.appointmentDetails?.status
      });
      addToast(t('Something went wrong'), FailToastOptions);
    })
    .finally(() =>{
      setRequestingSlot(false);
    });
  };
  return (
    <>
      <ModalDialog
        key="contacUsModal"
        closeModalAndClearInput={() => { 
          //@ts-ignore
          posthog.capture?.('AppointmentBookingPage ContactModalClosed',{
            service_appointment: appointmentNumber,
            selected_date: selectedSlotDateTime,
            status: appointmentDetails?.appointmentDetails?.status
          });
          setContactModalOpen(false) 
        }}
        title={'Contact us'}
        size={Size.Large}
        isModalOpen={contactModalOpen}
        closeAction={() => { 
          //@ts-ignore
          posthog.capture?.('AppointmentBookingPage ContactModalClosed',{
            service_appointment: appointmentNumber,
            selected_date: selectedSlotDateTime,
            status: appointmentDetails?.appointmentDetails?.status
          });
          setContactModalOpen(false) 
        }}
        submitAction={() => { }}
        buttons={[
          {
            action: () => { 
              //@ts-ignore
              posthog.capture?.('AppointmentBookingPage ContactModalClosed',{
                service_appointment: appointmentNumber,
                selected_date: selectedSlotDateTime,
                status: appointmentDetails?.appointmentDetails?.status
              });
              setContactModalOpen(false) 
            },
            text: 'Okay',
          },
        ]}
      >
        <>          
          <Row32>
            <ComponentL>{t('Service Appointment Number')}:</ComponentL>
            <ComponentL>{appointmentNumber}</ComponentL>
          </Row32>
          <Row32>
            <ComponentL>{t('Phone')}:</ComponentL>
            <ComponentL textStyle={ComponentTextStyle.Bold} color={COLORS.primary_600}>{supportInfo?.phone}</ComponentL>
          </Row32>
          <Row32>
            <ComponentL>{t('Email')}:</ComponentL>
            <ComponentL textStyle={ComponentTextStyle.Bold} color={COLORS.primary_600}>{supportInfo?.email}</ComponentL>
          </Row32>
        </>
      </ModalDialog>
      
 
      <ModalDialog
        key="organizationChangeModal"
        closeModalAndClearInput={() => { setOrganizationChangeModalOpen(false) }}
        title={'Your organization has changed'}
        size={Size.Large}
        isModalOpen={organizationChangeModalOpen}
        closeAction={() => { setOrganizationChangeModalOpen(false) }}
        submitAction={() => { }}
        buttons={[
          {
            action: () => { setOrganizationChangeModalOpen(false) },
            text: 'Okay',
          },
        ]}
      >
        <>          
          <Row32>
            <ComponentL>{t('To schedule this appointment we have changed your organization to')}:</ComponentL>
          </Row32>
          <Row32>
            <ComponentL textStyle={ComponentTextStyle.Bold}>
              {user?.currentOrganization?.name}
            </ComponentL>
          </Row32>
        </>
      </ModalDialog>

      <Helmet>
        <title>{t('Appointment details page')}</title>
      </Helmet>
      <StyledPageWidth useMaxWidth={true} maxWidth={1600}>
        {loading && <LoadingIndicator />}
        {!loading && appointmentDetails &&
          <>
            <SchedulingFrame>
              <Row>
                <FirstColumn>
                  <Header>
                    <BackButton size={Size.Small} onClick={() => navigate(`/appointments/details/${appointmentNumber}`)}>{t('Appointment details')}</BackButton>
                    <DesktopH3>{appointmentDetails.appointmentDetails.workType}</DesktopH3>
                    
                    <HeaderDetails>
                      { !!appointmentDetails.appointmentDetails.scheduledStart && 
                      <Row>
                        <ContentIcons.Calendar size="40" />
                        <AppointmentInfoColumn>
                          <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Date')}</ComponentS>
                          <ComponentL>
                            { `${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleDateString()} ${new Date(appointmentDetails.appointmentDetails.scheduledStart).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})} (${moment.tz(moment.tz.guess()).format('z')})`}
                          </ComponentL>
                        </AppointmentInfoColumn>
                      </Row>  }

                      <Row>
                        <ContentIcons.Stopwatch size="40" />
                        <AppointmentInfoColumn>
                          <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Duration')}</ComponentS>
                          <ComponentL> {appointmentDetails.appointmentDetails.duration} {appointmentDetails.appointmentDetails.durationType} </ComponentL>
                        </AppointmentInfoColumn>
                      </Row>

                      {!!appointmentDetails.workOrderDetails.deliveryType &&
                        <Row>
                          <ContentIcons.ESimulation size="40" />
                          <AppointmentInfoColumn>
                            <ComponentS textStyle={ComponentTextStyle.Bold}>{t('Type')}</ComponentS>
                            <ComponentL>{appointmentDetails.workOrderDetails.deliveryType}</ComponentL>
                          </AppointmentInfoColumn>
                        </Row>
                      }
                    </HeaderDetails>
                  </Header>
                  <Notes>
                      <NotesText>
                        {t('After submitting your preferred time, our service team will review your request before confirming the appointment.')}
                      </NotesText>
                  </Notes>
                </FirstColumn>
                  <SecondColumn>
                    <Header>                    
                      
                        <DesktopH5>
                          {appointmentDetails.appointmentDetails.sfStatus == 'Ready to Self-Schedule' && t('Schedule appointment')}
                          {appointmentDetails.appointmentDetails.status == 'Requested' && t('Update appointment request')}
                          {appointmentDetails.appointmentDetails.status == 'Scheduled' && t('Update appointment request')}
                          {appointmentDetails.appointmentDetails.status == 'Needs Reschedule' && t('Update appointment request')}
                        </DesktopH5>
                        <DesktopParagraph>
                          {appointmentDetails.appointmentDetails.sfStatus == 'Ready to Self-Schedule' && t('Send a request for when you would like this appointment to take place.')}
                          {appointmentDetails.appointmentDetails.status == 'Requested' && t('Make changes to your previously submitted appointment request.')}
                          {appointmentDetails.appointmentDetails.status == 'Scheduled' && t('Make changes to your previously submitted appointment request.')}
                          {appointmentDetails.appointmentDetails.status == 'Needs Reschedule' && t('Make changes to your previously submitted appointment request.')}
                        </DesktopParagraph>
                    </Header>
                    <DateTimeContainer>
                        <CalendarContainer>
                          <Step>
                            <ComponentL textStyle={ComponentTextStyle.Bold}>{t('1')}</ComponentL>
                            <StepText>
                              <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Select date')}</ComponentL>
                                {!!calendarValue &&
                                  <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_600}>
                                    {(calendarValue as Date).toLocaleDateString()}
                                  </ComponentS>
                                }
                            </StepText>
                          </Step>
                          <Calendar
                            className={loadingSlots || requestingSlot ? 'react-calendar-disabled' : ''}
                            defaultActiveStartDate={minDate}
                            locale={i18n.language}
                            value={calendarValue}
                            minDate={minDate}
                            maxDate={maxDate}
                            view="month"
                            tileDisabled={({ date }) => [0, 6].includes(date.getDay())}
                            //@ts-ignore
                            onChange={dateSelected}
                          />
                        </CalendarContainer>
                        <TimeContainer>
                          <Step>
                            <ComponentL textStyle={ComponentTextStyle.Bold}>{t('2')}</ComponentL>
                            <StepText>
                              <ComponentL textStyle={ComponentTextStyle.Bold}>{t('Choose a time')}</ComponentL>
                                {!!selectedSlotDateTime &&
                                  <ComponentS textStyle={ComponentTextStyle.Bold} color={COLORS.neutral_600}>
                                    {`${new Date(selectedSlotDateTime).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})}`} 
                                  </ComponentS>
                                }
                            </StepText>
                          </Step>
                          <SlotsContainer>
                            { !slots &&
                              <NoDateSelected>
                                  { !loadingSlots &&
                                    <ComponentL textStyle={ComponentTextStyle.Italic} color={COLORS.neutral_300}>
                                      {t('Select a date')}
                                    </ComponentL>
                                  }
                                  { loadingSlots &&
                                    <LoadingIndicator></LoadingIndicator>
                                  }
                              </NoDateSelected>
                            }
                            { !!slots && slots.length > 0 &&
                              <>
                                <TimeSlots>
                                  {slots.map((slot, i) => 
                                    <FilterChip 
                                      text={ `${new Date(slot.interval.start).toLocaleTimeString([],  { hour: 'numeric', minute: 'numeric'})}`} 
                                      size={Size.Large} 
                                      onClick={() => {
                                          setSelectedSlotDateTime(slot.interval.start);
                                        }} 
                                      selected={slot.interval.start == selectedSlotDateTime} />
                                  )}
                                </TimeSlots>
                                <Timezone>
                                  {t('Timezone')}: <strong>{moment.tz(moment.tz.guess()).format('z')}</strong> 
                                </Timezone>
                              </>
                            }
                            
                            { !!slots && slots.length == 0 &&
                              <ComponentL textStyle={ComponentTextStyle.Italic} color={COLORS.neutral_400}>
                                {t('No times are available for selected date. Please try another date.')}
                              </ComponentL>
                            }
                          </SlotsContainer>
                        </TimeContainer>
                    </DateTimeContainer>

                    <ActionButtons>
                      <InfoButtons>
                        <Button 
                          variant="tertiary" 
                          icon={<SystemIcons.Help />} 
                          size={Size.Small} 
                          onClick={() => {
                            //@ts-ignore
                            posthog.capture?.('AppointmentBookingPage ContactModalOpened',{
                              service_appointment: appointmentNumber,
                              selected_date: selectedSlotDateTime
                            });
                            setContactModalOpen(true)}
                          }>
                          {t('Need help?')}
                        </Button>
                      </InfoButtons>
                      <FormButtons>
                        <Button disabled={!selectedSlotDateTime} loading={requestingSlot} onClick={() => requestSlot()}>
                          {appointmentDetails.appointmentDetails.sfStatus == 'Ready to Self-Schedule' && t('Send request')}
                          {appointmentDetails.appointmentDetails.status == 'Requested' && t('Update request')}
                          {appointmentDetails.appointmentDetails.status == 'Scheduled' && t('Update request')}
                          {appointmentDetails.appointmentDetails.status == 'Needs Reschedule' && t('Update request')}
                        </Button>
                      </FormButtons>
                    </ActionButtons>
                  </SecondColumn>
                
              </Row>
             

            </SchedulingFrame>

          </>
        }
      </StyledPageWidth>
    </>
  )
}
export default AppointmentBookingPage;
