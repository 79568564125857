import React, {useContext, useEffect, useState} from 'react';
import {IdPConfigDto} from '../../model/dto/idP/IdPConfig';
import {useController, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from 'react-i18next';
import SAMLApi from '../../services/api/SAMLApi';
import styled from 'styled-components';
import {
  BasicDropdown,
  COLORS, ContentAccordion,
  InputLabel,
  LoadingIndicator,
  Size,
  States,
  SystemIcons,
  Textarea,
  TextField,
  Tile, ToastContext,
  Z_INDEXES,
} from '@laerdal/life-react-components';
import ImportFromUrlModal from './components/ImportFromUrlModal';
import ImportFromFileModal from './components/ImportFromFileModal';
import {FailToastOptions, SuccessToastOptions} from '../../model/constants/ToastConstants';
import Accordion from './components/Accordion';
import AttributesInput from './components/AttributesInput';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1024px;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const FieldRow = styled.div`
  display: flex;
  gap: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEXES.backdrop};
`;

const schema = yup.object().shape({
  entityId: yup.string().required('Field is required'),
  singleSignOnServiceUrl: yup.string().required('Field is required'),
  singleSignOnServiceBinding: yup.string().required('Field is required'),
  singleLogoutServiceUrl: yup.string().nullable(),
  singleLogoutServiceBinding: yup.string().when('singleLogoutServiceUrl', ([url]) => !!url 
    ? yup.string().required('Field is required') 
    : yup.string().nullable()),
  nameIDFormat: yup.string().nullable(),
  attributeMap: yup.object().nullable(),
  certificate: yup.string().nullable(),
  spSigningAlgorithm: yup.string().when('certificate',
    ([certificate]) => !!certificate
      ? yup.string().required('Field is required')
      : yup.string().nullable()),
});

const IdPPage = () => {

  const {t} = useTranslation('SAML');
  const {addToast} = useContext(ToastContext);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [idpConfig, setIdpConfig] = useState<IdPConfigDto>();
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [urlModalOpen, setUrlModalOpen] = useState(false);

  const {handleSubmit, control, reset} = useForm<IdPConfigDto>({
    defaultValues: {
      attributeMap: {},
      singleSignOnServiceBinding: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
      singleLogoutServiceBinding: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
      spSigningAlgorithm: 'SHA1',
      certificate: '',
      entityId: '',
      nameIDFormat: '',
      singleLogoutServiceUrl: '',
      singleSignOnServiceUrl: '',
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  });

  const entityIdField = useController({control, name: 'entityId'});
  const singleSignOnServiceUrlField = useController({control, name: 'singleSignOnServiceUrl'});
  const singleSignOnServiceBindingField = useController({control, name: 'singleSignOnServiceBinding'});
  const singleLogoutServiceUrlField = useController({control, name: 'singleLogoutServiceUrl'});
  const singleLogoutServiceBindingField = useController({control, name: 'singleLogoutServiceBinding'});
  const nameIDFormatField = useController({control, name: 'nameIDFormat'});
  const certificateField = useController({control, name: 'certificate'});
  const spSigningAlgorithmField = useController({control, name: 'spSigningAlgorithm'});
  const attributeMapField = useController({control, name: 'attributeMap'});

  useEffect(() => {
    SAMLApi.GetIdP().then(setIdpConfig).finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    reset(idpConfig);
  }, [idpConfig]);

  const submit = (data: IdPConfigDto) => {
    setSubmitting(true);
    SAMLApi.RegisterIdP(data)
      .then(() => {
        reset(data);
        setIdpConfig(data);
        addToast(t('Operation successful'), SuccessToastOptions);
      })
      .catch(() => addToast(
        t('Something went wrong please contact support to help you with the configuration'),
        FailToastOptions,
      ))
      .finally(() => setSubmitting(false));
  };

  const onImport = (type: string) => {
    switch (type) {
      case 'file':
        setFileModalOpen(true);
        break;
      case 'url':
        setUrlModalOpen(true);
        break;
    }
  };

  return (
    <Wrapper>
      {
        loading &&
        <LoadingContainer>
          <LoadingIndicator color={COLORS.white}/>
        </LoadingContainer>
      }
      <Tile size={Size.Large}
            className={'idp-form'}
            header={{
              title: t('Identity provider settings'),
              buttons: [
                {
                  componentType: 'dropdown',
                  icon: <SystemIcons.CloudUpload/>,
                  onClick: (value) => onImport(value[0]),
                  items: [
                    {
                      value: 'file',
                      icon: <SystemIcons.Document/>,
                      displayLabel: t('Import from file'),
                    },
                    {
                      value: 'url',
                      icon: <SystemIcons.Link/>,
                      displayLabel: t('Import from url'),
                    },
                  ],
                },
              ],
            }}
            footer={{
              buttons: [
                {
                  componentType: 'button',
                  variant: 'tertiary',
                  buttonText: t('Reset'),
                  disabled: submitting,
                  onClick: () => reset(idpConfig),
                },
                {
                  componentType: 'button',
                  variant: 'primary',
                  buttonText: t('Save'),
                  loading: submitting,
                  onClick: handleSubmit(submit),
                },
              ],
            }}>
        <FormContainer onSubmit={handleSubmit(submit)}>
          <FieldContainer>
            <InputLabel inputId={'entityId'} text={'Issuer'}/>
            <TextField id={'entityId'}
                       placeholder={t('The IdP entity ID')}
                       required={true}
                       validationMessage={entityIdField.fieldState.error?.message}
                       state={entityIdField.fieldState.error?.message ? States.Invalid : undefined}
                       {...entityIdField.field}/>
          </FieldContainer>

          <FieldRow>
            <FieldContainer>
              <InputLabel inputId={'singleSignOnServiceUrl'} text={'Single-Sign-On Service URL'}/>
              <TextField id={'singleSignOnServiceUrl'}
                         required={true}
                         placeholder={t('The IdP SSO Url')}
                         validationMessage={singleSignOnServiceUrlField.fieldState.error?.message}
                         state={singleSignOnServiceUrlField.fieldState.error?.message ? States.Invalid : undefined}
                         {...singleSignOnServiceUrlField.field}/>
            </FieldContainer>
            <FieldContainer>
              <InputLabel inputId={'singleSignOnServiceBinding'} text={'Single-Sign-On Service Binding'}/>
              <BasicDropdown id={'singleSignOnServiceBinding'}
                             
                             placeholder={t('The IdP SSO Url')}
                             list={[
                               {
                                 value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
                                 displayLabel: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
                               },
                               {
                                 value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
                                 displayLabel: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
                               },
                             ]}
                             activeValidationMessage={singleSignOnServiceBindingField.fieldState.error?.message}

                             value={singleSignOnServiceBindingField.field.value}
                             onSelect={singleSignOnServiceBindingField.field.onChange}
                             ref={singleSignOnServiceBindingField.field.ref}
                             onBlur={singleSignOnServiceBindingField.field.onBlur}/>
            </FieldContainer>
          </FieldRow>

          <FieldRow>
            <FieldContainer>
              <InputLabel inputId={'singleLogoutServiceUrl'} text={'Single Logout Service URL'}/>
              <TextField id={'singleLogoutServiceUrl'}
                         placeholder={t('The IdP SSO Url')}
                         validationMessage={singleLogoutServiceUrlField.fieldState.error?.message}
                         state={singleLogoutServiceUrlField.fieldState.error?.message ? States.Invalid : undefined}
                         {...singleLogoutServiceUrlField.field}/>
            </FieldContainer>
            <FieldContainer>
              <InputLabel inputId={'singleLogoutServiceBinding'} text={'Single Logout Service Binding'}/>
              <BasicDropdown id={'singleLogoutServiceBinding'}
                             placeholder={t('The IdP SSO Url')}
                             list={[
                               {
                                 value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
                                 displayLabel: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-Redirect',
                               },
                               {
                                 value: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
                                 displayLabel: 'urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST',
                               },
                             ]}
                             activeValidationMessage={singleLogoutServiceBindingField.fieldState.error?.message}
                             value={singleLogoutServiceBindingField.field.value}
                             onSelect={singleLogoutServiceBindingField.field.onChange}
                             ref={singleLogoutServiceBindingField.field.ref}
                             onBlur={singleLogoutServiceBindingField.field.onBlur}/>
            </FieldContainer>
          </FieldRow>

          <AttributesInput attributes={attributeMapField.field.value as any}
                           onChange={attributeMapField.field.onChange}/>


          <Accordion title={t('Additional settings')}
                     open={!!nameIDFormatField.fieldState.error ||
                       !!certificateField.fieldState.error ||
                       !!spSigningAlgorithmField.fieldState.error}>
            <FieldContainer>
              <InputLabel inputId={'nameIDFormat'} text={'Name ID Format'}/>
              <TextField id={'nameIDFormat'}
                         validationMessage={nameIDFormatField.fieldState.error?.message}
                         state={nameIDFormatField.fieldState.error?.message ? States.Invalid : undefined}
                         {...nameIDFormatField.field}/>
            </FieldContainer>
            <FieldRow>
              <FieldContainer style={{flex: 3}}>
                <InputLabel inputId={'certificate'} text={'IdP x509 Certificate'}/>
                <Textarea id={'certificate'}
                          placeholder={''}
                          size={Size.Medium}
                          required={false}
                          style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                          rows={10}
                          validationMessage={certificateField.fieldState.error?.message}
                          state={certificateField.fieldState.error?.message ? States.Invalid : undefined}
                          {...certificateField.field}/>
              </FieldContainer>

              <FieldContainer style={{flex: 1}}>
                <InputLabel inputId={'spSigningAlgorithm'} text={'IdP x509 Certificate'}/>
                <BasicDropdown id={'spSigningAlgorithm'}
                               list={[
                                 {value: 'SHA1', displayLabel: 'SHA1'}, {value: 'SHA256', displayLabel: 'SHA256'},
                               ]}
                               activeValidationMessage={spSigningAlgorithmField.fieldState.error?.message}
                               value={spSigningAlgorithmField.field.value}
                               onSelect={spSigningAlgorithmField.field.onChange}
                               ref={spSigningAlgorithmField.field.ref}
                               onBlur={spSigningAlgorithmField.field.onBlur}/>
              </FieldContainer>
            </FieldRow>
          </Accordion>

        </FormContainer>
      </Tile>

      <ImportFromUrlModal isModalOpen={urlModalOpen}
                          onClose={() => setUrlModalOpen(false)}
                          onImport={reset}/>
      <ImportFromFileModal isModalOpen={fileModalOpen}
                           onClose={() => setFileModalOpen(false)}
                           onImport={reset}/>

    </Wrapper>
  );
};

export default IdPPage;