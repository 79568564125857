import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
  BREAKPOINTS,
  COLORS, ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  ModalDialog,
  RadioButton,
  Size,
  ToastContext,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {Service} from '../../../model/dto/userProfile/service';
import {OrganizationService} from '../../../model/dto/organization/organizationService';
import {OrganizationServiceMember} from '../../../model/dto/organization/organizationServiceMember';
import {ServiceRole} from '../../../model/dto/userProfile/serviceRole';
import OrganizationApi from '../../../services/api/OrganizationApi';
import {ServiceMemberItemMember} from '../types';
import {FailToastOptions, SuccessToastOptions} from '../../../model/constants/ToastConstants';
import {useMediaMatch} from 'rooks';

const Header = styled.div`

  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  margin-bottom: 8px;
  
  ${BREAKPOINTS.LARGE}{
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
    margin-bottom: 16px;
  }
`;

const RoleList = styled.div`
  display: flex;
  flex-direction: column;
`;


interface Props {
  onClose: () => void;
  isOpen: boolean;
  service: Service | null | undefined;
  organizationServiceId: string | null | undefined;
  member: ServiceMemberItemMember | undefined;
  handleUserEdit: (member: OrganizationServiceMember, role: ServiceRole) => void;
}

const EditUserModal: React.FunctionComponent<Props> = ({
  onClose,
  isOpen,
  service,
  organizationServiceId,
  member,
  handleUserEdit,
}) => {
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const {t} = useTranslation('License');
  const {addToast} = useContext(ToastContext);
  const [busy, setBusy] = useState<boolean>(false);
  const [role, setRole] = useState<ServiceRole>();

  useEffect(() => isOpen ? setRole(member?.member?.role) : undefined, [isOpen]);

  const header = `${member?.member?.user.firstName} ${member?.member?.user?.lastName} (${member?.member?.user?.email})`;
  const roles = service?.serviceRoles ?? [];

  const close = () => {
    setBusy(false);
    setRole(undefined);
    onClose();
  };

  const submit = () => {
    setBusy(true);
    let promise = Promise.resolve(0);

    if (role?.id !== member?.member.role?.id) {
      const orgServiceId = organizationServiceId ?? '';
      const memberId = member?.member?.user?.id ?? '';

      promise = OrganizationApi.UpdateOrganizationServiceMember(orgServiceId, memberId, role!);
    }

    promise.then(() => {
      addToast(t('User access to {{serviceName}} changed', {serviceName: service?.name}), SuccessToastOptions);
      handleUserEdit(member!.member, role!);
    }).catch(() => {
      addToast(t('There was a problem editing the service member.'), FailToastOptions);
    }).finally(() => {
      close();
    });
  };

  return (
    <ModalDialog
      isModalOpen={isOpen}
      closeAction={close}
      closeModalAndClearInput={close}
      title={t('Edit access')}
      size={isMediumScreen ? Size.Medium : Size.Small}
      submitAction={e => e?.preventDefault()}
      buttons={[
        {
          id: 'cancel',
          text: t('Cancel'),
          variant: 'tertiary',
          disabled: busy,
          action: close,
        },
        {
          id: 'save',
          variant: 'primary',
          text: t('Save changes'),
          action: submit,
          loading: busy,
        },
      ]}>
      <Header>{header}</Header>
      <RoleList>
        {roles.map((value) => (
          <RadioButton key={value.id}
                       id={value.id}
                       label={value.name}
                       selected={role?.id === value.id}
                       select={() => setRole(value)}/>
        ))}
      </RoleList>
    </ModalDialog>
  );
};

export default EditUserModal;
