import axios from 'axios';
import { AddressDto } from '../../model/dto/address/addressDto';
import AuthApi from './AuthApi';
import { BaseApi } from './BaseApi';

class AddressApi extends BaseApi {
  Create  = async (a: AddressDto) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/Address/`, a, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  }
  Edit  = async (a: AddressDto) => {
    return axios.put(
      `${process.env.REACT_APP_API_URL}/Address/`, a, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  }
  Delete = async (id? : string): Promise<void> => {
    if(!id) return;

    return axios.delete(
      `${process.env.REACT_APP_API_URL}/Address/${id}`, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  }
  GetList = async (): Promise<AddressDto[]> => {
    return axios.get(
      `${process.env.REACT_APP_API_URL}/Address`,
      {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      },
    )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  }
  SetDefaultBilling = async (id?: string): Promise<void> => {
    if(!id) return;

    return axios.post(
      `${process.env.REACT_APP_API_URL}/Address/DefaultBilling/${id}`, null, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  };
  SetDefaultShipping = async (id?: string): Promise<void> => {
    if(!id) return;

    return axios.post(
      `${process.env.REACT_APP_API_URL}/Address/DefaultShipping/${id}`, null, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
      },
    })
    .then((response) => {
      if (response.status === 200) {
        return response.data;
      }
    });
  };
}

export default new AddressApi();