import React, {useState} from 'react';
import styled from 'styled-components';
import {COLORS, SegmentControl, Size} from '@laerdal/life-react-components';
import IdPPage from './IdPPage';
import SPPage from './SPPage';
import {useTranslation} from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  background-color: ${COLORS.neutral_20};
  padding: 32px;
  box-sizing: border-box;
`;

const TabsWrapper = styled.div`
  max-width: 1024px;
  width: 100%;
  background-color: ${COLORS.white};
`;

const SAMLPage = () => {
  const {t} = useTranslation('SAML');
  const [active, setActive] = useState('idp');

  return (
    <Wrapper>
      <TabsWrapper>
        <SegmentControl items={[{key: 'idp', content: t('Identity provider')}, {key: 'sp', content: t('Service provider')}]} 
                        selected={active}
                        onChange={setActive}/>
      </TabsWrapper>
      {
        active === 'idp' && <IdPPage/>
      }
      {
        active === 'sp' && <SPPage/>
      }
    </Wrapper>

  );
};

export default SAMLPage;