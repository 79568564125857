import axios from 'axios';
import AuthApi from './AuthApi';
import {BaseApi} from './BaseApi';
import {UserPermissionDetailsDto} from "../../model/dto/userDetails/UserPermissionDetailsDto";

export class PermissionsApi extends BaseApi {
  token = '';
  static instance = new PermissionsApi();

  constructor() {
    super();
    axios.defaults.headers.patch['Content-Type'] = 'application/json';
  }

  GetUserPermissionDetails = async (email: string): Promise<UserPermissionDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/permissions/user?email=${encodeURIComponent(email)}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
  };

  GetInvitationsPermissionDetails = async (email: string): Promise<UserPermissionDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/permissions/invitation?email=${encodeURIComponent(email)}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
  };

  UpdateUserPermissions = async (id: string, permissions: string[]): Promise<any> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/permissions/user/${id}`, {
        permissions: permissions,
      }, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
  }

  UpdateInvitationPermissions = async (code: string, permissions: string[]): Promise<any> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/permissions/invitation/${code}`, {
        permissions: permissions,
      }, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      })
  }
}

export default PermissionsApi.instance;
