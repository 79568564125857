import React, {useEffect, useMemo} from 'react';
import {useParams} from "react-router";
import {useLicenseInformation} from "./helpers/LicenseInformationHook";
import {
  selectIsSalesforceContact,
  selectIsSubscriptionOwner,
  selectSubscription,
  selectSubscriptionAvailableTerms,
  selectSubscriptionOrganizationService,
  selectSubscriptionService
} from "../../store/subscription/subscriptionSlice";
import {useSelector} from "react-redux";
import {Outlet, useMatch, useNavigate} from "react-router-dom";
import {
  BackButton, Breadcrumb, BreadcrumbItem,
  BREAKPOINTS,
  COLORS,
  ComponentSStyling,
  ComponentTextStyle, DropdownItem, HyperLink,
  LoadingIndicator,
  Note,
  Size, SystemIcons
} from "@laerdal/life-react-components";
import styled from "styled-components";
import {useMediaMatch} from "rooks";
import {useTranslation} from "react-i18next";
import {Simulate} from "react-dom/test-utils";
import {useLicenseAmendment} from "./helpers/LicenseAmendmentHook";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;

  ${BREAKPOINTS.MEDIUM} {
    flex-direction: row;
    gap: 0;
  }

  &.loading {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  a:visited {
    color: ${COLORS.primary_600} !important;
  }
`;

const Aside = styled.aside`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 16px;
  padding: 16px;

  ${BREAKPOINTS.MEDIUM} {
    flex: 50%;
    justify-content: space-between;
    padding: 32px;
  }

  ${BREAKPOINTS.LARGE} {
    flex: 37.5%;
    padding: 64px;
    width: 480px;
  }
`;

const AsideHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const AsideFooter = styled.div`
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  border-radius: 4px;
  border: 1px solid ${COLORS.neutral_200};

  div {
    ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.neutral_600)}
  }

  a {
    display: flex;
    align-items: center;
    ${ComponentSStyling(ComponentTextStyle.Bold, null)}
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: ${COLORS.white};

  ${BREAKPOINTS.MEDIUM} {
    flex: 50%;
    padding: 32px;
    gap: 24px;
  }

  ${BREAKPOINTS.LARGE} {
    flex: 62.5%;
    padding: 64px;
    gap: 32px;
  }
`;


export const LicenseAmendmentPage = () => {
  const {t} = useTranslation('License');

  const {id} = useParams<{ id: string }>();

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const plan = useMatch('/licenses/:id/edit/options');
  const confirmPlan = useMatch('/licenses/:id/edit/options/confirm');
  const renew = useMatch('/licenses/:id/edit/renew');
  const confirmRenew = useMatch('/licenses/:id/edit/renew/confirm');
  const cancel = useMatch('/licenses/:id/edit/cancel');
  const payment = useMatch('/licenses/:id/edit/payment');
  const navigate = useNavigate();

  const {loading} = useLicenseInformation(id!);

  useLicenseAmendment();

  const isOwner = useSelector(selectIsSubscriptionOwner);
  const service = useSelector(selectSubscriptionService);
  const orgService = useSelector(selectSubscriptionOrganizationService);

  useEffect(() => {
    !loading && !isOwner && navigate('/forbidden');
  }, [isOwner, loading]);

  const breadcrumb = useMemo(() => {
      if (!!plan) {
        return [{
          label: t('Plans'),
          url: `/licenses/${orgService?.id}/edit/options`
        }]
      }
      if (!!confirmPlan) {
        return [{
          label: t('Plans'),
          url: `/licenses/${orgService?.id}/edit/options`
        }, {
          label: t('Confirm'),
          url: `/licenses/${orgService?.id}/edit/options/confirm`
        }];
      }

      if (!!cancel) {
        return [{
          label: t('Cancel'),
          url: `/licenses/${orgService?.id}/edit/cancel`
        }]
      }

      if (!!renew) {
        return [{
          label: t('Plans'),
          url: `/licenses/${orgService?.id}/edit/renew`
        }]
      }

      if (!!confirmRenew) {
        return [{
          label: t('Plans'),
          url: `/licenses/${orgService?.id}/edit/renew`
        }, {
          label: t('Confirm'),
          url: `/licenses/${orgService?.id}/edit/renew/confirm`
        }];
      }

      if (!!payment) {
        return [{
          label: t('Payment method'),
          url: `/licenses/${orgService?.id}/edit/payment`
        }]
      }


    },
    [plan, confirmPlan, cancel, renew, confirmRenew, orgService])


  return (
    <Wrapper className={loading ? 'loading' : ''}>
      {
        loading && <LoadingIndicator/>
      }
      {
        !loading &&
        <>
          <Aside>
            <AsideHeader>
              <BackButton size={isMediumScreen ? Size.Small : Size.XSmall}
                          onClick={() => navigate(`/licenses/${orgService!.id}`)}>
                {t('Back')}
              </BackButton>
              <Note>{t('Your subscription')}</Note>
              <h3>{service!.name}</h3>
            </AsideHeader>
            <AsideFooter>
              <div>
                {t('Questions about subscriptions?')}
              </div>
              <HyperLink variant={'default'} target={'_self'} href={'/cases?showCreateModal=true'}>
                <span>{t('Contact support')}</span>
                <SystemIcons.ArrowLineRight size={'20px'}/>
              </HyperLink>
            </AsideFooter>
          </Aside>
          <ContentContainer>
            <Breadcrumb homeIcon={false}
                        homeLabel={t('Your subscription')}
                        items={breadcrumb}
                        lastItemAsLabel={true}
                        homeUrl={`/licenses/${orgService!.id}`}/>

            <Outlet></Outlet>

          </ContentContainer>
        </>
      }
    </Wrapper>
  )
};