import React, {useContext, useEffect, useState} from 'react';
import {SPConfigDto} from '../../model/dto/idP/SPConfig';
import SAMLApi from '../../services/api/SAMLApi';
import styled from 'styled-components';
import {
  BasicDropdown, Button,
  COLORS,
  InputLabel,
  LoadingIndicator,
  Size,
  Textarea,
  TextField,
  Tile, ToastContext,
  Z_INDEXES,
} from '@laerdal/life-react-components';
import {useTranslation} from 'react-i18next';
import {SuccessToastOptions} from '../../model/constants/ToastConstants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 1024px;
  width: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  
  .idp-form-textarea{
    cursor: pointer; 
  }
`;

const FieldContainer = styled.div`
  flex: 1;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${Z_INDEXES.backdrop};
`;

const SPPage = () => {

  const {t} = useTranslation('SAML');
  const {addToast} = useContext(ToastContext);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<SPConfigDto>();
  const [algo, setAlgo] = useState<string>('SHA256');
  const algorithms = [
    {
      value: 'SHA1',
      displayLabel: 'SHA1',
    },
    {
      value: 'SHA256',
      displayLabel: 'SHA256',
    },
  ];

  useEffect(() => {
    SAMLApi.GetSPConfig().then(setConfig).finally(() => setLoading(false));
  }, []);

  const copyText = (str: string) => {
    navigator.clipboard.writeText(str).then(() => {
      addToast('Copied to clipboard', SuccessToastOptions);
    });
  };

  return (
    <Wrapper>
      {
        loading &&
        <LoadingContainer>
          <LoadingIndicator color={COLORS.white}/>
        </LoadingContainer>
      }
      <Tile size={Size.Large}
            className={'idp-form'}
            header={{title: t('SAML SP Metadata')}}>
        <ContentContainer>
          <FieldContainer>
            <InputLabel inputId={'algorithm'} text={t('Certificate Signature Algorithm')}/>
            <BasicDropdown id={'algorithm'} list={algorithms} onSelect={setAlgo} value={algo}/>
          </FieldContainer>

          <FieldContainer>
            <InputLabel inputId={'metadata'} text={t('Metadata URL')}/>
            <RowContainer>
              <Textarea id={'metadata'}
                        placeholder={''}
                        className={'idp-form-textarea'}
                        value={config?.spMetadataUrls?.[algo] || ''}
                        size={Size.Medium}
                        onClick={() => copyText(config?.spMetadataUrls?.[algo] || '')}
                        required={false}
                        readOnly={true}
                        style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                        rows={2}/>
              <Button variant={'secondary'}
                      onClick={() => copyText(config?.spMetadataUrls?.[algo] || '')}>
                {t('Copy')}
              </Button>
            </RowContainer>
          </FieldContainer>

          <FieldContainer>
            <InputLabel inputId={'entityId'} text={t('Issuer')}/>
            <RowContainer>
              <Textarea id={'entityId'}
                        placeholder={''}
                        className={'idp-form-textarea'}
                        value={config?.entityId || ''}
                        size={Size.Medium}
                        onClick={() => copyText(config?.entityId || '')}
                        required={false}
                        readOnly={true}
                        style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                        rows={2}/>
              <Button variant={'secondary'}
                      onClick={() => copyText(config?.entityId || '')}>
                {t('Copy')}
              </Button>
            </RowContainer>
          </FieldContainer>

          <FieldContainer>
            <InputLabel inputId={'assertion'} text={t('Assertion Consumer Service URL')}/>
            <RowContainer>
              <Textarea id={'assertion'}
                        placeholder={''}
                        className={'idp-form-textarea'}
                        value={config?.spAssertionConsumerServiceUrl || ''}
                        size={Size.Medium}
                        onClick={() => copyText(config?.spAssertionConsumerServiceUrl || '')}
                        required={false}
                        readOnly={true}
                        style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                        rows={2}/>
              <Button variant={'secondary'}
                      onClick={() => copyText(config?.spAssertionConsumerServiceUrl || '')}>
                {t('Copy')}
              </Button>
            </RowContainer>
          </FieldContainer>

          <FieldContainer>
            <InputLabel inputId={'logout'} text={t('Single Logout Service URL')}/>
            <RowContainer>
              <Textarea id={'logout'}
                        placeholder={''}
                        className={'idp-form-textarea'}
                        value={config?.spSingleLogoutServiceUrl || ''}
                        size={Size.Medium}
                        onClick={() => copyText(config?.spSingleLogoutServiceUrl || '')}
                        required={false}
                        readOnly={true}
                        style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                        rows={2}/>
              <Button variant={'secondary'}
                      onClick={() => copyText(config?.spSingleLogoutServiceUrl || '')}>
                {t('Copy')}
              </Button>
            </RowContainer>
          </FieldContainer>


          <FieldContainer>
            <InputLabel inputId={'certificate'} text={t('x509 Certificate')}/>
            <RowContainer>
              <Textarea id={'certificate'}
                        placeholder={''}
                        className={'idp-form-textarea'}
                        value={config?.certificates?.[algo] || ''}
                        size={Size.Medium}
                        onClick={() => copyText(config?.certificates?.[algo] || '')}
                        required={false}
                        readOnly={true}
                        style={{width: '100%', boxSizing: 'border-box', resize: 'none'}}
                        rows={12}/>
              <Button variant={'secondary'}
                      onClick={() => copyText(config?.certificates?.[algo] || '')}>
                {t('Copy')}
              </Button>
            </RowContainer>
          </FieldContainer>
        </ContentContainer>
      </Tile>
    </Wrapper>
  );
};

export default SPPage;