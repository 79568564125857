import axios from 'axios';
import {BaseApi} from './BaseApi';
import {Feature} from '../../model/dto/settings/feature';
import {Country} from '../../model/dto/settings/country';
import {Service} from '../../model/dto/userProfile/service';
import {Language} from '../../model/dto/settings/language';
import {CompanyRole} from '../../model/dto/userProfile/companyRole';


class SettingsApi extends BaseApi {
  static instance = new SettingsApi();

  /**
   * Retrieves feature settings.
   * @returns list of features.
   */
  GetFeatures = async (): Promise<Feature[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/features`)
      .then((response) => response.data);

    return this.GetCached('features', '_', request);
  };


  /**
   * Retrieves country settings.
   * @returns list of countries.
   */
  GetCountries = async (abort?: AbortSignal): Promise<Country[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/countries`, {signal: abort})
      .then((response) => response.data);

    return this.GetCached('countries', '_', request);
  };

  
  /**
   * Retrieves country settings.
   * @returns list of countries by market.
   */
  GetCountriesByMarket = async (market: string): Promise<Country[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/marketCountries/${market}`)
      .then((response) => response.data);

    return this.GetCached(`marketCountries`, market, request);
  };


  /**
   * Retrieves service settings.
   * @returns list of services.
   */
  GetServices = async (abort?: AbortSignal): Promise<Service[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/services`, {
        signal: abort,
      })
      .then((response) => response.data);

    return this.GetCached('services', '_', request);
  };

  /**
   * Retrieves service settings.
   * @returns list of services.
   */
  GetService = async (id: string, abort?: AbortSignal): Promise<Service> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/services/${id}`, {
        signal: abort,
      })
      .then((response) => response.data);

    return this.GetCached('services', id, request);
  };

  /**
   * Retrieves service settings.
   * @returns list of services.
   */
  GetLanguages = async (abort?: AbortSignal): Promise<Language[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/languages`, {
        signal: abort,
      })
      .then((response) => response.data);

    return this.GetCached('languages', '_', request);
  };

  /**
   * Retrieves service settings.
   * @returns list of company roles.
   */
  GetCompanyRoles = async (abort?: AbortSignal): Promise<CompanyRole[]> => {
    const request = async () => await axios
      .get(`${process.env.REACT_APP_API_URL}/settings/roles`, {
        signal: abort,
      })
      .then((response) => response.data);

    return this.GetCached('roles', '_', request);
  };


}

export default SettingsApi.instance;