import axios from 'axios';
import {ListContainerDto} from '../../model/dto/listContainerDto';
import AuthApi from './AuthApi';
import {ShipmentListItemDto} from '../../model/dto/shipments/shipmentListItemDto';
import {ShipmentDetailsDto} from '../../model/dto/shipments/shipmentDetailsDto';
import {BaseApi} from './BaseApi';

class ShipmentsApi extends BaseApi {
  GetShipments = async (
    itemsPerPage: number,
    pageNumber: number,
    lang: string,
    orderBy: string | undefined,
    orderType: string | undefined,
    searchTerm: string | undefined,
    orderNumber: string | undefined
  ): Promise<ListContainerDto<ShipmentListItemDto>> => {
    const cacheKey = `${itemsPerPage}_${pageNumber}_${orderBy || ''}_${orderType || ''}_${searchTerm || ''}_${orderNumber || ''}_${lang || ''}`;

    let url = `${process.env.REACT_APP_API_URL}/Shipments?itemsPerPage=${itemsPerPage}&pageNumber=${pageNumber}`;

    if (!!orderBy) {
      url += `&orderBy=${orderBy}`;
    }
    if (!!orderType) {
      url += `&orderType=${orderType}`;
    }
    if (!!searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }
    if (!!orderNumber) {
      url += `&orderNumber=${orderNumber}`;
    }
    if (!!lang) {
      url += `&lang=${lang}`;
    }
    

    const request = async () => axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });

    return this.GetCached('shipments', cacheKey,request);

  };

  GetShipmentDetails = async (shipmentNumber: string, lang: string): Promise<ShipmentDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Shipments/${shipmentNumber}?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  ClearCache = () => {
    this.Clear('shipments');
  }
}

export default new ShipmentsApi();
