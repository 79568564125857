import { ComponentS, ComponentXXS, DropdownItem, SystemIcons, Tag, Tile } from "@laerdal/life-react-components";
import { useMemo } from "react";
import {useTranslation} from 'react-i18next';
import styled from "styled-components";
import { AddressDto } from "../../model/dto/address/addressDto";
import AddressFormat from "./AddressFormat";

const TileContainer = styled.div`
  margin: 8px 0;
  flex-direction: column;
  display: flex;
  gap: 8px;
`;

type AddressTileProps = {
    address: AddressDto ;
    setDefaultShippingAddress: (address: AddressDto) => void;
  };

const AddressTile = ({address, setDefaultShippingAddress}: AddressTileProps ) => { 
    const {t} = useTranslation('Address');

    const dropdownItemsWithIcon = useMemo<DropdownItem[]>(() => 
      {
        const list = []

        if(!address.shippingDefault) list.push({value: 'default shipping', displayLabel: t('Set as default shipping')});

        return list;
      }, [t, address]);

    const menuItemClick = (value: string[]) =>
    {
      if(value[0] == 'default shipping') setDefaultShippingAddress(address);
     
    }

    return (
        <Tile  header={{
                        title: address?.billingName ?? '',
                        // title: '',
                        subtitle: address.addressNumber,
                        buttons:  dropdownItemsWithIcon.length > 0 ? [
                          {
                            componentType: 'dropdown',
                            items: dropdownItemsWithIcon,
                            itemsType: 'normal',
                            onClick: menuItemClick,
                            icon: <SystemIcons.MoreVertical/>,
                            tooltip:{
                              label: t('Address settings'),
                            }
                          }] : []
                      }}>
          <TileContainer>
          
            <AddressFormat address={address} />

            {address.shippingDefault && <Tag label={t('Default')} />}
            {address.billingDefault && <Tag label={t('Default billing')} />}
          </TileContainer>
        </Tile>
      );
}

export default AddressTile;