import {
  BREAKPOINTS,
  COLORS,
  ListRow,
  LoadingIndicator,
  ModalDialog,
  Size,
  SystemIcons,
  ToastContext,
} from '@laerdal/life-react-components';
import styled from 'styled-components';
import {useTranslation} from 'react-i18next';
import {useMediaMatch} from "rooks";
import { ShipmentListItemDto } from '../../../model/dto/shipments/shipmentListItemDto';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
`;

export interface Props {
  shipments: ShipmentListItemDto[],
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
}

const OrderShipmentsModal = ({isOpen, onClose, loading, shipments}: Props) => {
  const {t} = useTranslation('Order');
  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));
  
  const close = () => {
    onClose();
  }

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeAction={close}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 closeModalAndClearInput={close}
                 submitAction={(e) => e?.preventDefault()}
                 buttons={[{
                   variant: 'tertiary',
                   action: close,
                   text: t('Close')
                 }
                 ]}
                 title={ shipments?.length == 1 ? t('Track shipment') : t('Track shipments') }>
      <Wrapper>
        <Description>
          {t('This order contains the following shipment information.')}
        </Description>

        {loading && <LoadingIndicator />}

        {shipments && shipments.map(s => 
                            <ListRow mainText={s.trackingNumber ?? 'Shipment'}
                              secondaryText={s.poNumber ?? ''}
                              note={s.trackingStatusTranslated}
                              size={Size.Small}
                              rightSideIcon={<SystemIcons.OpenNewWindow />}
                              rightSideAction={() => {
                                //@ts-ignore
                                posthog.capture?.('OrderShipmentModal TrackingOpened');
                                window.open(s.trackingUrl, '_blank')
                              }}/>
          )}
        
      </Wrapper>
    </ModalDialog>);
}

export default OrderShipmentsModal;