import {Service} from "../../../model/dto/userProfile/service";
import {OrganizationService} from "../../../model/dto/organization/organizationService";
import {TFunction} from "react-i18next";
import {Organization} from "../../../model/dto/organization/organization";
import {BillingInterval} from "../../../model/dto/userProfile/billingInterval";
import {Subscription} from "../../../model/dto/organization/subscription";

export class ServiceHelper {
  static GetOwnerRoles = (service: Service | undefined | null) => {
    return service?.serviceRoles
      ?.filter((s) => s.id.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase() || s.parentRoleId?.toLowerCase() === process.env.REACT_APP_SERVICE_OWNER_ROLE_ID?.toLowerCase())
      .map((a) => a.name);
  };

  static FormatContactInfo = (org: OrganizationService | null | undefined, roles: string[] | undefined, t: TFunction): string => {
    const owners = org?.members?.filter((m) => (m.role?.name ? roles?.includes(m.role.name) : false));
    if (owners && owners.length > 0) {
      let owner = `${owners[0]?.user?.email}`;
      const others = owners.length - 1;
      if (others > 0) {
        owner = owner.concat(`, + ${others} ${t('more...')}`);
      }
      return owner;
    }

    const invitedOwners = org?.invitations?.filter((m) => (m.role?.name ? roles?.includes(m.role.name) : false) && m.isActive);
    if (invitedOwners && invitedOwners.length > 0) {
      let owner = invitedOwners[0]?.email;
      const others = invitedOwners.length - 1;
      if (others > 0) {
        owner = owner.concat(`, + ${others} ${t('more...')}`);
      }
      return owner;
    }

    return '';
  };

  static GetPrice = (service?: Service, organization?: Organization | null, subscription?: Subscription, billingInterval?: BillingInterval) => {
    if (!service || !organization || !subscription || billingInterval === undefined) return undefined;

    const plan = service?.availablePlans?.find(x => x.id === subscription?.plan.id);

    const tier = plan?.tiers?.find(x => x.id === subscription?.tier.id);
    const price = tier?.prices?.find(x => x.billingInterval === billingInterval && x.country?.toLowerCase() === organization?.address?.country?.codeAlpha2?.toLowerCase()) ||
      tier?.prices?.find(x => x.billingInterval === billingInterval && !x.country);


    return price;
  }

}
