import { Tag, TagVariants } from "@laerdal/life-react-components";
import {useTranslation} from 'react-i18next';
import { CaseStatuses } from "../../model/constants/CaseStatuses";

type CaseEmailProps = {
    status: string ;
    statusTranslated: string;
  };

const CaseStatus = ({status, statusTranslated}: CaseEmailProps ) => { 
    const GetStatusVariant = (status :string) : TagVariants => {
        if(status == CaseStatuses.Resolved) return 'positive';
        else if(status == CaseStatuses.New) return 'warning';
        return 'neutral';
      }
      
    return (  
        !!statusTranslated ? 
          <Tag label={statusTranslated} variant={GetStatusVariant(status)} />
          : <></>
      );
}

export default CaseStatus;