import { AttachmentDto } from './../../model/dto/cases/caseEmailDto';
import axios from 'axios';
import {CaseCreateDto} from '../../model/dto/cases/caseCreateDto';
import {CaseDetailsDto} from '../../model/dto/cases/caseDetailsDto';
import { CaseEmailDto } from '../../model/dto/cases/caseEmailDto';
import {CasesDto} from '../../model/dto/cases/casesDto';
import AuthApi from './AuthApi';
import {BaseApi} from './BaseApi';
import { CaseStatuses } from '../../model/constants/CaseStatuses';
import { CaseCommentCreateDto } from '../../model/dto/cases/caseCommentCreateDto';

class CaseApi extends BaseApi {

  GetCases = async (
    limit: number,
    offset: number,
    lang: string,
    filterUsers?: boolean,
    status?: CaseStatuses[],
    orderBy?: string,
    orderType?: string,
    searchTerm?: string,
    assetId?: string,
    signal?: AbortSignal,
  ): Promise<CasesDto> => {
    const cacheKey = `${filterUsers || ''}_${status || ''}_${orderBy || ''}_${orderType || ''}_${searchTerm || ''}_${limit || ''}_${offset || ''}_${lang || ''}`;

    let url = `${process.env.REACT_APP_API_URL}/Cases?limit=${limit}&offset=${offset}`;
    if (filterUsers) {
      url += `&filterUsers=${filterUsers}`;
    }
    if (status && status.length > 0) {
      for(let i = 0; i<status.length; i++)
      url += `&status=${status[i]}`;
    }
    if (orderBy) {
      url += `&orderBy=${orderBy}`;
    }
    if (orderType) {
      url += `&orderType=${orderType}`;
    }
    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }
    if (lang) {
      url += `&lang=${lang}`;
    }
    if (assetId) {
      url += `&assetId=${assetId}`;
    }

    

    const request = async () => axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${await AuthApi.getToken()}`,
          },
          signal: signal,
        },
      )
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });

    return this.GetCached('cases', cacheKey, request);
  };

  GetCaseDetails = async (caseNumber: string, lang: string,): Promise<CaseDetailsDto> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Cases/${caseNumber}?lang=${lang}`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  GetCaseEmails = async (caseNumber: string): Promise<CaseEmailDto[]> => {
    return axios
      .get(`${process.env.REACT_APP_API_URL}/Cases/${caseNumber}/emails`, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
      });
  };

  CreateComment = async (dto: CaseCommentCreateDto, files: File[]): Promise<string> => {
    const formData = new FormData();
    if(dto) 
      formData.append('dto', JSON.stringify(dto));
    files.forEach((file) => formData.append('files', file));

    return axios
    .post(`${process.env.REACT_APP_API_URL}/Cases/comment`, formData, {
      headers: {
        Authorization: `Bearer ${await AuthApi.getToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      this.ClearCache();
      return response.data;
    });
  };


  CreateCase = async (dto: CaseCreateDto): Promise<string> => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/Cases`, dto, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
      })
      .then((response) => {
        this.ClearCache();
        return response.data;
      });
  };

  DownloadAttachment = async (caseNumber: string, id: string, attachment: AttachmentDto, handleError: (message: string) => void): Promise<void> => {
    const downloadLink = `${process.env.REACT_APP_API_URL}/Cases/${caseNumber}/attachment/${id}`;
    return axios
      .get(downloadLink, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data]);

          const url = window.URL || window.webkitURL;
          const link = url.createObjectURL(blob);
          const downloadElement = document.createElement('a');
          downloadElement.href = link;
          downloadElement.setAttribute(
            'download',
            attachment.fileName + `.` + attachment.fileExtension,
          );
          document.body.appendChild(downloadElement);
          downloadElement.click();
          downloadElement!.parentNode!.removeChild(downloadElement);
        }
      }).catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 401:
          case 403:
            handleError('Not authorized to download');
            break;
          case 404:
            handleError(
              'The invoice document is not generated yet, please try to download it later. If this error persists please contact support');
            break;
          default:
            handleError('Issue when downloading');
        }
      });
  };

  GetBlobLink = async (caseNumber: string, id: string, attachment: AttachmentDto, handleLink: (link:string)=>void, handleError: (message: string) => void): Promise<void> => {
    const downloadLink = `${process.env.REACT_APP_API_URL}/Cases/${caseNumber}/attachment/${id}`;
    return axios
      .get(downloadLink, {
        headers: {
          Authorization: `Bearer ${await AuthApi.getToken()}`,
        },
        responseType: 'blob',
      })
      .then((response) => {
        if (response.status === 200) {
          const blob = new Blob([response.data], {type: `application/${attachment.fileExtension}`});

          const url = window.URL || window.webkitURL;
          handleLink(url.createObjectURL(blob));
        }
      }).catch((error) => {
        const status = error.response?.status;
        switch (status) {
          case 401:
          case 403:
            handleError('Not authorized to download');
            break;
          case 404:
            handleError(
              'The invoice document is not generated yet, please try to download it later. If this error persists please contact support');
            break;
          default:
            handleError('Issue when downloading');
        }
      });
  };

  ClearCache = () => {
    this.Clear('cases');
  };
}

export default new CaseApi();
