import React from 'react';
import {
  BREAKPOINTS,
  COLORS,
  ModalDialog,
  Size,
} from '@laerdal/life-react-components';
import {Trans, useTranslation} from 'react-i18next';
import {useMediaMatch} from 'rooks';
import {Organization} from '../../../model/dto/organization/organization';
import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 400px;
  span {
    font-weight: 700;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => Promise<any>;
  action?: string;
  organization?: Organization;
}

export const ConfirmModal = ({isOpen, onClose, onSubmit, action, organization}: Props) => {

  const {t} = useTranslation('Profile');

  const isMediumScreen = useMediaMatch(BREAKPOINTS.MEDIUM.replace('@media ', ''));

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const close = () => {
    onClose();
  };

  const submit = () => {
    setIsSubmitting(true);
    onSubmit().finally(() => setIsSubmitting(false));
  };

  return (
    <ModalDialog isModalOpen={isOpen}
                 closeModalAndClearInput={close}
                 closeAction={close}
                 submitAction={submit}
                 title={action === 'current' ? t('Set default organization?') : t('Leave organization?')}
                 size={isMediumScreen ? Size.Medium : Size.Small}
                 buttons={[
                   {
                     text: t('No, cancel'),
                     action: close,
                     variant: 'tertiary',
                   }, {
                     text: action === 'current' ? t('Yes, confirm') : t('Yes, leave'),
                     action: submit,
                     loading: isSubmitting,
                     variant: action === 'current' ? 'primary' : 'critical',
                   },
                 ]}>
      <Wrapper>
        {
          action === 'current' &&
          <Trans i18nKey={'ConfirmDefaultOrganizationChange'} ns={'Profile'}>
            You will be directed to <span>{organization?.name}</span> the next time you sign in.
          </Trans>
        }
        {
          action === 'remove' &&
          <Trans i18nKey={'ConfirmLeavingOrganization'} ns={'Profile'}>
            You will no longer be able to access <span>{organization?.name}</span> unless invited back by a co-worker.
          </Trans>
        }
      </Wrapper>

    </ModalDialog>
  );
};