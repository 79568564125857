import React, {useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styled from 'styled-components';
import {
  BREAKPOINTS, Button,
  COLORS,
  ComponentLStyling,
  ComponentMStyling,
  ComponentSStyling,
  ComponentTextStyle,
  ComponentXSStyling,
  ComponentXXSStyling,
  defaultOnMouseDownHandler,
  Note,
  SystemIcons,
  Tile,
  TileFooterProps,
} from '@laerdal/life-react-components';
import {LicenseInformationHelper} from '../helpers/LicenseInformationHelper';
import {useDynamicSize} from "../../../util/DynamicSize";
import {useSelector} from "react-redux";
import {
  selectIsSubscriptionOwner,
  selectOrganizationMembers,
  selectSubscription,
  selectSubscriptionOrganizationService,
  selectSubscriptionPrice, selectSubscriptionPriceConfigurations, selectSubscriptionScheduledAmendment,
  selectSubscriptionService
} from "../../../store/subscription/subscriptionSlice";
import {TileHeaderProps} from "@laerdal/life-react-components/dist/Tile/TileTypes";
import {useNavigate} from "react-router-dom";
import posthog from 'posthog-js';
import {navigateByUrl} from "../../../util/navigation-helper";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.neutral_100};
  border-radius: 4px;
  margin-bottom: 16px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  border-bottom: 1px solid ${COLORS.neutral_100};;

  ${BREAKPOINTS.LARGE} {
    flex-direction: row;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

const Information = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const PricingInfo = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  }
`;

const DateInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  ${ComponentXXSStyling(ComponentTextStyle.Regular, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentXSStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  &.critical {
    color: ${COLORS.critical_500};
  }

  svg {
    width: 16px;
    height: 16px;
  }

  ${BREAKPOINTS.LARGE} {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const AdditionalInformation = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const AdditionalInformationItem = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding: 8px 0;
`;

const AdditionalInformationKey = styled.div`
  ${ComponentSStyling(ComponentTextStyle.Bold, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Bold, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Bold, COLORS.black)}
  }
`;

const AdditionalInformationValue = styled.div`
  width: min-content;

  ${ComponentSStyling(ComponentTextStyle.Regular, COLORS.black)}
  ${BREAKPOINTS.MEDIUM} {
    ${ComponentMStyling(ComponentTextStyle.Regular, COLORS.black)}
  }

  ${BREAKPOINTS.LARGE} {
    ${ComponentLStyling(ComponentTextStyle.Regular, COLORS.black)}
  }
`;

export const LicenseDetailsCard = () => {
  const {t} = useTranslation('License');
  const size = useDynamicSize();
  const navigate = useNavigate();

  const service = useSelector(selectSubscriptionService);
  const amendment = useSelector(selectSubscriptionScheduledAmendment);
  const organizationService = useSelector(selectSubscriptionOrganizationService);
  const organizationMembers = useSelector(selectOrganizationMembers);
  const subscription = useSelector(selectSubscription);
  const prices = useSelector(selectSubscriptionPriceConfigurations);
  const price = useSelector(selectSubscriptionPrice);
  const isOwner = useSelector(selectIsSubscriptionOwner);

  //@ts-ignore
  const typeText = useMemo(() => LicenseInformationHelper.FormatServicePlan(t, service, subscription), [t, service, subscription]);
    //@ts-ignore
  const instanceLimitText = useMemo(() => LicenseInformationHelper.FormatTierDescription(t, service, subscription?.plan.id, subscription?.tier.id, subscription?.maxSubscriptionInstances), [t, service, subscription]);
    //@ts-ignore
  const priceText = useMemo(() => LicenseInformationHelper.FormatPrice(t, subscription, price), [t, subscription, price]);
    //@ts-ignore
  const canExpire = useMemo(() => LicenseInformationHelper.CanExpire(subscription), [subscription]);
    //@ts-ignore
  const expirationText = useMemo(() => LicenseInformationHelper.FormatExpiration(t, subscription), [t, subscription]);
    //@ts-ignore
  const statusText = useMemo(() => LicenseInformationHelper.FormatStatus(t, subscription), [t, subscription]);
    //@ts-ignore
  const ownerInfo = useMemo(() => LicenseInformationHelper.FormatLicenseOwner(t, service, organizationService, organizationMembers), [t, subscription]);
  const willExpire = useMemo(() => LicenseInformationHelper.WillExpire(subscription), [subscription]);
  const isExpired = useMemo(() => LicenseInformationHelper.IsExpired(subscription), [subscription]);

  const canCancel = LicenseInformationHelper.CanCancel(subscription, isOwner);
  const canRenew = LicenseInformationHelper.CanRenew(subscription, isOwner);
  const canChange = LicenseInformationHelper.CanChange(subscription, isOwner);
  const canPurchase = LicenseInformationHelper.CanPurchase(subscription, isOwner, !!prices?.length);

  const header = {
    title: t('Subscription details'),
    subtitle: t('View and manage your selected plan.'),
  } as TileHeaderProps;

  const navigateToPurchase = () => {

    //@ts-ignore
    posthog.capture?.('AC SubscriptionGetNew Opened',{
      subscription_name: service?.name,
    });

    let url = process.env.REACT_APP_CHECKOUT_URL + '/subscription/order/' + service!.id;
    url += '?planId=' + subscription!.plan.id;
    url += '&tierId=' + subscription!.tier.id;
    url += '&billingInterval=' + subscription!.billingInterval;
    url += '&duration=' + LicenseInformationHelper.Duration(subscription);
    url += '&autoRenew=' + subscription!.autoRenew;
    if (subscription!.tier.individualLicensing) {
      url += '&instances=' + subscription!.maxSubscriptionInstances;
    }

    navigateByUrl(url, undefined, '_self');
  };

  const disableAmendments = !LicenseInformationHelper.CanAmend(subscription, amendment);

  return (
    <Tile header={header}
          size={size}>
      <Wrapper>
        <Container>
          <Information>
            <Header>
              <h6>{typeText}</h6>
              <Note>{instanceLimitText}</Note>
            </Header>
            <Description>
              {
                !!price &&
                <PricingInfo>{priceText}</PricingInfo>
              }
              {
                canExpire &&
                <DateInfo className={willExpire || isExpired ? 'critical' : ''}>
                  <SystemIcons.Calendar/>
                  <span>
                  {expirationText}
                </span>
                </DateInfo>
              }
            </Description>
          </Information>
          <Actions>
            {
              canPurchase &&
              <Button onClick={navigateToPurchase}>
                {t('Get subscription')}
              </Button>
            }
            {
              canChange &&
              <Button disabled={disableAmendments}
                      onClick={() => {
                        //@ts-ignore
                        posthog.capture?.('AC SubscriptionAmendment Opened',{
                          subscription_name: service?.name,
                          subscription_number: subscription?.subscriptionNumber || subscription?.id,
                        });

                        navigate('edit/options')
                      }}>
                {t('Change plan')}
              </Button>
            }
            {
              canCancel &&
              <Button variant={'secondary'}
                      disabled={disableAmendments}
                      onClick={() => {
                        //@ts-ignore
                        posthog.capture?.('AC CancelSubscription Opened');
                        navigate('edit/cancel')
                      }}>
                {t('Cancel plan')}
              </Button>
            }
            {
              canRenew &&
              <Button variant={'secondary'}
                      disabled={disableAmendments}
                      onClick={() => {
                        //@ts-ignore
                        posthog.capture?.('AC RenewSubscription Opened');
                        navigate('edit/renew')
                      }}>
                {t('Renew plan')}
              </Button>
            }
          </Actions>

        </Container>
        <AdditionalInformation>
          <AdditionalInformationItem>
            <AdditionalInformationKey>{t('Status')}</AdditionalInformationKey>
            <AdditionalInformationValue>{statusText}</AdditionalInformationValue>
          </AdditionalInformationItem>
          <AdditionalInformationItem>
            <AdditionalInformationKey>{t('License owner')}</AdditionalInformationKey>
            <AdditionalInformationValue>{ownerInfo}</AdditionalInformationValue>
          </AdditionalInformationItem>
          {
            !!subscription?.poNumber &&
            <AdditionalInformationItem>
              <AdditionalInformationKey>{t('License Number')}</AdditionalInformationKey>
              <AdditionalInformationValue>{subscription!.poNumber}</AdditionalInformationValue>
            </AdditionalInformationItem>
          }
        </AdditionalInformation>
      </Wrapper>
    </Tile>
  );
};