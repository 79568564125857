import React, { useMemo } from 'react';
import { useState, useEffect } from 'react';
import {
  Table,
  TableColumn,
  TablePagination,
} from '@laerdal/life-react-components';
import { useTranslation } from 'react-i18next';
import PaginationHelper from '../../services/helpers/PaginationHelper';
import { OrderAssetListItemDto } from '../../model/dto/orders/orderAssetListItemDto';
import OrdersApi from '../../services/api/OrdersApi';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { selectGigyaAccount, selectUserProfile } from '../../store/account/accountSlice';
import { useFeatures } from '../../hooks/Features';
import { FeatureNames } from '../../model/constants/FeatureConstants';

const Wrapper = styled.div`
  overflow-x: auto;
  background: white;
`;

const InitialPagination: TablePagination = {
  currentPage: 0,
  from: 0,
  rowsPerPage: 20,
  to: 0,
  total: 0,
};

export interface OrderAssetsTableProps {
  onItemClick?: (item: OrderAssetListItemDto) => void;

  orderNumber: string;
}

export const OrderAssetsTable: React.FunctionComponent<OrderAssetsTableProps> = ({
  orderNumber,
  onItemClick,
}) => {
  const { t, i18n } = useTranslation(['Asset', 'Common']);
  const [checkFeature, isFeaturesInitialized] = useFeatures();
  const user = useSelector(selectUserProfile);

  const account = useSelector(selectGigyaAccount);
  const [assets, setAssets] = useState<OrderAssetListItemDto[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  const [pagination, setPagination] = useState<TablePagination>(InitialPagination);

  const columnsWithPrice = useMemo<TableColumn[]>(() => [
    {
      key: 'name',
      name: t('Name'),
      sortable: true,
    },
    {
      key: 'catalogNumber',
      name: t('Catalog Number'),
      sortable: true,
    },
    {
      key: 'shipDate',
      name: t('Shipped date'),
      sortable: true,
      width: 230,
      type: 'custom',
      customContent: (row: OrderAssetListItemDto, key: string) => !!row.shipDate
        ? moment(row.shipDate).format('ll')
        : '',
    },
    {
      key: 'quantity',
      sortable: true,
      name: t('Quantity'),
    },

    {
      key: 'unitPrice',
      name: t('Unit price'),
      sortable: true,
      justify: 'right',
      type: 'custom',
      customContent: (row: OrderAssetListItemDto, key: string) => (row.unitPrice ?? 0)?.toLocaleString(i18n.language, { style: 'currency', currency: row.currency }),
    },
    {
      key: 'totalPrice',
      name: t('Total price'),
      sortable: true,
      justify: 'right',
      type: 'custom',
      customContent: (row: OrderAssetListItemDto, key: string) => (row.totalPrice ?? 0)?.toLocaleString(i18n.language, { style: 'currency', currency: row.currency }),
    }
  ], [t]);

  const columnsWithoutPrice = useMemo<TableColumn[]>(() => [
    {
      key: 'name',
      name: t('Name'),
      sortable: true,
    },
    {
      key: 'catalogNumber',
      name: t('Catalog Number'),
      sortable: true,
    },
    {
      key: 'shipDate',
      name: t('Shipped date'),
      sortable: true,
      width: 230,
      type: 'custom',
      customContent: (row: OrderAssetListItemDto, key: string) => !!row.shipDate
        ? moment(row.shipDate).format('L')
        : '',
    },
    {
      key: 'quantity',
      sortable: true,
      name: t('Quantity'),
    }
  ], [t]);


  useEffect(() => {
    setLoading(true);

    OrdersApi.GetOrderAssets(orderNumber)
      .then((response) => {
        setAssets(response.items);
        setPagination(PaginationHelper.GetInitial(pagination.rowsPerPage, response.totalCount));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    
    <Wrapper>
      <Table
        noRowsLabel={t('There are no rows to display', {'ns': 'Common'})}
        rowsPerPageLabel={t('Rows per page:', {'ns': 'Common'})}
        rows={assets || []}
        pagination={pagination}
        showLoadingIndicator={loading}
        onSelectionChange={(row: OrderAssetListItemDto) => onItemClick && onItemClick(row)}
        selectable={!!onItemClick}
        columns={
          checkFeature(FeatureNames.HideProductPrices) 
            ? columnsWithoutPrice 
            : columnsWithPrice }
      />
    </Wrapper>
  );
};